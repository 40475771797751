import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { TontinesEntity } from 'src/app/fund-raising/project/user-tontine-entity';
import { UserTontineService } from 'src/app/fund-raising/project/user-tontine.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-tontine-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {
  loading: boolean;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  searchText: string = '';
  tontines: Array<TontinesEntity> = [];
  searchByname: string;
  searchByUserId: string;
  searchByIsInRemediation: boolean;
  totalPages: any[] = [];
  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  constructor(
    private authService: AuthService,
    private userTontineService: UserTontineService,
    private route: ActivatedRoute,
  ) {
    this.loading = true;
    if(window.One != undefined){
      window.One.loader('show')
    }
  }

  ngOnInit(): void {
    this.loading = true;

    if(window.One != undefined){
      window.One.loader('show')
    }

    this.authService.token().subscribe(() => {
      this.search();
      if(window.One != undefined){
        window.One.loader('hide')
      }
    });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.search();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  search() {
    this.loading = true;
    let parameters = {"page":this.currentPage,"limit":this.pageSize};
    if (this.searchByname) {
      parameters["name"] = this.searchByname;
    }

    if (this.searchByUserId) {
      parameters["userId"] = this.searchByUserId;
    }

    if (this.searchByIsInRemediation) {
      parameters["isInRemediation"] = this.searchByIsInRemediation;
    }

    this.tontines = [];
    let queryParams = new HttpParams({ fromObject: parameters });
    this.userTontineService.fetchBy(queryParams).subscribe(
      (response: WalletApiResponse<any>) => {
        if (response.status !== "success") {
          this.loading = false;
          return;
        }
        this.tontines = response.data.ProjectLists.data;
        this.currentPage = response.data.ProjectLists.page;
        this.totalItems = response.data.ProjectLists.total;
        this.pageSize = response.data.ProjectLists.limit;
        this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
        this.loading = false;
      });
  };

  onSearch(value: string, key: string): void {
    this.loading = true;
    let parameters = {"page":this.currentPage,"limit":this.pageSize};
    if (this.searchByname) {
      parameters["name"] = this.searchByname;
    }

    if (this.searchByUserId) {
      parameters["userId"] = this.searchByUserId;
    }

    if (this.searchByIsInRemediation) {
      parameters["isInRemediation"] = this.searchByIsInRemediation;
    }

    this.tontines = [];
    let queryParams = new HttpParams({ fromObject: parameters });
    this.userTontineService.fetchBy(queryParams).subscribe(
      (response: WalletApiResponse<any>) => {
        if (response.status !== "success") {
          this.loading = false;
          return;
        }
        this.tontines = response.data.ProjectLists.data;
        this.currentPage = response.data.ProjectLists.page;
        this.totalItems = response.data.ProjectLists.total;
        this.pageSize = response.data.ProjectLists.limit;
        this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
        this.loading = false;
      });
  }

  refresh() {
    this.ngOnInit()
  }

}
