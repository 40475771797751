import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import {FormBuilder, Validators} from '@angular/forms';
import { AccountsService } from 'src/app/wallet-api/accounts.service';
import { PlanService } from 'src/app/plans/plan.service';
import { PlanEntity } from 'src/app/plans/plan-entity';
import { PlanCollection } from 'src/app/plans/plan-collection';
import { OrganizationAccountService } from 'src/app/wallet-api/organization/organization-account.service';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-organization-account',
  templateUrl: './create-organization-account.component.html',
  styleUrls: ['./create-organization-account.component.css']
})
export class CreateOrganizationAccountComponent implements OnInit {
  organizationAccountForm;
  walletPlans : Array<PlanEntity>;
  alert: AlertEntity;
  inProgress: boolean

  constructor(
    private accountService: OrganizationAccountService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private planService: PlanService
  ) { 
    this.organizationAccountForm = this.formBuilder.group({
      name : '',
      accountType: 'business',
      walletPlanId: ''
    });


    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
  }

   ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.planService.fetchAll().subscribe((response: WalletApiResponse<PlanCollection>) => {
        this.walletPlans = response.data.walletPlans;
      });
    });
  }

  createAccount(){
    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.accountService.create(
        this.organizationAccountForm.value
      ).subscribe((response)=>{
        this.alert.display = true;
        this.alert.message = 'Successfully created organization account'
        this.inProgress = false;
      });
    });
  }

}
