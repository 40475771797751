import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserDocumentsService {

  constructor(
    private apiService: ApiService 
  ) {
  }


  fetch(userId: string, documentId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/users/' + userId + '/documents/' + documentId);
  }

  fetchAll(
    userId: string,
    filter: HttpParams): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/users/' + userId + '/documents', filter);
  }

  add(userId: string, formData: FormData): Observable<any> {
    return this.apiService.post(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/users/' + userId + '/documents',
      formData
    )
  }

  changeStatus(
    userId: string,
    documentId: string,
    documentStatus: string,
    comment: string
    ): Observable<any> {
    return this.apiService.patch(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/users/' + userId + '/documents/' + documentId + '/status',
      {
        status: documentStatus,
        comment: comment
      }
    )
  }

  attachMetadata(
    userId: string,
    documentId: string,
    metadata
    ): Observable<any> {
    return this.apiService.patch(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/users/' + userId + '/documents/' + documentId + '/meta-data',
      metadata
    )
  }
}
