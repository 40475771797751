import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../auth/auth.guard';
import {DetailComponent} from './detail/detail.component';
import { ListOrganizationAccountsComponent } from './accounts/list-organization-accounts/list-organization-accounts.component';
import { CreateOrganizationAccountComponent } from './accounts/create-organization-account/create-organization-account.component';
import { ListOrganizationAccountTransactionsComponent } from './accounts/transactions/list-organization-account-transactions/list-organization-account-transactions.component';
import { EditOrganizationAccountComponent } from './accounts/edit-organization-account/edit-organization-account.component';
import { CreateBankAccountPayoutComponent } from './accounts/payout/create-bank-account-payout/create-bank-account-payout.component';


const routes: Routes = [
  {
    path: 'organizations',
    component: DetailComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'organizations/accounts',
    component: ListOrganizationAccountsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'organizations/accounts/:accountId',
    component: EditOrganizationAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'organizations/accounts/add/new',
    component: CreateOrganizationAccountComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'organizations/accounts/:accountId/transactions/list',
    component: ListOrganizationAccountTransactionsComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'organizations/accounts/:accountId/payouts/bank-accounts/create/new',
    component: CreateBankAccountPayoutComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class OrganizationsRoutingModule { }