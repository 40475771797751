import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { TransactionAmountKycRuleEntity } from './transaction-amount-kyc-rule-entity';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionAmountKycRuleService {

  constructor(
    private apiService: ApiService
  ) {
  }


  createRule(
    entity: TransactionAmountKycRuleEntity
    ): Observable<any> {
    return this.apiService.post(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/rules/transactions/amount',
      entity
    )
  }

  fetch(ruleId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/rules/transactions/amount/' + ruleId);
  }

  fetchAll(): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/rules/transactions/amount');
  }
}
