import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { UserTontineEntity } from 'src/app/fund-raising/project/user-tontine-entity';
import { UserDocumentsService } from 'src/app/compliance-api/documents/user-documents.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import {ActivatedRoute} from '@angular/router';
import { UserTontineCollection} from 'src/app/fund-raising/project/user-tontine-collection';
import { HttpParams } from '@angular/common/http';
import {UserTontineService} from "../../../fund-raising/project/user-tontine.service";

@Component({
  selector: 'app-list-user-tontines',
  templateUrl: './list-user-tontines.component.html',
  styleUrls: ['./list-user-tontines.component.css']
})
export class ListUserTontinesComponent implements OnInit {

  tontines: Array<UserTontineEntity>
  loading: boolean;
  userId: string;

  constructor(
    private userTontineService: UserTontineService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {

      this.userTontineService.fetchAll(
        this.userId,
        (new HttpParams()).set('latest', 'true')
        ).subscribe((response: WalletApiResponse<UserTontineCollection>) => {
        this.tontines = response.data.ProjectLists;
        this.loading = false;
      });
    });
  }
}
