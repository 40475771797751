import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { AccountTransactionCollection } from 'src/app/wallet-api/account-transaction-collection';
import { AccountTransactionEntity } from 'src/app/wallet-api/account-transaction-entity';
import { AccountTransactionsService } from 'src/app/wallet-api/account-transactions.service';
import { BankTransactionEntity as BankTransactionEntity } from 'src/app/wallet-api/bank-transactions.model';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { NotifierService } from '../../../notifier';

@Component({
  selector: 'app-transactions-account',
  templateUrl: './transactions-account.component.html',
  styleUrls: ['./transactions-account.component.css'],
})
export class TransactionsAccountComponent implements OnInit {
  transactions: Array<AccountTransactionEntity> = [];
  bankTransactions: Array<BankTransactionEntity> = [];
  loading: boolean;
  userId: string;
  accountId: string;

  constructor(
    private accountTransactionService: AccountTransactionsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private notifier: NotifierService
  ) {
    this.loading = true;
    (this.userId = this.route.snapshot.paramMap.get('userId')),
      (this.accountId = this.route.snapshot.paramMap.get('accountId'));
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.accountTransactionService
        .fetchAll(
          this.route.snapshot.paramMap.get('userId'),
          this.route.snapshot.paramMap.get('accountId')
        )
        .subscribe(
          (response: WalletApiResponse<AccountTransactionCollection>) => {
            if (response.status === 'success') {
              this.transactions = response.data[0].walletAccountTransactions;
            } else {
              //   this.errorHandlerService.handleGeneralError(
              //     response.StatusDescription
              //   );
              this.notifier.error(response.StatusDescription);
            }
            this.loading = false;
          }
        );
    });

    this.authService
      .token()
      .pipe(
        switchMap(() =>
          this.accountTransactionService.fetchBankTransactions(
            this.route.snapshot.paramMap.get('userId')
          )
        )
      )
      .subscribe({
        next: (response) => {
          if (response.status !== 'success') {
            return this.notifier.error(response.StatusDescription);
          }

          this.bankTransactions = Array.isArray(response.data)
            ? [...response.data]
            : [];
        },
        error: (e: HttpErrorResponse) => {
          this.notifier.httpError(e);
        },
      });
  }
}
