import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ApiService} from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class BankCustomerService {
  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(
    httpParams: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.bassServiceBaseUrl + '/api/customers', httpParams);
  }

  fetchBankUserDetails(userId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.bassServiceBaseUrl + '/api/mt/customers?customer_id=' + userId
    );
  }

  updateBankUserDetails(userId: string): Observable<any> {
    return this.apiService.put(
      environment.wallet.bassServiceBaseUrl + '/api/customers/' + userId,
      {}
    );
  }

  attachDocument(userId: string, documentType: string): Observable<any> {
    return this.apiService.post(
      environment.wallet.bassServiceBaseUrl + '/api/customers/documents/' + userId + '/' + documentType.toLowerCase(),
      {}
    );
  }
}
