import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, of, Subject, take, takeUntil, tap } from 'rxjs';
import { NotifierService } from 'src/app/notifier';

import { CommunityRequest } from '../../models';
import { CommunityService } from '../../services';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrl: './list-page.component.css',
})
export class ListPageComponent {
  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  totalPages: any[] = [];
  communityRequests: CommunityRequest[] = [];
  selectedCommunityRequest: CommunityRequest | null = null;
  loading = false;

  private subject$ = new Subject<void>();

  constructor(
    private readonly communityService: CommunityService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getData();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  private getData(): void {
    let parameters = { page: this.currentPage, limit: this.pageSize };

    this.communityService
      .fetchAllCommunities(new HttpParams({ fromObject: parameters }))
      .pipe(
        map((response) => {
          if (response.status !== 'success') {
            throw new Error();
          }

          this.currentPage = response.page;
          this.totalItems = response.total;
          this.pageSize = response.limit;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );

          return response.data;
        }),
        takeUntil(this.subject$),
        catchError(() => of([] as CommunityRequest[]))
      )
      .subscribe({
        next: (data) => {
          this.communityRequests = [...data];
        },
      });
  }

  onNavigate(item: CommunityRequest): void {
    this.selectedCommunityRequest = item;

    this.router.navigate(['./edit', item.community_id], {
      relativeTo: this.route,
    });
  }

  onAccept(): void {
    this.communityService
      .updateCommunityStatus(this.selectedCommunityRequest.community_id, {
        status: 'VALIDATED',
      })
      .pipe(
        tap((res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
          }
        }),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            const index = this.communityRequests.findIndex(
              (cr) =>
                cr.community_id == this.selectedCommunityRequest.community_id
            );

            if (index >= 0) {
              this.communityRequests[index] = res.data;
            }
          }
        },
      });
  }

  onBlock(): void {
    this.communityService
      .updateCommunityStatus(this.selectedCommunityRequest.community_id, {
        status: 'BLOCKED',
      })
      .pipe(
        tap((res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
          }
        }),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            const index = this.communityRequests.findIndex(
              (cr) =>
                cr.community_id == this.selectedCommunityRequest.community_id
            );

            if (index >= 0) {
              this.communityRequests[index] = res.data;
            }
          }
        },
      });
  }
}
