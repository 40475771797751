import { Component, input } from '@angular/core';

import { CountUpDirective } from '../../directives/count-up.directive';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CountUpDirective],
  templateUrl: './card.component.html',
  styleUrl: './card.component.css',
})
export class CardComponent {
  title = input.required<string>();
  count = input.required<number>();
}
