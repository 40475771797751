import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { ListKycTransactionAmountRuleComponent } from './list/list-kyc-transaction-amount-rule.component';
import { CreateKycTransactionAmountRuleComponent } from './create/create-kyc-transaction-amount-rule.component';
import { EditKycTransactionAmountRuleComponent } from './edit/edit-kyc-transaction-amount-rule/edit-kyc-transaction-amount-rule.component';

const routes: Routes = [
  {
    path: 'compliance/kyc/rules/transactions/amount/list',
    component: ListKycTransactionAmountRuleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance/kyc/rules/transactions/amount/create',
    component: CreateKycTransactionAmountRuleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance/kyc/rules/transactions/amount/edit/:ruleId',
    component: EditKycTransactionAmountRuleComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ComplianceKycRoutingModule { }
