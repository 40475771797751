<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Community Creation Requests
            </h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Community Creation Requests
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <div class="block-content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th style="width: 20%">ID</th>
                                    <th style="width: 20%">Community Name</th>
                                    <th style="width: 20%">Description</th>
                                    <th style="width: 20%">Email</th>
                                    <th style="width: 20%">Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!loading">
                                <tr *ngFor="let communityRequest of communityRequests">
                                    <td class="font-w600 font-size-sm">
                                        <a style="cursor: pointer;"
                                           (click)="onNavigate(communityRequest)">
                                            {{ '...' + communityRequest.community_id.slice(-4) }}
                                        </a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a style="cursor: pointer;"
                                           (click)="onNavigate(communityRequest)">
                                            {{ communityRequest.community_name }}
                                        </a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a style="cursor: pointer;"
                                           (click)="onNavigate(communityRequest)">
                                            {{ communityRequest.description }}
                                        </a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a style="cursor: pointer;"
                                           (click)="onNavigate(communityRequest)">
                                            {{ communityRequest.email }}
                                        </a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <!-- <button class="btn btn-sm btn-success mr-1">Accept</button>
                                        <button class="btn btn-sm btn-danger">Block</button> -->

                                        <ng-container
                                                      *ngTemplateOutlet="statusTemp; context: { status: communityRequest.status }">
                                        </ng-container>
                                    </td>
                                    <td class="font-w600 font-size-sm">

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--  pagination -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- max results per page -->
                        <select class="custom-select"
                                style="width: auto"
                                [(ngModel)]="pageSize">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                        </select>

                        <!-- total results -->
                        <span class="text-muted">Showing
                            <span class="badge badge-primary">{{ pageSize }}</span> of
                            <span class="badge badge-primary">{{ totalItems }}</span>
                            results</span>

                        <!-- pagination -->
                        <!-- pagination -->
                        <ul class="pagination">
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(1)">
                                    ««
                                </button>
                            </li>
                            <!-- previous button -->
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="previous()">«</button>
                            </li>
                            <ng-container *ngFor="let p of totalPages; index as i">
                                <li *ngIf="
                    i + 1 >= currentPage - pageSize &&
                    i + 1 <= currentPage + pageSize
                  "
                                    [class]="
                    currentPage === i + 1 ? 'page-item active' : 'page-item'
                  ">
                                    <button class="page-link"
                                            (click)="selectPageNumber(i + 1)">
                                        {{ i + 1 }}
                                    </button>
                                </li>
                            </ng-container>
                            <!-- next button -->
                            <li [class]="
                  currentPage === totalPages.length
                    ? 'page-item disabled'
                    : 'page-item'
                "
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="next()">»</button>
                            </li>
                            <!-- last button -->
                            <li [class]="
                  currentPage === totalPages.length
                    ? 'page-item disabled'
                    : 'page-item'
                "
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(totalPages.length)">
                                    »»
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #statusTemp
             let-status="status">
    <span *ngIf="status === 'ACCEPTED' || status === 'VALIDATED'"
          class="badge badge-success">
        Creation approved by BO
    </span>

    <span *ngIf="status === 'BLOCKED'"
          class="badge badge-danger">
        Creation rejected by BO
    </span>

    <span *ngIf="status === 'PENDING'"
          class="badge badge-primary">
        Creation posted by user
    </span>
</ng-template>
