import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceKycRoutingModule } from './compliance-kyc-routing-module';
import { ListKycTransactionAmountRuleComponent } from './list/list-kyc-transaction-amount-rule.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateKycTransactionAmountRuleComponent } from './create/create-kyc-transaction-amount-rule.component';
import { EditKycTransactionAmountRuleComponent } from './edit/edit-kyc-transaction-amount-rule/edit-kyc-transaction-amount-rule.component';



@NgModule({
  declarations: [ListKycTransactionAmountRuleComponent, CreateKycTransactionAmountRuleComponent, EditKycTransactionAmountRuleComponent],
  imports: [
    CommonModule,
    ComplianceKycRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ComplianceKycModule { }
