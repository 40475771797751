import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { AccountEntity } from './account-entity';
import { ApiService } from  'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetch(
    userId: string,
    accountId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/accounts/' + accountId
    );
  }

  fetchAll(userId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/accounts');
  }

  create(
    userId: string,
    accountEntity: AccountEntity
    ): Observable<any> {
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/accounts',
      accountEntity
    )
  }
}
