<div class="block block-rounded d-flex flex-column h-100 mb-0">
    <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
        <dl class="mb-0">
            <dt class="fs-3 fw-bold" >{{ count() }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">
                {{ title() }}
            </dd>
        </dl>
        <div class="item item-rounded-lg bg-body-light">
            <i class="fa fa-chart-bar fs-3 text-primary"></i>
        </div>
    </div>
</div>