import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { DocumentTypeCreateComponent } from './types/document-type-create/document-type-create.component';
import { DocumentTypeListComponent } from './types/document-type-list/document-type-list.component';


const routes: Routes = [
  {
    path: 'compliance/documents/types/list',
    component: DocumentTypeListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance/documents/types/add/new',
    component: DocumentTypeCreateComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
