import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { KycRegionsCreateComponent } from './kyc-regions-create/kyc-regions-create.component';
import { KycRegionsEditComponent } from './kyc-regions-edit/kyc-regions-edit.component';
import { KycRegionsListComponent } from './kyc-regions-list/kyc-regions-list.component';


const routes: Routes = [
  {
    path: 'compliance/kyc/regions/add/new',
    component: KycRegionsCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance/kyc/regions/list',
    component: KycRegionsListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'compliance/kyc/regions/edit/:regionId',
    component: KycRegionsEditComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegionRoutingModule { }
