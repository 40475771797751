<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Cash Out to Bank Account
        </h1>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center" data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users']">User List</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId]">User</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts']">Accounts</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts', accountId]">Account</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Cash Out
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- Navigation -->

    <div class="content">
        <div class="row">
          <div class="col-md-6">
            <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <p class="mb-0"> {{ alert.message }} </p>
            </div>
            <div class="js-wizard-simple block">
            <!-- Form -->
            <form [formGroup]="bankCashOutForm" (ngSubmit)="createCashOut()">
                <!-- Steps Content -->
                <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
                    <!-- Step 1 -->
                    <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
                        <h2 class="content-heading border-bottom mb-4 pb-2">General Settings</h2>
                        <div class="form-group">
                            <label for="amount">Amount</label>
                            <input class="form-control" type="text" id="amount" name="amount" formControlName="amount">
                        </div>
                        <div class="form-group">
                            <label for="currency">Bank Account</label>
                            <select class="form-control" id="paymentMeanId" name="paymentMeanId" formControlName="paymentMeanId">
                                <option value="0">Please select</option>
                                <option *ngFor= "let paymentMean of paymentMeans" value="{{ paymentMean.paymentMeanId }}">{{ paymentMean.name }}</option>
                            </select>
                        </div>
                    </div>
                    <!-- END Step 1 -->
                </div>
                <!-- END Steps Content -->
    
                <!-- Steps Navigation -->
                <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                    <div class="row">
                        <div class="col-6 text-right">
                            <button type="submit" class="btn btn-primary" data-wizard="finish">
                                <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                            </button>
                        </div>
                    </div>
                </div>
                <!-- END Steps Navigation -->
            </form>
            <!-- END Form -->
    </div>
    </div>
    </div>
    </div>