import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../app/api.service';
import { environment } from '../../../environments/environment';

type Count = {
  status: string;
  data: {
    count: number;
  };
};

type Period = {
  status: string;
  data: {
    count: {
      [key: string]: { count: number }[];
    };
  };
};

type TransactionVolume = {
  status: string;
  data: {
    count: number;
  };
};

type Frequency = 'M' | 'W' | 'D';
type TransactionType = 'IN' | 'OUT' | 'ANY';
type UserStatus = 'active' | 'deleted' | 'inactive';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  fetchPendingSubscriptionsCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/onboarding/subscriptions/pending/count`
    );
  }

  fetchPendingSignaturesCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/onboarding/signature/pending/count`
    );
  }

  fetchPendingKYCCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/onboarding/kyc/pending/count`
    );
  }

  fetchTransactionVolumeCount(
    transaction_type?: TransactionType
  ): Observable<TransactionVolume> {
    let params = new HttpParams();

    if (transaction_type) {
      params = params.set('transaction_type', transaction_type);
    }

    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/transactions/volume`,
      params
    );
  }

  fetchNewUsersCount(status?: UserStatus): Observable<Count> {
    let params = new HttpParams();

    if (status) {
      params = params.set('user_status', status);
    }

    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/users/count`,
      params
    );
  }

  getUserCountPerPeriod(
    data: Partial<{
      frequency: Frequency;
      user_status: UserStatus;
      periods: number;
    }>
  ): Observable<Period> {
    let params = new HttpParams({ fromObject: data });

    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/users/count/per-periods`,
      params
    );
  }

  getTransactionVolumePerPeriod(
    data: Partial<{
      frequency: Frequency;
      transaction_type: TransactionType;
      periods: number;
    }>
  ): Observable<Period> {
    let params = new HttpParams({ fromObject: data });

    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/transactions/volume/per-periods`,
      params
    );
  }

  getTransactionCount(transaction_type?: TransactionType): Observable<any> {
    let params = new HttpParams();

    if (transaction_type) {
      params = params.set('transaction_type', transaction_type);
    }

    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/transactions/count`,
      params
    );
  }

  getTransactionCountPerPeriod(
    data: Partial<{
      frequency: Frequency;
      transaction_type: TransactionType;
      periods: number;
    }>
  ): Observable<Period> {
    let params = new HttpParams({ fromObject: data });

    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/transactions/count/per-periods`,
      params
    );
  }
}
