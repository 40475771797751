<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">User Documents</h1>
        </div>
    </div>
</div>
<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content"
     *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <div class="block">

                <!-- <div class="block-header">
                    <h3 class="block-title"></h3>
                    <div class="block-options">
                        <div class="dropdown">
                            <button type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    id="dropdown-default-primary"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                Actions
                            </button>
                            <div class="dropdown-menu font-size-sm"
                                 aria-labelledby="dropdown-default-primary">
                                <a [routerLink]="['/users', userId, 'documents', 'add', 'new']"
                                   class="dropdown-item"
                                   href="javascript:void(0)">Add new document</a>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="block-content">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <h2 class="flex-sm-fill h4 my-4">Documents</h2>

                        <div class="block-options">
                            <div class="dropdown">
                                <button type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        id="dropdown-default-primary"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false">
                                    Actions
                                </button>
                                <div class="dropdown-menu font-size-sm"
                                     aria-labelledby="dropdown-default-primary">
                                    <a [routerLink]="['/users', userId, 'documents', 'add', 'new']"
                                       class="dropdown-item"
                                       href="javascript:void(0)">Add new document</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>Document Type</th>
                                    <th>Document Version</th>
                                    <th style="width: 15%">DateTime</th>
                                    <th style="width: 15%">Status</th>
                                    <th class="text-center"
                                        style="width: 100px">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let document of documents">
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ document.type }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ document.documentId }}</em>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a href="#">{{
                                            document.timestamp * 1000 | date : "yyyy/MM/dd h:mm:ss a"
                                            }}</a>
                                    </td>
                                    <td>
                                        <span [ngClass]="{
                        badge: true,
                        'badge-success': document.status == 'VALIDATED',
                        'badge-warning': document.status == 'PENDING_REVIEW',
                        'badge-danger': document.status == 'FAILED'
                      }">
                                            {{ document.status }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-group">
                                            <button type="button"
                                                    [routerLink]="[
                          '/users',
                          userId,
                          'documents',
                          document.documentId
                        ]"
                                                    class="btn btn-sm btn-primary"
                                                    data-toggle="tooltip"
                                                    title="Edit">
                                                <i class="fa fa-fw fa-pencil-alt"></i>
                                            </button>
                                            <button type="button"
                                                    class="btn btn-sm btn-primary"
                                                    data-toggle="tooltip"
                                                    title="Delete">
                                                <i class="fa fa-fw fa-times"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="block-content">
                    <h2 class="flex-sm-fill h4 my-4">Errors during Verify KYC checks</h2>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th style="width: 15%">Timestamp</th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let error of errorList">
                                    <tr *ngFor="let e of error.errors">
                                        <td class="font-size-sm">
                                            <em class="text-muted">{{ e.errorCode }}</em>
                                        </td>
                                        <td class="font-size-sm">
                                            <em class="text-muted">{{ e.errorMsg }}</em>
                                        </td>
                                        <td class="font-w600 font-size-sm">
                                            <a href="#">{{
                                                error.timestamp * 1000 | date : 'dd-MM-yyyy h:mm:ss a'
                                                }}</a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>