import { Component, OnInit } from '@angular/core';

import { AccountEntity } from 'src/app/wallet-api/account-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/wallet-api/accounts.service';
import {FormBuilder, Validators} from '@angular/forms';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { TransfersService } from 'src/app/wallet-api/remittances/transfers.service';
import { AccountCollection } from 'src/app/wallet-api/account-collection';
import { BeneficiaryEntity } from 'src/app/wallet-api/beneficiaries/beneficiary-entity';
import { BeneficiaryService } from 'src/app/wallet-api/beneficiaries/beneficiary.service';
import { BeneficiaryCollection } from 'src/app/wallet-api/beneficiaries/beneficiary-collection';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-transfer',
  templateUrl: './create-transfer.component.html',
  styleUrls: ['./create-transfer.component.css']
})
export class CreateTransferComponent implements OnInit {

  transferForm;
  accounts: Array<AccountEntity>;
  beneficiaries: Array<BeneficiaryEntity>;
  userId: string;
  alert: AlertEntity;
  inProgress: boolean;

  constructor(
    private accountService: AccountsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private transferService: TransfersService,
    private beneficiaryService: BeneficiaryService
  ) { 

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;

    this.userId = this.route.snapshot.paramMap.get('userId');
    this.transferForm = this.formBuilder.group({
      amount: 100,
      sender: this.formBuilder.group({
        accountId: '',
      }),
      receiver: this.formBuilder.group({
        beneficiaryId: ''
      })
    });
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.accountService.fetchAll(this.route.snapshot.paramMap.get('userId')).subscribe((response: WalletApiResponse<AccountCollection>) => {
        this.accounts = response.data.walletAccounts
      });

      this.beneficiaryService.fetchAll(
        this.route.snapshot.paramMap.get('userId')
      ).subscribe((response: WalletApiResponse<BeneficiaryCollection>) => {
        this.beneficiaries = response.data.UserBeneficiaries
      });
    })
  }

  transfer(): void{
    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.transferService.create(
        this.transferForm.value
      ).subscribe(response=>{
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Transfer successfully executed ';
      })
    });
  }

}
