<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Wallet Plan Compliance Rules
        </h1>
      </div>
    </div>
  </div>
  
  
  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item active" aria-current="page">
                        Compliance Rule List
                    </li>
                </ol>
            </nav>
        </div>
    </div>
  </div>
  
  <div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center" data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
  </div>

  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
            <div class="block-header">
                <h3 class="block-title"></h3>
                <div class="block-options">
                    <div class="dropdown">
                        <button type="button" class="btn btn-primary dropdown-toggle" id="dropdown-default-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div class="dropdown-menu font-size-sm" aria-labelledby="dropdown-default-primary" style="">
                            <a class="dropdown-item" [routerLink]="['/compliance','wallet-plans', 'add', 'new']" href="javascript:void(0)">Create New Compliance Rule</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-content">
                <p class="font-size-sm text-muted">
                    
                </p>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th style="width: 15%;">Status</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>

                        <div *ngIf="loading">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                              <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                        </div>
                        
                        <tbody *ngIf="!loading">
                            <tr *ngFor= "let rule of rules"> 
                                <td class="font-w600 font-size-sm">
                                    <a href="#">{{ rule.name }}</a>
                                </td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': rule.status == 'active', 'badge-danger': rule.status == 'deactivated'}">
                                        {{ rule.status == 'active' ? 'Active ': 'Disabled' }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>