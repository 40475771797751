import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';

import { environment } from '../../environments/environment';
import { BankTransactionEntity } from './bank-transactions.model';
import { WalletApiResponse } from './wallet-api-response';

@Injectable({
  providedIn: 'root',
})
export class AccountTransactionsService {
  constructor(private apiService: ApiService) {}

  fetchAll(userId: string, accountId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl +
        '/v1/wallets/users/' +
        userId +
        '/accounts/' +
        accountId +
        '/transactions'
    );
  }

  fetchBankTransactions(
    customerId: string,
    query?: any
  ): Observable<WalletApiResponse<BankTransactionEntity[]>> {
    let params = new HttpParams();

    Object.keys(query).forEach((key) => {
      if (typeof query[key] === 'string' && query[key].length <= 0) {
        return;
      }

      params = params.append(key, query[key]);
    });

    return this.apiService.get(
      environment.wallet.bassServiceBaseUrl + `/api/transactions/${customerId}`,
      params
    );
  }
}
