<div class="bg-body-light">
  <div class="content content-full">
    <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
      <h1 class="flex-sm-fill h3 my-2">
        Edit Tontine details
      </h1>
    </div>
  </div>
</div>
<div class="bg-white p-3 push">



  <div class="col-xl-6">
    <div class="block">
      <div class="block-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb push">
            <li class="breadcrumb-item">
              <a routerLinkActive="active"  [routerLink]="['/tontines','list']" href="#">Tontine List</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Update
              Tontine
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <div class="block">
              <div class="block-content">
                <h2 class="content-heading border-bottom mb-4 pb-2">General Actions</h2>
                  <div class="form-group">
                      <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#startTontineModal">
                        <i class="fa fa-hourglass-start" aria-hidden="true"></i> Start Tontine
                      </button>
                  </div>
                  <div class="form-group">
                      <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#startCollectionModal">
                        <i class="fa fa-users" aria-hidden="true"></i> Start Collection
                      </button>
                  </div>
                  <div class="form-group">
                    <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#startDistributionModal">
                      <i class="fa fa-user-circle-o" aria-hidden="true"></i> Start Distribution
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="block">
              <div class="block-content">
                <h2 class="content-heading border-bottom mb-4 pb-2">Remediation</h2>
                <div class="form-group">
                  <button type="button" class="btn btn-warning btn-sm" data-toggle="modal" data-target="#PutTontineInRemediationModal">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Put in Remediation
                  </button>
                </div>
                <div class="form-group">
                  <button type="button" class="btn btn-secondary btn-sm" data-toggle="modal" data-target="#SendNotificationsModal">
                    <i class="fa fa-paper-plane" aria-hidden="true"></i> Send Notifications
                  </button>
                </div>
                <div class="form-group">
                  <button type="button" class="btn btn-danger btn-sm" data-toggle="modal" data-target="#CancelTontineModal">
                    <i class="fa fa-ban" aria-hidden="true"></i> Cancel Tontine
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="js-wizard-simple block">
          <!-- add loading here -->
          <div *ngIf="loading">
              <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
          </div>
        </div>
        <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <p class="mb-0"> {{
              alert.message
            }} </p>
        </div>
        <div class="js-wizard-simple block">
          <!-- Form -->
          <form novalidate>
            <div class="block-content block-content-full tab-content px-md-5">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="content-heading border-bottom mb-4 pb-2">General Information</h2>
                  <div formGroupName="projectDetails">
                    <div class="form-group">
                      <label for="name">Tontine Name</label>
                      <input class="form-control" type="text" id="name" name="name"
                             value="{{ projectDetails.name }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Amount</label>
                      <input class="form-control" type="text" id="amount" name="amount"
                             value="{{ projectDetails.amount }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Frequency</label>
                      <input class="form-control" type="text" id="frequencyOfPayment" name="frequencyOfPayment"
                             value="{{ projectDetails.frequencyOfPayment }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Currency</label>
                      <input class="form-control" type="text" id="currency" name="currency"
                             value="{{ projectDetails.currency }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Start At</label>
                      <input class="form-control" type="text" id="startAt" name="startAt"
                             value="{{ projectDetails.startAt }}">
                    </div>
                    <div class="form-group">
                      <label for="name">End At</label>
                      <input class="form-control" type="text" id="endAt" name="endAt"
                             value="{{ projectDetails.endAt }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Status</label>
                      <input class="form-control" type="text" id="status" name="status"
                             value="{{ projectDetails.status }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Retention Rate</label>
                      <input class="form-control" type="text" id="retentionRate" name="retentionRate"
                             value="{{ projectDetails.retentionRate }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Created At</label>
                      <input class="form-control" type="text" id="createdAt" name="createdAt"
                             value="{{ projectDetails.createdAt }}">
                    </div>
                    <div class="form-group">
                      <label for="name">updated At</label>
                      <input class="form-control" type="text" id="updatedAt" name="updatedAt"
                             value="{{ projectDetails.updatedAt }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Remeditation</label>
                      <input class="form-control" type="text" id="isInRemediation" name="isInRemediation"
                             value="{{ projectDetails.isInRemediation }}">
                    </div>
                    <div class="form-group">
                      <label for="name">Type</label>
                      <input class="form-control" type="text" id="type" name="type"
                             value="{{ projectDetails.type }}">
                    </div>
                  </div>
                  <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                    <div class="row">
                      <div class="col-md-6">
                        <button type="submit" class="btn btn-primary" data-wizard="finish">
                          <!-- <i class="fa fa-check mr-1"></i> Submit -->
                          <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i>
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <h2 class="content-heading border-bottom mb-4 pb-2">Activity Details</h2>
                  <div formGroupName="activityDetails" *ngIf="!loading">
                    <div class="form-group">
                      <label for="numberOfPayers">Number of Payers</label>
                      <input class="form-control" type="text" id="numberOfPayers" name="numberOfPayers"
                             value="{{ numberOfPayers }}" readonly>
                    </div>
                    <div class="form-group">
                      <label for="numberOfBeneficiaries">Number of Beneficiaries</label>
                      <input class="form-control" type="text" id="numberOfBeneficiaries" name="numberOfBeneficiaries"
                             value="{{ numberOfBeneficiaries }}" readonly>
                    </div>
                    <div class="form-group">
                      <label for="currentToReceive">Current Reveiver</label>
                      <input *ngIf="currentToReceive" class="form-control" type="text" id="currentToReceive" name="currentToReceive"
                             value="{{ currentToReceive.beneficiaryDetails.details.firstName }} {{ currentToReceive.beneficiaryDetails.details.lastName }}"
                             readonly>
                    </div>
                    <div class="form-group">
                      <label for="nextToReceived">Next Reveiver</label>
                      <input *ngIf="nextToReceived" class="form-control" type="text" id="nextToReceived" name="nextToReceived"
                             value="{{ nextToReceived.beneficiaryDetails.details.firstName }} {{ nextToReceived.beneficiaryDetails.details.lastName }}"
                             readonly>
                    </div>
                  </div>
                  <h2 class="content-heading border-bottom mb-4 pb-2">Payers</h2>
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                      <thead>
                      <tr>
                        <th style="width: 30%;">Name</th>
                        <th style="width: 15%;">Email</th>
                        <th style="width: 15%;">MobileNumber</th>
                      </tr>
                      </thead>
                      <tbody *ngIf="!loading">
                      <tr *ngFor="let payer of payers">
                        <td class="font-size-sm">
                          <em class="text-muted">{{ payer.details.firstName }} {{ payer.details.lastName }}</em>
                        </td>
                        <td class="font-size-sm">
                          <em class="text-muted">{{ payer.details.email }}</em>
                        </td>
                        <td class="font-w600 font-size-sm">
                          <em class="text-muted">{{ payer.details.mobileNumber }}</em>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <h2 class="content-heading border-bottom mb-4 pb-2">Beneficiaries</h2>
                  <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                      <thead>
                      <tr>
                        <th style="width: 30%;">Name</th>
                        <th style="width: 15%;">Email</th>
                        <th style="width: 15%;">MobileNumber</th>
                      </tr>
                      </thead>
                      <tbody *ngIf="!loading">
                      <tr *ngFor="let beneficiary of beneficiaries">
                        <td class="font-size-sm">
                          <em
                            class="text-muted">{{ beneficiary.details.firstName }} {{ beneficiary.details.lastName }}</em>
                        </td>
                        <td class="font-size-sm">
                          <em class="text-muted">{{ beneficiary.details.email }}</em>
                        </td>
                        <td class="font-w600 font-size-sm">
                          <em class="text-muted">{{ beneficiary.details.mobileNumber }}</em>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
            <!-- END Steps Navigation -->
          </form>

          <!-- END Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Start Tontine -->
<div class="modal fade" id="startTontineModal" tabindex="-1" role="dialog" aria-labelledby="startTontineTtile" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="startTontineTtile">Start a tontine</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you confirm, you want to start tontine: <strong>{{ projectDetails.name }}</strong>?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" >Cancel</button>
        <button (click)="startTontine()" data-dismiss="modal" type="button" class="btn btn-primary btn-sm">Start Tontine</button>
      </div>
    </div>
  </div>
</div>

<!-- Start Collection -->
<div class="modal fade" id="startCollectionModal" tabindex="-1" role="dialog" aria-labelledby="startCollectionTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="startCollectionTitle">Collection</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you confirm, you want to start collection for tontine: <strong>{{ projectDetails.name }}</strong>?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
        <button (click)="startCollection()" data-dismiss="modal" type="button" class="btn btn-primary btn-sm">Start Collection</button>
      </div>
    </div>
  </div>
</div>

<!-- Start Distribution -->
<div class="modal fade" id="startDistributionModal" tabindex="-1" role="dialog" aria-labelledby="startDistributionTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="startDistributionTitle">Distribution</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you confirm, you want to start distribution for tontine: <strong>{{ projectDetails.name }}</strong>?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
        <button (click)="startDistribution()" data-dismiss="modal" type="button" class="btn btn-primary btn-sm">Start Distribution</button>
      </div>
    </div>
  </div>
</div>


<!-- Put Tontine In Remediation -->
<div class="modal fade" id="PutTontineInRemediationModal" tabindex="-1" role="dialog" aria-labelledby="PutTontineInRemediationTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="PutTontineInRemediationTitle">Remediation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you confirm, you want to put tontine <strong>{{ projectDetails.name }}</strong> in remediation?:
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
        <button (click)="putTontineInRemediation()" data-dismiss="modal" type="button" class="btn btn-primary btn-sm">Put In Remediation</button>
      </div>
    </div>
  </div>
</div>

<!-- Send Notification -->
<div class="modal fade" id="SendNotificationsModal" tabindex="-1" role="dialog" aria-labelledby="SendNotificationsTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="SendNotificationsTitle">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you confirm, you want to send notifications to the participants who haven't paid?:
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
        <button (click)="sendNotifications()" data-dismiss="modal" type="button" class="btn btn-primary btn-sm">Send Notifications</button>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Tontine -->
<div class="modal fade" id="CancelTontineModal" tabindex="-1" role="dialog" aria-labelledby="CancelTontineTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="CancelTontineTitle">Tontine Cancellation </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Do you confirm, you want to send cancel tontine <strong>{{ projectDetails.name }}</strong>?:
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button>
        <button (click)="cancelTontine()" data-dismiss="modal" type="button" class="btn btn-primary btn-sm">Confirm</button>
      </div>
    </div>
  </div>
</div>
