import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { map, switchMap, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

import { UserService } from '../user.service';

type ErrorList = {
  timestamp: number;
  entityId: string;
  errors: any[];
};

@Component({
  selector: 'app-kyc-error-details',
  templateUrl: './kyc-error-details.component.html',
  styleUrl: './kyc-error-details.component.css',
})
export class KycErrorDetailsComponent implements OnInit {
  errorList: ErrorList[] = [];
  loading: boolean;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  totalPages: any[] = [];

  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.getKycErrorDetails();
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getKycErrorDetails();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  getKycErrorDetails(): void {
    let params = new HttpParams();

    params = params.set('limit', this.pageSize);
    params = params.set('page', this.currentPage);

    this.authService
      .token()
      .pipe(
        switchMap(() => this.userService.fetchKycErrorDetails(params)),
        map((res) => res.data.events),
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.loading = false;

          let errorList = res.walletAccountTransactions.map((t) => {
            return {
              timestamp: t.timestamp,
              entityId: t.entityId,
              errors: Object.keys(t.data.data).map((key) => {
                if (t.data.data['errorCode'] && t.data.data['errorMsg']) {
                  return {
                    errorCode: t.data.data['errorCode'],
                    errorMsg: Array.isArray(t.data.data['errorMsg'])
                      ? t.data.data['errorMsg'].join()
                      : t.data.data['errorMsg'],
                  };
                }

                return t.data.data[key];
              }),
            };
          });

          this.errorList = [...errorList];

          this.totalItems = res.total;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );
        },
        error: console.error,
      });
  }
}
