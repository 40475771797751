import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { KycRegionCollection } from 'src/app/compliance-api/kyc/regions/kyc-region-collection';
import { KycRegionEntity } from 'src/app/compliance-api/kyc/regions/kyc-region-entity';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-kyc-regions-list',
  templateUrl: './kyc-regions-list.component.html',
  styleUrls: ['./kyc-regions-list.component.css']
})
export class KycRegionsListComponent implements OnInit {
  regions: Array<KycRegionEntity>
  loading: boolean;
  constructor(
    private kycRegionService: KycRegionsService,
    private authService: AuthService
  ) { 
    this.loading = true;
  }

  ngOnInit(): void {

    this.authService.token().subscribe(() => {
      this.kycRegionService.fetchAll().subscribe((response: WalletApiResponse<KycRegionCollection>) => {
        this.regions = response.data.Regions;
        this.loading = false;
      });
    });
  }

}
