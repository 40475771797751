<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Distributions</h1>
        </div>
    </div>
</div>

<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <!-- add user search field  -->
                <form #searchForm="ngForm"
                      class="mb-4 pt-5">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group ml-5">
                                <input placeholder="Start Date"
                                       type="text"
                                       class="js-flatpickr form-control"
                                       id="start_date"
                                       name="start_date"
                                       [(ngModel)]="start_date"
                                       appSearch
                                       (search)="onSearch($event, '')"
                                       data-enable-time="true"
                                       data-time_24hr="true">
                            </div>
                            <div class="form-group ml-5">
                                <input placeholder="Tontine ID"
                                       type="text"
                                       id="tontineId"
                                       name="tontineId"
                                       [(ngModel)]="tontineId"
                                       class="form-control"
                                       appSearch
                                       (search)="onSearch($event, '')" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mr-5">
                                <input placeholder="End Date"
                                       type="text"
                                       class="js-flatpickr form-control"
                                       id="end_date"
                                       name="end_date"
                                       [(ngModel)]="end_date"
                                       appSearch
                                       (search)="onSearch($event, '')"
                                       data-enable-time="true"
                                       data-time_24hr="true">
                            </div>
                            <div class="mr-5">
                                <select class="custom-select"
                                        id="example-select"
                                        name="example-select"
                                        [(ngModel)]="status"
                                        (ngModelChange)="onSearch($event, '')">
                                    <option [ngValue]="'STARTED'">STARTED</option>
                                    <option [ngValue]="'COMPLETED'">COMPLETED</option>
                                    <option [ngValue]="'DELETED'">DELETED</option>
                                    <option [ngValue]="'CANCELLED'">CANCELLED</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="block-content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th style="width: 15%">Project Name</th>
                                    <th style="width: 15%">Start At</th>
                                    <th style="width: 15%">End At</th>
                                    <th class="text-center"
                                        style="width: 100px">Details</th>
                                    <th class="text-center"
                                        style="width: 100px">Make Payment</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!loading">
                                <tr *ngFor="let distribution of distributions">
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ distribution.amount | currency: 'EUR' }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ distribution.status }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ distribution.project_name }}</em>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a href="#">{{ distribution.start_date * 1000 | date: 'yyyy/MM/dd h:mm:ss a'
                                            }}</a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a href="#">{{ distribution.end_date * 1000 | date: 'yyyy/MM/dd h:mm:ss a'
                                            }}</a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a data-toggle="modal"
                                           data-target="#view-object"
                                           style="cursor: pointer;"
                                           (click)="getDistribution(distribution.distribution_id)">
                                            ...{{ distribution.distribution_id.slice(-4).toUpperCase() }}
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <button data-toggle="modal"
                                                data-target="#sca"
                                                class="btn btn-primary btn-sm"
                                                (click)="selectedDistributionId = distribution.distribution_id">
                                            <i class="fa fa-money-bill"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--  pagination -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- max results per page -->
                        <select class="custom-select"
                                style="width: auto"
                                [(ngModel)]="pageSize">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                        </select>

                        <!-- total results -->
                        <span class="text-muted">Showing
                            <span class="badge badge-primary">{{ pageSize }}</span> of
                            <span class="badge badge-primary">{{ totalItems }}</span>
                            results</span>

                        <!-- pagination -->
                        <ul class="pagination">
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(1)">
                                    ««
                                </button>
                            </li>
                            <!-- previous button -->
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="previous()">«</button>
                            </li>
                            <ng-container *ngFor="let p of totalPages; index as i">
                                <li *ngIf="
                      i + 1 >= currentPage - pageSize &&
                      i + 1 <= currentPage + pageSize
                    "
                                    [class]="
                      currentPage === i + 1 ? 'page-item active' : 'page-item'
                    ">
                                    <button class="page-link"
                                            (click)="selectPageNumber(i + 1)">
                                        {{ i + 1 }}
                                    </button>
                                </li>
                            </ng-container>
                            <!-- next button -->
                            <li [class]="
                    currentPage === totalPages.length
                      ? 'page-item disabled'
                      : 'page-item'
                  "
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="next()">»</button>
                            </li>
                            <!-- last button -->
                            <li [class]="
                    currentPage === totalPages.length
                      ? 'page-item disabled'
                      : 'page-item'
                  "
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(totalPages.length)">
                                    »»
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal"
     id="view-object"
     tabindex="-2"
     role="dialog"
     aria-labelledby="view-object"
     aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Distribution Details</h3>
                    <div class="block-options">
                        <button type="button"
                                class="btn-block-option"
                                data-dismiss="modal"
                                aria-label="Close">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="fs-sm p-1"
                     style="display: flex; justify-content: center; align-items: center;">
                    <pre><code>{{ distribution | json }}</code></pre>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal"
     id="sca"
     tabindex="-3"
     role="dialog"
     aria-labelledby="sca"
     aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Make Payment</h3>
                    <div class="block-options">
                        <button type="button"
                                class="btn-block-option"
                                data-dismiss="modal"
                                aria-label="Close">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="fs-sm p-2">

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input placeholder="SCA Code"
                                       type="text"
                                       [(ngModel)]="scacode"
                                       class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <button data-dismiss="modal"
                                    aria-label="Close"
                                    class="btn btn-sm btn-primary"
                                    (click)="validatePayment()">Validate Payment</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>