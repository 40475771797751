import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

export interface BankPayloadsAPIResponse {
  status: string;
  data: {
    message: {
      action: string;
    }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class BankPayloadsService {
  constructor(private apiService: ApiService) {}

  fetchAll(
    userId: string,
    httpParams: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.bassServiceBaseUrl + '/api/bank-payloads/' + userId,
      httpParams
    );
  }

  fetchBankPayloadActions(desc: string): Observable<BankPayloadsAPIResponse> {
    return this.apiService.get(
      `${environment.wallet.bassServiceBaseUrl}/api/bank-payloads/actions/list?query=${desc}`
    );
  }
}
