import { Component, OnInit } from '@angular/core';
import { AccountEntity } from 'src/app/wallet-api/account-entity';
import { AccountsService } from 'src/app/wallet-api/accounts.service';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { OrganizationAccountService } from 'src/app/wallet-api/organization/organization-account.service';
import { OrganizationAccountCollection } from 'src/app/wallet-api/organization/organization-account-collection';

@Component({
  selector: 'app-list-organization-accounts',
  templateUrl: './list-organization-accounts.component.html',
  styleUrls: ['./list-organization-accounts.component.css']
})
export class ListOrganizationAccountsComponent implements OnInit {

  accounts: Array<AccountEntity> = [];
  loading: boolean;

  constructor(
    private accountService: OrganizationAccountService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { 
    this.loading = true;
  }

  ngOnInit(): void {
    
    this.authService.token().subscribe(() => {
      this.accountService.fetchAll().subscribe((response: WalletApiResponse<OrganizationAccountCollection>) => {
        this.accounts = response.data.OrganizationAccounts
        this.loading = false;
      })
    })
  }

}
