import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from '../chats.service';
type User = {
  userId: string;
};
@Component({
  selector: 'app-list-chats',
  templateUrl: './list-chats.component.html',
  styleUrls: ['./list-chats.component.css']
})
export class ListChatsComponent implements OnInit {

  loading: boolean;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  totalPages: any[] = [];
  channels: Array<any> = [];
  allChannels: Array<any> = [];
  userId: string = '';
  channelSubject: string= '';
  message: string = '';
  
  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  
  constructor(
    private authService: AuthService,
    private chatService: ChatService
  ) {
    this.loading = true;
    if(window.One != undefined){
      window.One.loader('show')
    }
   }
    
   ngOnInit(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      if(window.One != undefined){
        window.One.loader('hide')
      }
      this.chatService.fetchAll().subscribe((res: any) => {
        this.allChannels = res.data;
        this.channels = res.data.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
        this.totalItems = res.data.length;
        this.totalPages = Array.from({length: Math.ceil(this.totalItems / this.pageSize)}, (v, k) => k + 1);
        this.loading = false;
      })
    });
  }
  
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.channels = this.allChannels.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
  }

  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  refresh() {
    this.ngOnInit()
  }
  convertToTime(createdAt: string): string {
    const date = new Date(+createdAt * 1000);
    return date.toLocaleString(); 
  }

  btnCreateClicked() {
    this.authService.token().subscribe(() => {
      const data = {
        "userId": this.userId,
        "subject": this.channelSubject
      }
      this.chatService.createChannel(data).subscribe((res: any) => {
        if (res.status !== 'success') {
          this.message = res.statusDescription;
          return 
        }
        this.message = '';
        this.refresh()
      })
    });
  }
}
