<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Organization Account Transactions
        </h1>
      </div>
    </div>
  </div>
  
  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/organizations']">Organization</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/organizations', 'accounts']">Account List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/organizations', 'accounts', accountId]">Account</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Transactions
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
            <div class="block-header">
                <h3 class="block-title"></h3>
                <div class="block-options">
                    <div class="dropdown">
                        <button type="button" class="btn btn-primary dropdown-toggle" id="dropdown-default-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div class="dropdown-menu font-size-sm" aria-labelledby="dropdown-default-primary" style="">
                            <a href="#">...</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-content">
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th>DateTime</th>
                                <th style="width: 30%;">Amount</th>
                                <th style="width: 15%;">Description</th>
                            </tr>
                        </thead>
                        <div *ngIf="loading">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                              <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                        </div>
                        <tbody *ngIf="!loading">
                            <tr *ngFor= "let transaction of transactions"> 
                                <td class="font-w600 font-size-sm">
                                    <a href="#">{{ transaction.datetime*1000 | date: 'yyyy/MM/dd h:mm:ss a'}}</a>
                                </td>
                                <td class="font-size-sm">
                                    <em class="text-muted">{{ transaction.amount / 100 | currency: 'EUR' }}</em>
                                </td>
                                <td class="font-size-sm">
                                    <em class="text-muted">{{ transaction.description }}</em>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  