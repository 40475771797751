import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { DetailComponent } from './detail/detail.component';
import { OrganizationsRoutingModule } from './organizations-routing.module';
import { ListOrganizationAccountsComponent } from './accounts/list-organization-accounts/list-organization-accounts.component';
import { CreateOrganizationAccountComponent } from './accounts/create-organization-account/create-organization-account.component';
import { ListOrganizationAccountTransactionsComponent } from './accounts/transactions/list-organization-account-transactions/list-organization-account-transactions.component';
import { EditOrganizationAccountComponent } from './accounts/edit-organization-account/edit-organization-account.component';
import { CreateBankAccountPayoutComponent } from './accounts/payout/create-bank-account-payout/create-bank-account-payout.component';


@NgModule({
  declarations: [DetailComponent, ListOrganizationAccountsComponent, CreateOrganizationAccountComponent, ListOrganizationAccountTransactionsComponent, EditOrganizationAccountComponent, CreateBankAccountPayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrganizationsRoutingModule
  ]
})
export class OrganizationsModule { }