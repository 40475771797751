import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

import { DistributionsApiResponse } from './distributions-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class DistributionService {
  constructor(private apiService: ApiService) {}

  fetch(queryParams?: HttpParams): Observable<DistributionsApiResponse> {
    return this.apiService.get(
      `${environment.wallet.tontineRemittanceServiceBaseUrl}/api/tontines/remediation/distributions/search`,
      queryParams
    );
  }

  fetchBy(distributionId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.tontineRemittanceServiceBaseUrl}/api/tontines/remediation/distributions/${distributionId}`
    );
  }

  validatePayment(distId: string, data: any): Observable<any> {
    return this.apiService.post(
      `${environment.wallet.walletGwBaseUrl}/v1/wallets/nbk-bank-accounts/outgoing-transfers/${distId}/distributions`,
      data
    );
  }
}
