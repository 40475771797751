import { Injectable } from '@angular/core';
import { ApiService } from  'src/app/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { TransferEntity } from './transfer-entity';


@Injectable({
  providedIn: 'root'
})
export class TransfersService {

  constructor(
    private apiService: ApiService
  ) {
  }

  create(transferEntity: TransferEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/remittances',
      transferEntity
    )
  }
}
