import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { map, switchMap, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'src/app/notifier';

import { UserService } from '../user.service';

@Component({
  selector: 'app-money-trans-ekyc-errors',
  templateUrl: './money-trans-ekyc-errors.component.html',
  styleUrl: './money-trans-ekyc-errors.component.css',
})
export class MoneyTransEKYCErrorsComponent implements OnInit {
  data: any[] = [];
  loading: boolean;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  totalPages: any[] = [];
  firstLastButtons = false;
  nextPreviousButtons = true;

  userId = '';
  start_date = '';
  end_date = '';
  status = '';
  firstName = '';
  lastName = '';
  email = '';
  mobileNumber = '';

  selectedSessionId = '';
  selectedSessionStatus = '';
  currentIndex = 0;

  statuses: string[] = [];

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private notifier: NotifierService
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.onSearch();

    this.userService.fetchSessionsStatuses().subscribe({
      next: (res) => {
        this.statuses = res;
      },
    });
  }

  onSearch(event?: Event): void {
    let params = {};

    params['limit'] = this.pageSize;
    params['offset'] = (this.currentPage - 1) * 10;

    if (this.userId) {
      params['customer_id'] = this.userId;
    }

    if (this.end_date) {
      const dateTime = this.end_date.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      params['to_timestamp'] = (date.getTime() / 1000).toString();
    }

    if (this.start_date) {
      const dateTime = this.start_date.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      params['from_timestamp'] = (date.getTime() / 1000).toString();
    }

    if (this.email) {
      params['email'] = this.email;
    }

    if (this.mobileNumber) {
      params['mobile_number'] = this.mobileNumber;
    }

    if (this.status) {
      params['session_status'] = this.status;
    }

    if (this.firstName) {
      params['first_name'] = this.firstName;
    }

    if (this.lastName) {
      params['last_name'] = this.lastName;
    }

    const parameters = new HttpParams({ fromObject: params });

    this.authService
      .token()
      .pipe(
        switchMap(() => this.userService.fetchEkycIdentities(parameters)),
        map((res) => {
          this.totalItems = res.metadata.total_count;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );

          return res.data.map((i) => {
            return {
              firstName: i.identity
                ? i.identity.ownerData.firstNames.join(' ')
                : i.user_provided.first_name,
              lastName: i.identity
                ? i.identity.ownerData.lastNames.join(' ')
                : i.user_provided.last_name,
              email: i.user_provided ? i.user_provided.email : null,
              mobileNumber: i.user_provided
                ? i.user_provided.mobile_number
                : null,
              status: i.status,
              createdAt: i.created_at,
              sessionId: i.session_id,
              registered: !!i.nbk_customer_id,
              userDetailsUrl: i.nbk_customer_id
                ? '/users/' + i.nbk_customer_id
                : 'javascript:void(0);',
            };
          });
        }),
        take(1)
      )
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.data = [...res];
        },
      });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.onSearch();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  updateSessionStatus(): void {
    this.userService
      .updateEkycStatus(this.selectedSessionId, this.selectedSessionStatus)
      .subscribe({
        next: (res) => {
          this.notifier.success(res.status);

          if (res.status === 'success') {
            this.data[this.currentIndex].status = this.selectedSessionStatus;
          }
        },
      });
  }
}
