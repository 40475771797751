import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topup-account',
  templateUrl: './topup-account.component.html',
  styleUrls: ['./topup-account.component.css']
})
export class TopupAccountComponent implements OnInit {

  stripeTopUpForm;
  userId: string;
  accountId: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) { 

    this.accountId = this.route.snapshot.paramMap.get('accountId');
    this.userId =  this.route.snapshot.paramMap.get('userId');

    this.stripeTopUpForm = this.formBuilder.group({
      amount : 200,
      currency: 'EUR'
    });
  
  }

  ngOnInit(): void {

  }

  topUp(): void{
    console.dir(this.stripeTopUpForm);
    window.open(
      environment.wallet.paymentServiceBaseUrl + '/v1/stripe/payments/form/'
      + this.stripeTopUpForm.value.amount + '/'
      + this.stripeTopUpForm.value.currency + '/'
      + this.route.snapshot.paramMap.get('accountId') + '/'
      + this.route.snapshot.paramMap.get('userId'))
  }

}
