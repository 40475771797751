<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Wallet Account Top Up
        </h1>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center" data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users']">User List</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId]">User</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts']">Accounts</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts', accountId]">Account</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Top Up
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- Navigation -->
<!--     <div id="horizontal-navigation-hover-centered" class="d-none d-lg-block mt-2 mt-lg-0">
        <ul class="nav-main nav-main-horizontal nav-main-hover nav-main-horizontal-center">
        </ul>
    </div> -->
    <!-- END Navigation -->
</div> 

<div class="content">
    <div class="row">
      <div class="col-md-6">
        <div [ngClass]="{'alert': true, 'alert-success': true, 'alert-dismissable': true, 'alert-warning': true}" role="alert" *ngIf="alert.display">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <p class="mb-0"> {{ alert.message }} </p>
        </div>
<div class="js-wizard-simple block">

    <!-- Form -->
    <form [formGroup]="mtnTopUpForm" (ngSubmit)="topUp()">
        <!-- Steps Content -->
        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
            <!-- Step 1 -->
            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
                <span class="">
                    <img class="rounded" src="../assets/img/mtn_logo.png" alt="Header Avatar" style="width: 50px;">
                  </span>
                <div class="form-group">
                    <label for="amount">Amount (Cents) </label>
                    <input class="form-control" type="text" id="amount" name="amount" formControlName="amount">
                </div>
                <div class="form-group">
                    <label for="currency">Currency</label>
                    <input class="form-control" type="text" readonly id="currency" name="currency" formControlName="currency">
                </div>
                <div class="form-group">
                    <label for="kycRegions">
                        Regions
                        <a target="_blank" [routerLink]="['/compliance','kyc', 'regions', 'add', 'new']">
                            [ <i class="nav-main-link-icon fas fa-plus"></i> Create a new region]</a>
                        [ <a (click)="refresh()" href="javascript:;"><i
                                class="nav-main-link-icon fas fa-sync-alt"></i>Refresh</a>]
                        <i *ngIf="loadingRegions" class="nav-main-link-icon fa fa-sun fa-spin">
                        </i>
                    </label>

                    <select class="form-control" formControlName="regions" id="regions" name="regions" multiple>
                        <option></option>
                        <option *ngFor="let region of regions" value="{{region.regionId}}">{{region.name}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- END Step 1 -->
        </div>
        <!-- END Steps Content -->

        <!-- Steps Navigation -->
        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
                <div class="col-6 text-right">
                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                    </button>
                </div>
            </div>
        </div>
        <!-- END Steps Navigation -->
    </form>
    <!-- END Form -->
</div>
</div>
</div>
</div>
