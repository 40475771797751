import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentTypeListComponent } from './types/document-type-list/document-type-list.component';
import { DocumentTypeCreateComponent } from './types/document-type-create/document-type-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeNamePipe } from './types/type-name.pipe';


@NgModule({
  declarations: [DocumentTypeListComponent, DocumentTypeCreateComponent, TypeNamePipe],
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class DocumentsModule { }
