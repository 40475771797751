import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MTNCashInService } from 'src/app/payment-api/mtn/cash-in.service';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { TransactionAmountKycRuleDocumentService } from 'src/app/compliance-api/kyc/rules/transactions/amount/documents/transaction-amount-kyc-rule-document.service';
import { CashInEntity } from 'src/app/payment-api/cash-in-entity';
import { Observable } from 'rxjs';
import { KycRegionEntity } from 'src/app/compliance-api/kyc/regions/kyc-region-entity';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { KycRegionCollection } from 'src/app/compliance-api/kyc/regions/kyc-region-collection';

@Component({
  selector: 'app-mtn',
  templateUrl: './mtn.component.html',
  styleUrls: ['./mtn.component.css']
})
export class MtnComponent implements OnInit {

  mtnTopUpForm;
  userId: string;
  accountId: string;
  alert: AlertEntity;
  inProgress: boolean;
  loadingRegions: boolean;
  regions: Array<KycRegionEntity>

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private mtnCashInService: MTNCashInService,
    private kycRuleDocumentService: TransactionAmountKycRuleDocumentService,
    private kycRegionService: KycRegionsService,
    private authService: AuthService,
  ) {

    this.accountId = this.route.snapshot.paramMap.get('accountId');
    this.userId = this.route.snapshot.paramMap.get('userId');

    this.mtnTopUpForm = this.formBuilder.group({
      amount: 200,
      currency: 'EUR',
      regions: ''
    });

    this.alert = {
      display: false,
      message: '',
      level: 'success'
    };

    this.inProgress = false;
  }

  ngOnInit(): void {
    this.loadingRegions = true;
    this.authService.token().subscribe(() => {
      this.kycRegionService.fetchAll().subscribe((response: WalletApiResponse<KycRegionCollection>) => {
        this.regions = response.data.Regions;
        this.loadingRegions = false;
      });
    });
  }

  topUp(): void {
    this.inProgress = true;

    this.checkKYC().subscribe((response) => {
      if ('NO_KYC_DOCUMENT_REQUIRED' == response.data.status ||
        response.data.actions.some(action => 'REPORT_TRANSACTION' == action)
      ) {
        this.mtnCashInService.cashIn(
          this.route.snapshot.paramMap.get('accountId'),
          {
            amount: this.mtnTopUpForm.value.amount,
            type: "MTN",
            currency: this.mtnTopUpForm.value.currency,
            originator: {
              originatorType: "User",
              originatorId: this.route.snapshot.paramMap.get('userId')
            },
            description: "Top Up",
            regions: this.mtnTopUpForm.value.regions
          }
        ).subscribe((response) => {
          this.inProgress = false;
          this.alert.display = true;
          this.alert.message = 'Request to pay successfully sent';
        });
      } else {
        this.inProgress = false;
        this.alert.display = true;
        this.alert.level = 'warning';

        this.alert.message = 'Please provide the following documents: ' + response.data.documents.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.name + ' '
        },' ');
      }
    })
  }

  checkKYC() {
    return this.kycRuleDocumentService.getDocuments(
      {
        amount: this.mtnTopUpForm.value.amount,
        type: "MTN",
        currency: this.mtnTopUpForm.value.currency,
        originator: {
          originatorType: "User",
          originatorId: this.route.snapshot.paramMap.get('userId')
        },
        accountId: this.route.snapshot.paramMap.get('accountId'),
        description: "Top Up"
      }
    )
  }

  refresh() {
    this.ngOnInit()
  }
}
