import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ChatsModule } from './chats/chats.module';
import { CommunityAndServiceModule } from './community-and-service/community-and-service.module';
import { DocumentsModule } from './compliance/documents/documents.module';
import { RegionModule } from './compliance/kyc/region/region.module';
import { ComplianceKycModule } from './compliance/kyc/rules/transactions/compliance-kyc-module';
import { WalletPlanModule } from './compliance/wallet-plan/wallet-plan.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { EventsModule } from './events/events.module';
import { FeesModule } from './fees/fees.module';
import { TontineModule } from './fund-raising/tontine.module';
import { MarketingModule } from './marketing/marketing.module';
import { NotifierModule } from './notifier/notifier.module';
import { OrganizationsModule } from './organizations/organizations.module';
import { PlansModule } from './plans/plans.module';
import { ServiceStatusModule } from './service-status/service-status.module';
import { SupportCasesModule } from './support-cases/support-cases.module';
import { UserModule } from './user/user.module';
import { WalletEventsModule } from './wallet-events/wallet-events.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    PlansModule,
    HttpClientModule,
    FormsModule,
    OrganizationsModule,
    UserModule,
    MarketingModule,
    WalletPlanModule,
    EventsModule,
    ComplianceKycModule,
    RegionModule,
    DocumentsModule,
    FeesModule,
    TontineModule,
    DashboardModule,
    NotifierModule,
    ServiceStatusModule,
    CommunityAndServiceModule,
    WalletEventsModule,
    SupportCasesModule,
    JwtModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true, // Prevent duplicate toasts
      progressBar: true, // Show progress bar
      timeOut: 5000, // Time to automatically close the toast (in milliseconds)
      extendedTimeOut: 1000,
    }),
    ChatsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
