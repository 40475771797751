<div class="bg-body-light">
  <div class="content content-full">
    <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
      <h1 class="flex-sm-fill h3 my-2">MoneyTrans EKYC Sessions</h1>
    </div>
  </div>
</div>

<div class="js-wizard-simple block">
  <div class="text-center"
       *ngIf="loading"
       style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
    <div class="spinner-border"
         style="width: 3rem; height: 3rem"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow"
         style="width: 3rem; height: 3rem"
         role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="block">
        <!-- add user search field  -->
        <form #searchForm="ngForm"
              class="mb-4 pt-5">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group ml-5">
                <input placeholder="First Name"
                       type="text"
                       class="form-control"
                       id="firstName"
                       name="firstName"
                       [(ngModel)]="firstName"
                       appSearch
                       (search)="onSearch($event)">
              </div>
              <div class="form-group ml-5">
                <input placeholder="Email"
                       type="text"
                       id="email"
                       name="email"
                       [(ngModel)]="email"
                       class="form-control"
                       appSearch
                       (search)="onSearch($event)"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mr-5">
                <input placeholder="Last Name"
                       type="text"
                       class="form-control"
                       id="lastName"
                       name="lastName"
                       [(ngModel)]="lastName"
                       appSearch
                       (search)="onSearch($event)">
              </div>
              <div class="mr-5">
                <input placeholder="Mobile Number"
                       type="text"
                       class="form-control"
                       id="mobileNumber"
                       name="mobileNumber"
                       [(ngModel)]="mobileNumber"
                       appSearch
                       (search)="onSearch($event)">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group ml-5">
                <input placeholder="Start Date"
                       type="text"
                       class="js-flatpickr form-control"
                       id="start_date"
                       name="start_date"
                       [(ngModel)]="start_date"
                       appSearch
                       (search)="onSearch($event)"
                       data-enable-time="true"
                       data-time_24hr="true">
              </div>
              <div class="form-group ml-5">
                <input placeholder="User ID"
                       type="text"
                       id="userId"
                       name="userId"
                       [(ngModel)]="userId"
                       class="form-control"
                       appSearch
                       (search)="onSearch($event)"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mr-5">
                <input placeholder="End Date"
                       type="text"
                       class="js-flatpickr form-control"
                       id="end_date"
                       name="end_date"
                       [(ngModel)]="end_date"
                       appSearch
                       (search)="onSearch($event)"
                       data-enable-time="true"
                       data-time_24hr="true">
              </div>
              <div class="mr-5">
                <select class="custom-select"
                        id="example-select"
                        name="example-select"
                        [(ngModel)]="status"
                        (ngModelChange)="onSearch($event)">
                  <option *ngFor="let item of statuses"
                          [ngValue]="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>

        <div class="block-content">
          <h2 class="flex-sm-fill h4 my-4"></h2>
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-vcenter">
              <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Status</th>
                <th style="width: 15%">Creation Date</th>
              </tr>
              </thead>

              <tbody>

              <!-- <tr *ngFor="let e of data">
                  <td class="font-size-sm">
                      <a [routerLink]="['/users', error.entityId, 'documents']">
                          {{"..." + error.entityId.slice(-4).toUpperCase()}}
                      </a>
                  </td>
                  <td class="font-size-sm">
                      <em class="text-muted">{{ e.errorCode }}</em>
                  </td>
                  <td class="font-size-sm">
                      <em class="text-muted">{{ e.errorMsg }}</em>
                  </td>
                  <td class="font-w600 font-size-sm">
                      <a href="#">
                          {{ error.timestamp * 1000 | date : 'dd-MM-yyyy h:mm:ss a'}}
                      </a>
                  </td>
              </tr> -->
              <tr *ngFor="let item of data; let i = index">
                <td>{{ item.firstName }}</td>
                <td>{{ item.lastName }}</td>
                <td>
                  <a target="_blank" href="{{item.userDetailsUrl}}">{{ item.email }}</a>
                  &nbsp;
                  <span [ngClass]="{
                        badge: true,
                        'badge-success': item.registered
                      }">
                    {{ item.registered ? "Registered" : "" }}
                  </span>
                </td>
                <td>{{ item.mobileNumber }}</td>
                <td>
                  <a data-toggle="modal"
                     data-target="#updateStatus"
                     style="cursor: pointer;"
                     (click)="selectedSessionId = item.sessionId; selectedSessionStatus = item.status; currentIndex = i">
                    {{ item.status }}
                  </a>
                </td>
                <td>{{ item.createdAt * 1000 | date: 'yyyy/MM/dd h:mm:ss a' }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <!--  pagination -->
          <div class="d-flex justify-content-between align-items-center">
            <!-- max results per page -->
            <select class="custom-select"
                    style="width: auto"
                    [(ngModel)]="pageSize">
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
            </select>

            <!-- total results -->
            <span class="text-muted">Showing
                            <span class="badge badge-primary">{{ pageSize }}</span> of
                            <span class="badge badge-primary">{{ totalItems }}</span>
                            results</span>

            <!-- pagination -->
            <!-- pagination -->
            <ul class="pagination">
              <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                  *ngIf="firstLastButtons">
                <button class="page-link"
                        (click)="selectPageNumber(1)">
                  ««
                </button>
              </li>
              <!-- previous button -->
              <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                  *ngIf="nextPreviousButtons">
                <button class="page-link"
                        (click)="previous()">«
                </button>
              </li>
              <ng-container *ngFor="let p of totalPages; index as i">
                <li *ngIf="
                        i + 1 >= currentPage - pageSize &&
                        i + 1 <= currentPage + pageSize
                      "
                    [class]="
                        currentPage === i + 1 ? 'page-item active' : 'page-item'
                      ">
                  <button class="page-link"
                          (click)="selectPageNumber(i + 1)">
                    {{ i + 1 }}
                  </button>
                </li>
              </ng-container>
              <!-- next button -->
              <li [class]="
                      currentPage === totalPages.length
                        ? 'page-item disabled'
                        : 'page-item'
                    "
                  *ngIf="nextPreviousButtons">
                <button class="page-link"
                        (click)="next()">»
                </button>
              </li>
              <!-- last button -->
              <li [class]="
                      currentPage === totalPages.length
                        ? 'page-item disabled'
                        : 'page-item'
                    "
                  *ngIf="firstLastButtons">
                <button class="page-link"
                        (click)="selectPageNumber(totalPages.length)">
                  »»
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal"
     id="updateStatus"
     tabindex="-2"
     role="dialog"
     aria-labelledby="updateStatus"
     aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered"
       role="document">
    <div class="modal-content">
      <div class="block block-rounded block-transparent mb-0">
        <div class="block-header block-header-default">
          <h3 class="block-title">Update Status</h3>
          <div class="block-options">
            <button type="button"
                    class="btn-block-option"
                    data-dismiss="modal"
                    aria-label="Close">
              <i class="fa fa-fw fa-times"></i>
            </button>
          </div>
        </div>
        <div class="fs-sm p-2">
          <div class="row">
            <div class="col-lg-12">
              <!-- select control -->
              <select class="custom-select"
                      id="statuses"
                      name="statuses"
                      [(ngModel)]="selectedSessionStatus">
                <option *ngFor="let item of statuses"
                        [ngValue]="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <div class="row my-2 float-right">
            <div class="col-lg-4">
              <button class="btn btn-sm btn-primary"
                      data-dismiss="modal"
                      type="button"
                      (click)="updateSessionStatus()">Update
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
