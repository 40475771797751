import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegionRoutingModule } from './region-routing.module';
import { KycRegionsListComponent } from './kyc-regions-list/kyc-regions-list.component';
import { KycRegionsEditComponent } from './kyc-regions-edit/kyc-regions-edit.component';
import { KycRegionsCreateComponent } from './kyc-regions-create/kyc-regions-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [KycRegionsEditComponent, KycRegionsCreateComponent, KycRegionsListComponent],
  imports: [
    CommonModule,
    RegionRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class RegionModule { }
