import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs';
import { NotifierService } from 'src/app/notifier';

import { Task } from '../../models';
import { SupportCasesService } from '../../services';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrl: './view-page.component.css',
})
export class ViewPageComponent {
  task: Task | null = null;

  form = new FormGroup({
    id: new FormControl(''),
    status: new FormGroup({
      id: new FormControl(''),
      orderIndex: new FormControl(''),
      status: new FormControl(''), //
    }),
    priority: new FormGroup({
      id: new FormControl(''),
      orderIndex: new FormControl(''),
      priority: new FormControl(''),
    }),
    points: new FormControl(''), //
    description: new FormControl(''), //
    creator: new FormGroup({
      id: new FormControl(''),
      email: new FormControl(''),
      username: new FormControl(''), //
    }),
    text_content: new FormControl(''), //
    folder: new FormGroup({
      name: new FormControl(''), //
      id: new FormControl(''),
    }),
  });

  commentCtrl = new FormControl('', [Validators.required]);
  mediaForm = new FormGroup({
    attachment: new FormControl(null, [Validators.required]),
  });

  constructor(
    private supportCasesService: SupportCasesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        map((params) => params?.id),
        switchMap((id) => this.supportCasesService.fetchTask(id)),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.task = res.task;
            this.setFormValue(this.task);
            console.log(this.task);
          }
        },
      });
  }

  onSaveComment(): void {
    if (this.commentCtrl.invalid) {
      throw 'Comment is required!';
    }

    const taskId = this.route.snapshot.params['id'];

    this.supportCasesService
      .addComment(taskId, {
        comment_text: this.commentCtrl.value,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
            return;
          }
        },
      });
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsBinaryString(file);

      reader.onload = () => {
        this.mediaForm.patchValue({
          attachment: file,
        });

        this.cd.markForCheck();
      };
    }
  }

  uploadAttachment(): void {
    if (this.mediaForm.invalid) {
      throw 'Attachment is required!';
    }

    const taskId = this.route.snapshot.params['id'];

    const formData = new FormData();

    formData.append('attachment', this.mediaForm.get('attachment').value);

    this.supportCasesService
      .addAttachment(taskId, formData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
            return;
          }
        },
      });
  }

  private setFormValue(value: any): void {
    this.form.patchValue(value);
  }
}
