import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { OrganizationService } from '../organization.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { OrganizationApiResponse } from './organization-api-response';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  walletOrganizationForm;

  constructor(
    private formBuilder: FormBuilder,
    private organizationService: OrganizationService,
    private authService: AuthService
  ) { 
    this.walletOrganizationForm = this.formBuilder.group({
      name : '',
      email: '',
      phoneNumber: '',
      mobileNumber: '',
      address : this.formBuilder.group({
        city: '',
        country: '',
        postCode: '',
        state: '',
        streetName: '',
        streetNumber: ''
      }),
      compliance: this.formBuilder.group({
        kym: formBuilder.group({
          required: true,
          level:1
        }),
        documents: {}
      })
    });
  }


  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.organizationService.fetch().subscribe((response: WalletApiResponse<OrganizationApiResponse>)=>{
        this.walletOrganizationForm.patchValue({
          name : response.data.walletOrganization.name,
          email: response.data.walletOrganization.email,
          phoneNumber: response.data.walletOrganization.phoneNumber,
          mobileNumber: response.data.walletOrganization.mobileNumber,
          address: response.data.walletOrganization.address
        });
      });
    });

  }

}
