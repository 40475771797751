import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {PlanService} from '../plan.service';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { TransactionAmountKycRuleService } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule.service';
import { TransactionAmountKycRuleEntity } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { TransactionAmountKycRuleCollection } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-collection';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent implements OnInit {

  createWalletPlanForm;
  alert: AlertEntity;
  inProgress: boolean
  kycRules: Array<TransactionAmountKycRuleEntity>

  constructor(
    private formBuilder: FormBuilder,
    private planService: PlanService,
    private transactionAmountKycRuleService: TransactionAmountKycRuleService,
    private authService: AuthService
  ) { 
    this.createWalletPlanForm = this.formBuilder.group({
      name : 'Plan Name',
      currency: 'EUR',
      status: true,
      compliance: this.formBuilder.group({
        kycRules: ''
      })
    });

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.transactionAmountKycRuleService.fetchAll().subscribe((response: WalletApiResponse<TransactionAmountKycRuleCollection>) => {
        this.kycRules = response.data.Rules;
      });
    });
  }

  createWalletPlan(): void{
    this.inProgress = true;
    this.planService.create(
      this.createWalletPlanForm.value
    ).subscribe((response)=> {
      this.inProgress = false;
      this.alert.display = true;
      this.alert.message = 'Successfully created wallet plan ' + this.createWalletPlanForm.value.name

    });
  }

}
