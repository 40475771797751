import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../auth/auth.guard';
import { ListEventsComponent } from './list-events/list-events.component';


const routes: Routes = [
    {
      path: 'events',
      component: ListEventsComponent,
      canActivate: [AuthGuard],
      children: [
      ]
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class EventsRoutingModule { }