import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { TransactionAmountKycRuleService } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { TransactionAmountKycRuleEntity } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-entity';
import { TransactionAmountKycRuleApiResponse } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-api-response';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { KycRegionEntity } from 'src/app/compliance-api/kyc/regions/kyc-region-entity';
import { KycRegionCollection } from 'src/app/compliance-api/kyc/regions/kyc-region-collection';
import { DocumentTypeEntity } from 'src/app/compliance-api/documents/types/document-type-entity';
import { DocumentTypeEntityService } from 'src/app/compliance-api/documents/types/document-type-entity.service';
import { DocumentTypeCollection } from 'src/app/compliance-api/documents/types/document-type-collection';

@Component({
  selector: 'app-edit-kyc-transaction-amount-rule',
  templateUrl: './edit-kyc-transaction-amount-rule.component.html',
  styleUrls: ['./edit-kyc-transaction-amount-rule.component.css']
})
export class EditKycTransactionAmountRuleComponent implements OnInit {

  
  alert: AlertEntity;
  inProgress: boolean;
  ruleForm
  rule: TransactionAmountKycRuleEntity
  regions: Array<KycRegionEntity>
  documentTypes: Array<DocumentTypeEntity>
  
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private transactionAmountKycRuleService: TransactionAmountKycRuleService,
    private route: ActivatedRoute,
    private kycRegionService: KycRegionsService,
    private documentTypeService: DocumentTypeEntityService
  ) {

    this.ruleForm = this.formBuilder.group({
      name : '',
      location : this.formBuilder.group({
        regions: [],
        countries: []
      }),
      transaction: this.formBuilder.group({
        transactionTypes: [],
        minThreshold: 0,
        maxThreshold: 0,
        period: 0
      }),
      actions:  [],
      documents: [],
      status: ''
    });

    this.rule = {
      ruleId: '',
      name: '',
      location: {
        regions: [],
        countries: []
      },
      transaction: {
        transactionTypes: [],
        minThreshold: 0,
        maxThreshold: 0,
        period: 0
      },
      actions:  [],
      documents: [],
      status: ''
    }

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
   }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.transactionAmountKycRuleService.fetch(
        this.route.snapshot.paramMap.get('ruleId')).subscribe((response: WalletApiResponse<TransactionAmountKycRuleApiResponse>) => {
        this.rule = response.data.Rule;
        this.ruleForm.patchValue({
          name : response.data.Rule.name,
          location : response.data.Rule.location,
          transaction: response.data.Rule.transaction,
          actions: response.data.Rule.actions,
          documents: response.data.Rule.documents,
          status: response.data.Rule.status
        });
      });
    });


      this.authService.token().subscribe(() => {
        this.kycRegionService.fetchAll().subscribe((response: WalletApiResponse<KycRegionCollection>) => {
          this.regions = response.data.Regions;
          this.ruleForm.patchValue({
             
          })
        });

        this.documentTypeService.fetchAll().subscribe((response: WalletApiResponse<DocumentTypeCollection>) => {
          this.documentTypes = response.data.DocumentTypes;
        });
      });
  }

  updateRule(){

    console.dir(this.ruleForm.value)
    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.transactionAmountKycRuleService.createRule(
        this.ruleForm.value
      ).subscribe((response)=>{
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully created rule ';
      });
    });
  }


}
