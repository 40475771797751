<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Wallet User Account
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center"
            data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users']">User List</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users', userId]">User</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts']">Accounts</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts', accountId]">Account</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',userId, 'accounts', accountId, 'transactions']">Transactions</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Transaction Details
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

</div>

<div class="content">
    <div class="row" *ngIf="fees">
        <div class="col-xl-6">
            <!-- Striped Table -->
            <div class="block">
                <div class="block-content">
                    <table class="table table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th class="d-none d-sm-table-cell" style="width: 15%;">Amount</th>
                                <th class="text-center" style="width: 100px;">Flat Rate</th>
                                <th class="text-center" style="width: 100px;">Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="font-w600 font-size-sm">
                                    <a href="#">{{ fees.paymentMean.type}}</a>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="">{{ fees.paymentMean.fees}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="">{{ fees.paymentMean.spec.flat_fees}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="">{{ fees.paymentMean.spec.rate}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-w600 font-size-sm">
                                    <a href="#">NBK</a>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="">{{ fees.nbk.fees}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="">{{ fees.nbk.spec.flat_fees}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell">
                                    <span class="">{{ fees.nbk.spec.rate}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- END Striped Table -->
        </div>
    </div>
</div>