import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { TransactionAmountKycRuleCollection } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-collection';
import { TransactionAmountKycRuleEntity } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-entity';
import { TransactionAmountKycRuleService } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-list-kyc-transaction-amount-rule',
  templateUrl: './list-kyc-transaction-amount-rule.component.html',
  styleUrls: ['./list-kyc-transaction-amount-rule.component.css']
})
export class ListKycTransactionAmountRuleComponent implements OnInit {

  rules: Array<TransactionAmountKycRuleEntity>
  loading: boolean;
  constructor(
    private transactionAmountKycRuleService: TransactionAmountKycRuleService,
    private authService: AuthService
  ) { 
    this.loading = true;
  }

  ngOnInit(): void {

    this.authService.token().subscribe(() => {
      this.transactionAmountKycRuleService.fetchAll().subscribe((response: WalletApiResponse<TransactionAmountKycRuleCollection>) => {
        this.rules = response.data.Rules;
        this.loading = false;
      });
    });
  }

}
