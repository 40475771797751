import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { ListPageComponent, MainPageComponent, ViewPageComponent } from './pages';

const routes: Routes = [
  {
    path: 'community-and-service',
    component: MainPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'edit/:id',
        component: ViewPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: '',
        component: ListPageComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommunityAndServiceRoutingModule {}
