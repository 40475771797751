import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, of, switchMap, take, tap } from 'rxjs';
import { NotifierService } from 'src/app/notifier';

import { Sponsorship } from '../../models';
import { SponsorshipService } from '../../services';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrl: './list-page.component.css',
})
export class ListPageComponent {
  loading = false;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  totalPages: any[] = [];
  sponsorships: Sponsorship[] = [];

  form = new FormGroup({
    description: new FormControl('', [Validators.required]),
    prefix: new FormControl('', [Validators.required]),
  });

  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  constructor(
    private sponsorshipService: SponsorshipService,
    private notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.search();
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.search();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  private search(): void {
    this.loading = true;
    this.sponsorships = [];

    this.sponsorshipService
      .fetchAll()
      .pipe(
        finalize(() => (this.loading = false)),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.sponsorships = [...res.data];
          }
        },
        error: (e) => console.log(e),
      });
  }

  onSave(): void {
    if (this.form.invalid) return;

    const { userId, username, lastName } = JSON.parse(
      localStorage.getItem('user')
    );

    const data = {
      ...this.form.getRawValue(),
      author: {
        userId,
        lastName,
        firstName: username,
      },
    };

    this.sponsorshipService
      .addSponsorship(data)
      .pipe(
        tap((res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
          }
        }),
        catchError((e) => of(e)),
        switchMap(() => this.sponsorshipService.fetchAll()),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.sponsorships = [...res.data];
          }
        },
        error: (e) => console.log(e),
      });
  }
}
