import { Injectable } from '@angular/core';
import { ApiService } from  'src/app/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { UserPaymentMeanEntity } from './user-payment-mean-entity';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentMeansService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(
    userId: string
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + 'v1/wallets/users/' + userId + '/payment-means');
  }

  fetch(
    userId: string,
    paymentMeanId: string
    ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/payment-means/' + paymentMeanId);
  }

  create(
    userId: string,
    userPaymentMeanEntity: UserPaymentMeanEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/payment-means',
      userPaymentMeanEntity
    )
  }
}
