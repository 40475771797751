import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { UserDocumentsService } from 'src/app/compliance-api/documents/user-documents.service';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-kyc-regions-create',
  templateUrl: './kyc-regions-create.component.html',
  styleUrls: ['./kyc-regions-create.component.css']
})
export class KycRegionsCreateComponent implements OnInit {

  addNewRegionForm;
  alert: AlertEntity;
  inProgress: boolean;
  loading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private kycRegionService: KycRegionsService,
  ) { 


    this.addNewRegionForm = this.formBuilder.group({
      name: [null, Validators.required]
    });

    this.alert = {
      display: false,
      message: ''
    };
  }

  ngOnInit(): void {
  }

  addNewRegion(){
    this.inProgress = true;

    this.authService.token().subscribe(() => {
      this.kycRegionService.createRule(
        this.addNewRegionForm.value
      ).subscribe((response) => {
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully added region ';
      });
    });

  }

}
