<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Support Cases
            </h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                    </li>
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Support Cases
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<!-- Steps Navigation -->
<div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
    <div class="row">
        <div class="col-12 text-right">
            <button type="button"
                    class="btn btn-primary btn-sm mx-1"
                    data-toggle="modal"
                    data-target="#addTicketModal">
                <i class="far fa-ticket-simple mr-1"></i> Add Ticket
            </button>
        </div>
    </div>
</div>

<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div>
    <div class="col-md-12">
        <div class="block">
            <div class="block-content">
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th style="width: 20%">Description</th>
                                <th style="width: 20%">Created By</th>
                                <th style="width: 10%">Status</th>
                                <th style="width: 20%">Date Created</th>
                                <th style="width: 20%">Last Updated</th>
                                <th style="width: 10%">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!loading">
                            <tr *ngFor="let task of tasks">
                                <td class="font-w600 font-size-sm">
                                    {{ task.description }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.creator.username }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.status.status }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.date_created | date : 'dd-MM-yyyy h:mm:ss a' }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.date_updated | date : 'dd-MM-yyyy h:mm:ss a' }}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button [routerLink]="['/users', userId, 'support-cases', task.id]"
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                data-toggle="tooltip"
                                                title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  pagination -->
            </div>
        </div>
    </div>
</div>


<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="addTicketModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="addTicketModal"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="addTicketModal">Create Ticket</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="modal-body"
                  [formGroup]="form">
                <div class="mb-2">
                    <label class="form-label"
                           for="name">Name</label>
                    <input type="text"
                           class="form-control"
                           id="name"
                           name="name"
                           formControlName="name">
                </div>

                <div class="mb-2">
                    <label class="form-label"
                           for="description">Description</label>
                    <input type="text"
                           class="form-control"
                           id="description"
                           name="description"
                           formControlName="description">
                </div>

                <div class="mb-2">
                    <label class="form-label"
                           for="tag_name">Tag Name</label>
                    <input type="text"
                           class="form-control"
                           id="tag_name"
                           name="tag_name"
                           formControlName="tag_name">
                </div>
            </form>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="onSave()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Save</button>
            </div>
        </div>
    </div>
</div>