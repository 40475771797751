import { Component, OnInit } from '@angular/core';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-wallet-plan',
  templateUrl: './create-wallet-plan.component.html',
  styleUrls: ['./create-wallet-plan.component.css']
})
export class CreateWalletPlanComponent implements OnInit {

  alert: AlertEntity;
  inProgress: boolean;
  
  constructor() {
    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
   }

  ngOnInit(): void {
  }

}
