import { Component, OnInit } from '@angular/core';
import { AccountEntity } from 'src/app/wallet-api/account-entity';
import { OrganizationAccountService } from 'src/app/wallet-api/organization/organization-account.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AccountApiResponse } from 'src/app/user/account/edit-account/account-api-response';
import { OrganizationAccountApiResponse } from 'src/app/wallet-api/organization/account/organization-account-api-response';

@Component({
  selector: 'app-edit-organization-account',
  templateUrl: './edit-organization-account.component.html',
  styleUrls: ['./edit-organization-account.component.css']
})
export class EditOrganizationAccountComponent implements OnInit {

  walletAccountForm;
  account: AccountEntity;

  constructor(
    private accountService: OrganizationAccountService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { 

    this.walletAccountForm = this.formBuilder.group({
      name: '',
      accountType : '',
      balance: '',
      status: ''
    });

    this.account = {
      name: '',
      userId: '',
      accountId: '',
      accountType: '',
      balance: 0,
      walletPlanId: '',
      status: ''

    }
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.accountService.fetch(
        this.route.snapshot.paramMap.get('accountId')
        ).subscribe((response: WalletApiResponse<OrganizationAccountApiResponse>) => {
        this.account = response.data.OrganizationAccount;
        this.walletAccountForm.patchValue({
          accountType : response.data.OrganizationAccount.accountType,
          balance : response.data.OrganizationAccount.balance,
          status: response.data.OrganizationAccount.status,
          name: response.data.OrganizationAccount.name
        });
      });
    });
  }

}
