import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeesRoutingModule } from './fees-routing.module';
import { FeeListComponent } from './fee-list/fee-list.component';
import { FeeCreateComponent } from './fee-create/fee-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeeEditComponent } from './fee-edit/fee-edit.component';


@NgModule({
  declarations: [
    FeeListComponent,
    FeeCreateComponent,
    FeeEditComponent
  ],
  imports: [
    CommonModule,
    FeesRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class FeesModule { }
