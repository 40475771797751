import { Component, inject, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.css',
})
export class MainPageComponent implements OnInit {
  apiService = inject(ApiService);

  ngOnInit(): void {
    const url = environment.wallet.bassServiceBaseUrl;
    this.apiService.get(`${url}/api/healthcheck`).subscribe(console.log);
  }
}
