import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { ListPageComponent, MainPageComponent, ViewPageComponent } from './pages';

const routes: Routes = [
  {
    path: 'support-cases',
    component: MainPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'details/:id',
        component: ViewPageComponent,
      },
      {
        path: 'list',
        component: ListPageComponent,
      },
      {
        path: '',
        redirectTo: '**'
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportCasesRoutingModule {}
