import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPeriod]',
  standalone: true,
})
export class PeriodDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  @HostListener('input')
  onKeydown(e: Event) {
    const value = this.elementRef.nativeElement.value;

    if (!value) {
      this.elementRef.nativeElement.value = '12';

      return;
    }

    if (+value < 1) {
      this.elementRef.nativeElement.value = '12';

      return;
    }

    if (+value > 30) {
      this.elementRef.nativeElement.value = '30';
    }
  }
}
