<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Wallet Events
            </h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                    </li>
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Wallet Events
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <!-- add walletEvent search field  -->
                <div class="block-header">
                    <div class="block-options text-right mb-2">
                        <button class="btn btn-sm"
                                [class.btn-primary]="!isLiveTrailActive"
                                [class.btn-success]="isLiveTrailActive"
                                (click)="toggleLiveWalletEvents()">
                            {{ isLiveTrailActive ? 'Stop' : 'Start' }} Live Trail
                        </button>
                    </div>
                </div>

                <form class="mb-4">
                    <div class="row px-3">
                        <div class="col-md-6">
                            <div class="form-group ml-4">

                                <ng-select [items]="messages$ | async"
                                           bindLabel="name"
                                           placeholder="Description"
                                           [hideSelected]="true"
                                           [minTermLength]="2"
                                           [loading]="messagesLoading"
                                           typeToSearchText="Please enter 2 or more characters"
                                           [typeahead]="messageInput$"
                                           [(ngModel)]="description"
                                           (change)="onSelect()">
                                </ng-select>
                            </div>



                            <div class="form-group ml-4">
                                <input placeholder="To Date"
                                       type="text"
                                       class="js-flatpickr form-control"
                                       id="toTimestamp"
                                       name="toTimestamp"
                                       [(ngModel)]="toTimestamp"
                                       appSearch
                                       (search)="getWalletEvents()"
                                       data-enable-time="true"
                                       data-time_24hr="true">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group ml-4">
                                <input placeholder="From Date"
                                       type="text"
                                       class="js-flatpickr form-control"
                                       id="fromTimestamp"
                                       name="fromTimestamp"
                                       [(ngModel)]="fromTimestamp"
                                       appSearch
                                       (search)="getWalletEvents()"
                                       data-enable-time="true"
                                       data-time_24hr="true">
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group mr-5">
                                <input placeholder="First Name"
                                       type="text"
                                       id="searchByFirstName"
                                       name="firstName"
                                       [(ngModel)]="searchByFirstName"
                                       class="form-control"
                                       appSearch
                                       (search)="getWalletEvents()" />
                            </div>
                            <div class="form-group mr-5">
                                <input placeholder="Alias"
                                       type="text"
                                       id="searchByAlias"
                                       name="searchByAlias"
                                       [(ngModel)]="searchByAlias"
                                       class="form-control"
                                       appSearch
                                       (search)="getWalletEvents()" />
                            </div>
                        </div> -->
                    </div>
                </form>

                <div class="block-content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th style="width: 30%">Description</th>
                                    <th style="width: 30%">Timestamp</th>
                                    <th style="width: 30%">Event Content</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!loading">
                                <tr *ngFor="let walletEvent of walletEvents">
                                    <td class="font-w600 font-size-sm">
                                        {{ walletEvent.description }}
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        {{ walletEvent.timestamp * 1000 | date : 'dd-MM-yyyy h:mm:ss a' }}
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a data-toggle="modal"
                                           data-target="#view-object"
                                           style="cursor: pointer;"
                                           (click)="selectedData = walletEvent">
                                            ...{{ walletEvent.entityId.slice(-4).toUpperCase() }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--  pagination -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- max results per page -->
                        <select class="custom-select"
                                style="width: auto"
                                [(ngModel)]="pageSize">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                        </select>

                        <!-- total results -->
                        <span class="text-muted">Showing
                            <span class="badge badge-primary">{{ pageSize }}</span> of
                            <span class="badge badge-primary">{{ totalItems }}</span>
                            results</span>

                        <!-- pagination -->
                        <!-- pagination -->
                        <ul class="pagination">
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(1)">
                                    ««
                                </button>
                            </li>
                            <!-- previous button -->
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="previous()">«</button>
                            </li>
                            <ng-container *ngFor="let p of totalPages; index as i">
                                <li *ngIf="
                    i + 1 >= currentPage - pageSize &&
                    i + 1 <= currentPage + pageSize
                  "
                                    [class]="
                    currentPage === i + 1 ? 'page-item active' : 'page-item'
                  ">
                                    <button class="page-link"
                                            (click)="selectPageNumber(i + 1)">
                                        {{ i + 1 }}
                                    </button>
                                </li>
                            </ng-container>
                            <!-- next button -->
                            <li [class]="
                  currentPage === totalPages.length
                    ? 'page-item disabled'
                    : 'page-item'
                "
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="next()">»</button>
                            </li>
                            <!-- last button -->
                            <li [class]="
                  currentPage === totalPages.length
                    ? 'page-item disabled'
                    : 'page-item'
                "
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(totalPages.length)">
                                    »»
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal"
     id="view-object"
     tabindex="-2"
     role="dialog"
     aria-labelledby="view-object"
     aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Preview</h3>
                    <div class="block-options">
                        <button type="button"
                                class="btn-block-option"
                                data-dismiss="modal"
                                aria-label="Close">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="fs-sm p-1"
                     style="display: flex; justify-content: center; align-items: center;">
                    <pre><code>{{ selectedData | json }}</code></pre>
                </div>
            </div>
        </div>
    </div>
</div>