import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CashInEntity } from '../cash-in-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class PaypalCashInService {

  
  constructor(
    private apiService: ApiService
  ) {
  }

  cashIn(
    accountId: String,
    cashInEntity: CashInEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.paymentServiceBaseUrl + '/v1/paypal/payments/execute/'+ accountId,
      cashInEntity
    )
  }
}
