<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Wallet Plan Compliance Rule - New Rule
        </h1>
      </div>
    </div>
  </div>

  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/compliance','wallet-plans']">Compliance Rule List</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Create New Rule
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
  <div class="row">
    <div class="col-md-6">
      <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
          </button>
          <p class="mb-0"> {{ alert.message }} </p>
      </div>
      <div class="js-wizard-simple block">
      <!-- Form -->
      <form>
      <!-- Steps Content -->
      <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
          <!-- Step 1 -->

              <h2 class="content-heading border-bottom mb-4 pb-2">General Settings</h2>
              <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
              <div class="form-group">
                  <label for="name">Name</label>
                  <input class="form-control" type="text" id="name" name="name">
              </div>

              <div class="form-group">
                <label for="RuleExpiryDate">Compliance Rule Expiry Date</label>
                <input class="form-control" type="text" id="RuleExpiryDate" name="RuleExpiryDate">
              </div>


              <h2 class="content-heading border-bottom mb-4 pb-2">Account Operations</h2>
              <div>
                  <div class="form-group">
                      <label for="minDeposit">Min Deposit</label>
                      <input class="form-control"  type="text" id="minDeposit" name="minDeposit">
                  </div>
                  <div class="form-group">
                      <label for="maxDeposit">Max Deposit</label>
                      <input class="form-control"  type="text" id="maxDeposit" name="maxDeposit">
                  </div>
                  <div class="form-group">
                    <label for="minWithdraw">Min Withdrawal</label>
                    <input class="form-control"  type="text" id="minWithdraw" name="minWithdraw">
                </div>
                  <div class="form-group">
                      <label for="maxWithdraw">Max Withdrawal</label>
                      <input class="form-control"  type="text" id="postCode" name="maxWithdraw">
                  </div>

                  <div class="form-group">
                    <label for="accountExpiryDate">Account Expiry Date</label>
                    <input class="form-control" type="text" id="accountExpiryDate" name="accountExpiryDate">
                  </div>
              </div>

              <h2 class="content-heading border-bottom mb-4 pb-2">KYC Requirements</h2>
              <div>

              </div>
          </div>
          <!-- END Step 1 -->
      </div>
      <!-- END Steps Content -->

      <!-- Steps Navigation -->
      <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
          <div class="row">
              <div class="col-6 text-right">
                  <button type="submit" class="btn btn-primary" data-wizard="finish">
                      <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                  </button>
              </div>
          </div>
      </div>
      <!-- END Steps Navigation -->
  </form>
  <!-- END Form -->
</div>
</div>
</div>
</div>