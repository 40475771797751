import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { UserPaymentMeanEntity } from 'src/app/wallet-api/payment-means/user-payment-mean-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { UserPaymentMeansService } from 'src/app/wallet-api/payment-means/user-payment-means.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { UserPaymentMeanCollection } from 'src/app/wallet-api/payment-means/user-payment-mean-collection';

@Component({
  selector: 'app-user-payment-means',
  templateUrl: './user-payment-means.component.html',
  styleUrls: ['./user-payment-means.component.css']
})
export class UserPaymentMeansComponent implements OnInit {

  loading: boolean;
  userId: string;
  payments: Array<UserPaymentMeanEntity>;

  constructor(
    private userPaymentMeansService: UserPaymentMeansService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) { 
    this.loading = true;
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.payments = [];
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.userPaymentMeansService.fetchAll(
        this.route.snapshot.paramMap.get('userId')
      ).subscribe((response: WalletApiResponse<UserPaymentMeanCollection>) => {
        this.payments = response.data.userPaymentMeans
        this.loading = false;
      })
    })
  }

}
