import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentTypeEntity } from './document-type-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeEntityService {

  constructor(
    private apiService: ApiService
  ) {
  }


  create(
    entity: DocumentTypeEntity
    ): Observable<any> {
    return this.apiService.post(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/types',
      entity
    )
  }

  fetch(typeId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/types/' + typeId);
  }

  fetchAll(): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/documents/types');
  }
}
