<div class="row items-push px-4">
    <h3 class="flex-sm-fill h3 my-2">Users</h3>
</div>

<div class="row items-push">
    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="Users"
                  [count]="allUsersCount$ | async" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="Active Users"
                  [count]="activeUsersCount$ | async" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="Inactive Users"
                  [count]="inactiveUsersCount$ | async" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="Deleted Users"
                  [count]="deletedUsersCount$ | async" />
    </div>

</div>

<div class="row items-push">
    <div class="col-sm-6 col-md-3 col-xxl-3"
         #col>
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #aup />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #auf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="allUsersPeriods.length"
                   [data]="allUsersPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="aufEl.nativeElement.value" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #activeUP />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #activeUF>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="activeUsersPeriods.length"
                   [data]="activeUsersPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="activeUFEl.nativeElement.value" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #iup />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #iuf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="inactiveUsersPeriods.length"
                   [data]="inactiveUsersPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="iufEl.nativeElement.value" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #dup />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #duf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="deletedUsersPeriods.length"
                   [data]="deletedUsersPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="dufEl.nativeElement.value" />
    </div>

</div>