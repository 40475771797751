import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subscription, switchMap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { AccountTransactionCollection } from 'src/app/wallet-api/account-transaction-collection';
import { AccountTransactionEntity } from 'src/app/wallet-api/account-transaction-entity';
import { AccountTransactionsService } from 'src/app/wallet-api/account-transactions.service';
import { BankTransactionEntity as BankTransactionEntity } from 'src/app/wallet-api/bank-transactions.model';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { NotifierService } from '../../../notifier';

@Component({
  selector: 'app-transactions-account',
  templateUrl: './transactions-account.component.html',
  styleUrls: ['./transactions-account.component.css'],
})
export class TransactionsAccountComponent implements OnInit, OnDestroy {
  transactions: Array<AccountTransactionEntity> = [];
  bankTransactions: Array<BankTransactionEntity> = [];
  loading: boolean;
  userId: string;
  accountId: string;
  pageSize = 10;
  currentPage = 1;
  offset = 0;
  totalItems = 0;
  totalPages: any[] = [];

  descriptionCtrl = new FormControl('');

  subscription = new Subscription();

  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  constructor(
    private accountTransactionService: AccountTransactionsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private notifier: NotifierService
  ) {
    this.loading = true;
    (this.userId = this.route.snapshot.paramMap.get('userId')),
      (this.accountId = this.route.snapshot.paramMap.get('accountId'));
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.accountTransactionService
        .fetchAll(
          this.route.snapshot.paramMap.get('userId'),
          this.route.snapshot.paramMap.get('accountId')
        )
        .subscribe(
          (response: WalletApiResponse<AccountTransactionCollection>) => {
            if (response.status === 'success') {
              this.transactions = response.data[0].walletAccountTransactions;
            } else {
              //   this.errorHandlerService.handleGeneralError(
              //     response.StatusDescription
              //   );
              this.notifier.error(response.StatusDescription);
            }
            this.loading = false;
          }
        );
    });

    this.getBankTransactions();

    this.onSearch();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getBankTransactions(): void {
    const data = {
      limit: this.pageSize,
      offset: this.offset,
    };

    this.authService
      .token()
      .pipe(
        switchMap(() =>
          this.accountTransactionService.fetchBankTransactions(
            this.route.snapshot.paramMap.get('userId'),
            data
          )
        )
      )
      .subscribe({
        next: (response) => {
          if (response.status !== 'success') {
            return this.notifier.error(response.StatusDescription);
          }

          this.totalItems = response.metadata.totalCount;
        //   this.pageSize = response.metadata.count;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );

          this.bankTransactions = Array.isArray(response.data)
            ? [...response.data]
            : [];
        },
        error: (e: HttpErrorResponse) => {
          this.notifier.httpError(e);
        },
      });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;

    this.offset = (this.currentPage - 1) * this.pageSize;

    this.getBankTransactions();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;

    this.offset = (previousPage - 1) * this.pageSize;

    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;

    this.offset = (nextPage - 1) * this.pageSize;

    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  onSearch(): void {
    const data = {
      limit: this.pageSize,
      offset: this.offset,
      description: this.descriptionCtrl.value,
    };

    this.descriptionCtrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250),
        switchMap(() => this.authService.token()),
        switchMap(() =>
          this.accountTransactionService.fetchBankTransactions(
            this.route.snapshot.paramMap.get('userId'),
            data
          )
        )
      )
      .subscribe({
        next: (response) => {
          if (response.status !== 'success') {
            return this.notifier.error(response.StatusDescription);
          }

          this.totalItems = response.metadata.totalCount;
        //   this.pageSize = response.metadata.count;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );

          this.bankTransactions = Array.isArray(response.data)
            ? [...response.data]
            : [];
        },
        error: (e: HttpErrorResponse) => {
          this.notifier.httpError(e);
        },
      });
  }
}
