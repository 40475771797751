import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ListChatsComponent } from './list-chats/list-chats.component';
import { ChatsRoutingModule } from './chats-routing.module';
import {ChatWindowComponent} from "./chat-window/chat-window.component";


@NgModule({
  declarations: [
    ListChatsComponent,
    ChatWindowComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ChatsRoutingModule
  ]
})
export class ChatsModule { }
