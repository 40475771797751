import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FeeEntity } from './fee-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class FeeService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/fees');
  }

  fetch(
    feeId: string
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/fees/' + feeId);
  }

  create(
    entity: FeeEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/fees',
      entity
    )
  }

  update(
    feeId: string,
    entity: FeeEntity
  ): Observable<any> {
    return this.apiService.patch(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/fees/' + feeId,
      entity
    )
  }

}
