import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { DocumentTypeCollection } from 'src/app/compliance-api/documents/types/document-type-collection';
import { DocumentTypeEntity } from 'src/app/compliance-api/documents/types/document-type-entity';
import { DocumentTypeEntityService } from 'src/app/compliance-api/documents/types/document-type-entity.service';
import { KycRegionCollection } from 'src/app/compliance-api/kyc/regions/kyc-region-collection';
import { KycRegionEntity } from 'src/app/compliance-api/kyc/regions/kyc-region-entity';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { TransactionAmountKycRuleService } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-create-kyc-transaction-amount-rule',
  templateUrl: './create-kyc-transaction-amount-rule.component.html',
  styleUrls: ['./create-kyc-transaction-amount-rule.component.css']
})
export class CreateKycTransactionAmountRuleComponent implements OnInit {

  alert: AlertEntity;
  inProgress: boolean;
  ruleForm;
  regions: Array<KycRegionEntity>
  documentTypes: Array<DocumentTypeEntity>
  loadingRegions: boolean
  loadingDocumentTypes: boolean
  
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private transactionAmountKycRuleService: TransactionAmountKycRuleService,
    private kycRegionService: KycRegionsService,
    private documentTypeService: DocumentTypeEntityService
  ) {

    this.ruleForm = this.formBuilder.group({
      name : 'Please provide a name',
      location : this.formBuilder.group({
        regions: [],
        countries: []
      }),
      transaction: this.formBuilder.group({
        transactionTypes: [],
        minThreshold: 0,
        maxThreshold: 0,
        period: 0
      }),
      actions:  [],
      documents: [],
      status: 'active'
    });

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
    
   }

  ngOnInit(): void {
    this.loadingRegions = true;
    this.loadingDocumentTypes = true;
    this.authService.token().subscribe(() => {
      this.kycRegionService.fetchAll().subscribe((response: WalletApiResponse<KycRegionCollection>) => {
        this.regions = response.data.Regions;
        this.loadingRegions = false
      });

      this.documentTypeService.fetchAll().subscribe((response: WalletApiResponse<DocumentTypeCollection>) => {
        this.documentTypes = response.data.DocumentTypes;
        this.loadingDocumentTypes = false
      });
    });
  }

  createRule(){

    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.transactionAmountKycRuleService.createRule(
        this.ruleForm.value
      ).subscribe((response)=>{
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully created rule ';
      });
    });
  }

  refresh(){
    this.ngOnInit()
  }

}
