import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { CollectionListComponent } from './collection-list/collection-list.component';
import { DistributionListComponent } from './distribution-list/distribution-list.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { ProjectListComponent } from './project-list/project-list.component';

const routes: Routes = [
  {
    path: 'tontines/edit/:projectId',
    component: ProjectEditComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'tontines/list',
    component: ProjectListComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'collections/list',
    component: CollectionListComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'distributions/list',
    component: DistributionListComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TontineRoutingModule { }
