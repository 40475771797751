import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from '../auth/auth.guard';
import { ListChatsComponent } from './list-chats/list-chats.component';
import {ChatWindowComponent} from "./chat-window/chat-window.component";


const routes: Routes = [
    {
      path: 'chats/window/:channelId/:userId',
      component: ChatWindowComponent,
      canActivate: [AuthGuard],
      children: [
      ]
    },
    {
      path: 'chats/list',
      component: ListChatsComponent,
      canActivate: [AuthGuard],
      children: [
      ]
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class ChatsRoutingModule { }