import { Component, OnInit } from '@angular/core';
import { AccountTransactionEntity } from 'src/app/wallet-api/account-transaction-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AccountTransactionCollection } from 'src/app/wallet-api/account-transaction-collection';
import { OrganizationAccountTransactionService } from 'src/app/wallet-api/organization/account/organization-account-transaction.service';

@Component({
  selector: 'app-list-organization-account-transactions',
  templateUrl: './list-organization-account-transactions.component.html',
  styleUrls: ['./list-organization-account-transactions.component.css']
})
export class ListOrganizationAccountTransactionsComponent implements OnInit {

  transactions: Array<AccountTransactionEntity> = [];
  loading: boolean
  userId: string;
  accountId: string;


  constructor(
    private accountTransactionService: OrganizationAccountTransactionService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { 
    this.loading = true;
    this.accountId =  this.route.snapshot.paramMap.get('accountId')
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.accountTransactionService.fetchAll(
        this.route.snapshot.paramMap.get('accountId')
        ).subscribe((response: WalletApiResponse<AccountTransactionCollection>) => {
        this.transactions = response.data.walletAccountTransactions
        this.loading = false;
      })
    })
  }

}
