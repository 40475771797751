import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { KycRegionEntity } from './kyc-region-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class KycRegionsService {

  constructor(
    private apiService: ApiService,
  ) {
  }


  createRule(
    entity: KycRegionEntity
    ): Observable<any> {
    return this.apiService.post(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/regions',
      entity
    )
  }

  fetch(regionId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/regions/' + regionId);
  }

  fetchAll(): Observable<any> {
    return this.apiService.get(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/regions');
  }
}
