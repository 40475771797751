import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { NotifierService } from 'src/app/notifier';

import { Distribution } from '../project/distribution.model';
import { DistributionService } from '../project/distribution.service';
import { DistributionsApiResponse } from '../project/distributions-api-response.model';

@Component({
  selector: 'app-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrl: './distribution-list.component.css',
})
export class DistributionListComponent implements OnInit {
  loading = false;
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages: any[] = [];
  firstLastButtons = false;
  nextPreviousButtons = true;
  start_date = '';
  end_date = '';
  tontineId = '';
  status = '';
  scacode = '';

  distributions: Distribution[] = [];
  distribution: any;
  selectedDistributionId = '';

  constructor(
    private authService: AuthService,
    private distributionsService: DistributionService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.loading = true;
    if (window.One != undefined) {
      window.One.loader('show');
    }
  }

  ngOnInit(): void {
    this.loading = true;

    if (window.One != undefined) {
      window.One.loader('show');
    }

    this.authService.token().subscribe(() => {
      this.onSearch();
      if (window.One != undefined) {
        window.One.loader('hide');
      }
    });
  }

  getDistribution(distribution_id: string): void {
    this.distributionsService
      .fetchBy(distribution_id)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.distribution = res.distribution;
        },
      });
  }

  onSearch(event?: Event, key?: string) {
    this.loading = true;
    // let parameters = { page: this.currentPage, limit: this.pageSize };
    let parameters = {};

    if (this.tontineId) {
      parameters['project_id'] = this.tontineId;
    }

    if (this.status) {
      parameters['statuses'] = [this.status].join();
    }

    if (this.start_date) {
      const dateTime = this.start_date.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      parameters['start_date'] = (date.getTime() / 1000).toString();
    }

    if (this.end_date) {
      const dateTime = this.end_date.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      parameters['end_date'] = (date.getTime() / 1000).toString();
    }

    this.distributions = [];
    let queryParams = new HttpParams({ fromObject: parameters });

    this.distributionsService
      .fetch(queryParams)
      .subscribe((response: DistributionsApiResponse) => {
        if (response.status !== 'success') {
          this.loading = false;
          return;
        }
        this.distributions = [...response.distributions];
        this.currentPage = response.page;
        this.totalItems = response.total;
        this.pageSize = response.limit;
        this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
        this.loading = false;
      });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.onSearch();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  validatePayment(): void {
    const data = {
      scaCode: this.scacode,
    };

    this.distributionsService
      .validatePayment(this.selectedDistributionId, data)
      .subscribe({
        next: (res) => {
          if (res.status !== 'success') {
            this.notifier.error(res.statusDescription);
            return;
          }

          this.notifier.success(res.status);
        },
        error: console.error,
        complete: () => {
          this.scacode = '';
        },
      });
  }
}
