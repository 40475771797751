<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Support Cases
            </h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                    </li>
                    <li class="breadcrumb-item"
                        aria-current="page">
                        <a [routerLink]="['/users', userId, 'support-cases']">Support Cases</a>
                    </li>
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Support Case Details
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<!-- Steps Navigation -->
<div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
    <div class="row">
        <div class="col-12 text-right">
            <button type="button"
                    class="btn btn-primary btn-sm mx-1"
                    data-toggle="modal"
                    data-target="#addCommentModal">
                <i class="far fa-comment mr-1"></i> Add Comment
            </button>

            <button ype="button"
                    class="btn btn-primary btn-sm mx-1"
                    data-toggle="modal"
                    data-target="#addMediaModal">
                <i class="fa fa-upload mr-1"></i> Upload
            </button>

            <button ype="button"
                    class="btn btn-primary btn-sm mx-1"
                    data-toggle="modal"
                    data-target="#updateStatusModal">
                <i class="fa fa-circle mr-1"></i> Update
            </button>

            <!-- <button type="button"
                    class="btn btn-warning btn-sm mx-1"
                    data-toggle="modal"
                    data-target="#updateBankCustomerDetailsModal">
                <i class="fa fa-rocket"
                   aria-hidden="true"></i> Send To MoneyTrans
            </button>

            <button type="button"
                    class="btn btn-warning btn-sm mx-1"
                    data-toggle="modal"
                    data-target="#emailMTContractModal">
                <i class="fa fa-envelope"
                   aria-hidden="true"></i> Email MoneyTrans Contract
            </button>

            <button type="button"
                    class="btn btn-warning btn-sm ml-1 mr-4"
                    data-toggle="modal"
                    data-target="#sendEmail">
                <i class="fa fa-envelope"
                   aria-hidden="true"></i> Send Email
            </button> -->
        </div>
    </div>
</div>

<div class="block-content block-content-full tab-content px-md-5"
     style="min-height: 300px">
    <!-- Step 1 -->
    <div class="tab-pane active"
         id="wizard-simple-step1"
         role="tabpanel">
        <div class="row">
            <form class="col-md-6"
                  [formGroup]="form">
                <h2 class="content-heading border-bottom mb-4 pb-2">
                    {{ task?.description }}
                </h2>
                <div class="form-group">
                    <label for="description">Description</label>
                    <input class="form-control"
                           readonly
                           type="text"
                           id="description"
                           name="description"
                           formControlName="description" />
                </div>
                <div class="form-group">
                    <label for="text_content">Text Content</label>
                    <input class="form-control"
                           readonly
                           type="text"
                           id="text_content"
                           name="text_content"
                           formControlName="text_content" />
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"
                             formGroupName="creator">
                            <label for="username">Creator</label>
                            <input class="form-control"
                                   readonly
                                   type="text"
                                   id="username"
                                   name="username"
                                   formControlName="username" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"
                             formGroupName="status">
                            <label for="status">Status</label>
                            <input class="form-control"
                                   readonly
                                   type="text"
                                   id="status"
                                   name="status"
                                   formControlName="status" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6"
                         formGroupName="folder">
                        <div class="form-group">
                            <label for="name">Folder Name</label>
                            <input class="form-control"
                                   readonly
                                   type="text"
                                   id="name"
                                   name="name"
                                   formControlName="name" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group"
                             formGroupName="priority">
                            <label for="priority">Priority</label>
                            <input class="form-control"
                                   readonly
                                   type="text"
                                   id="priority"
                                   name="priority"
                                   formControlName="priority" />
                        </div>
                    </div>

                </div>
                <div class="form-group">
                    <label for="points">Sprint Points</label>
                    <input class="form-control"
                           readonly
                           type="text"
                           id="points"
                           name="points"
                           formControlName="points" />
                </div>
            </form>
        </div>
    </div>
    <!-- END Step 1 -->
</div>

<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="addCommentModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="addCommentModal"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="addCommentModal">Create Ticket</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mb-4">
                    <label class="form-label"
                           for="comment-textarea-input">Comment</label>
                    <textarea class="form-control"
                              id="comment-textarea-input"
                              name="comment-textarea-input"
                              rows="4"
                              placeholder="Comment..."
                              [formControl]="commentCtrl"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="onSaveComment()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Save</button>
            </div>
        </div>
    </div>
</div>

<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="addMediaModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="addMediaModal"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="addMediaModal">Upload Images/Videos</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>Choose file</label>
                    <div class="custom-control custom-switch mb-1">
                        <input type="file"
                               multiple
                               accept=".jpg,.jpeg,.png,.mp4,.avi,.mov,.mkv,3gp"
                               (change)="onFileChange($event)" />
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="uploadAttachment()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Save</button>
            </div>
        </div>
    </div>
</div>

<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="updateStatusModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="updateStatusModal"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="updateStatusModal">Update</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="modal-body"
                  [formGroup]="updateForm">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input class="form-control"
                           type="text"
                           id="name"
                           name="name"
                           formControlName="name" />
                </div>
                <div class="form-group">
                    <label for="description">Description</label>
                    <input class="form-control"
                           type="text"
                           id="description"
                           name="description"
                           formControlName="description" />
                </div>
                <div class="form-group">
                    <label for="status">Status</label>
                    <select class="form-control"
                            id="status"
                            name="status"
                            formControlName="status">
                        <option value="Todo">TODO</option>
                        <option value="Open">OPEN</option>
                        <option value="In Progress">IN PROGRESS</option>
                        <option value="Complete">COMPLETE</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="priority">Priority</label>
                    <select class="form-control"
                            id="documentStatus"
                            name="priority"
                            formControlName="priority">
                        <option value="Normal">NORMAL</option>
                        <option value="High">HIGH</option>
                        <option value="Urgent">URGENT</option>
                        <option value="Low">LOW</option>
                    </select>
                </div>
            </form>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="onUpdate()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Save</button>
            </div>
        </div>
    </div>
</div>