import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '../directives/directives.module';
import { CollectionListComponent } from './collection-list/collection-list.component';
import { DistributionListComponent } from './distribution-list/distribution-list.component';
import { ProjectEditComponent } from './project-edit/project-edit.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { TontineRoutingModule } from './tontine-routing.module';



@NgModule({
  declarations: [
    ProjectEditComponent,
    ProjectListComponent,
    CollectionListComponent,
    DistributionListComponent
  ],
  imports: [
    CommonModule,
    TontineRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    DirectivesModule
  ]
})
export class TontineModule { }
