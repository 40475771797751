import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { KycRegionCollection } from 'src/app/compliance-api/kyc/regions/kyc-region-collection';
import { KycRegionEntity } from 'src/app/compliance-api/kyc/regions/kyc-region-entity';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { FeeEntity } from 'src/app/wallet-api/fees/fee-entity';
import { FeeService } from 'src/app/wallet-api/fees/fee.service';
import { FeeApiResponse } from 'src/app/wallet-api/fees/fee-api-response';
import { ActivatedRoute } from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';



@Component({
  selector: 'app-fee-edit',
  templateUrl: './fee-edit.component.html',
  styleUrls: ['./fee-edit.component.css']
})
export class FeeEditComponent implements OnInit {

  updateFeeForm;
  alert: AlertEntity;
  inProgress: boolean;
  loading: boolean;
  fee: FeeEntity;
  loadingRegions: boolean;
  regions: Array<KycRegionEntity>

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private feeService: FeeService,
    private kycRegionService: KycRegionsService,
    private route: ActivatedRoute,

  ) {


    this.updateFeeForm = this.formBuilder.group({
      paymentMean: '',
      regions: [],
      fees: this.formBuilder.group({
        rate: '',
        flatRate: ''
      })
    });

    this.fee = {
      feeId: '',
      paymentMean: '',
      regions: [],
      fees: {
        rate: '',
        flatRate: ''
      },
      extras: []
    }

    this.alert = {
      display: false,
      message: ''
    };


    this.inProgress = false;
  }

  ngOnInit(): void {
    this.loadingRegions = true;
    this.authService.token().subscribe(() => {
      this.kycRegionService.fetchAll().subscribe((response: WalletApiResponse<KycRegionCollection>) => {
        this.regions = response.data.Regions;
        this.loadingRegions = false;
      });
    });

    this.authService.token().subscribe(() => {
      this.feeService.fetch(
        this.route.snapshot.paramMap.get('feeId')).subscribe((response: WalletApiResponse<FeeApiResponse>) => {
          this.fee = response.data.walletFee;
          this.updateFeeForm.patchValue({
            paymentMean: response.data.walletFee.paymentMean,
            regions: response.data.walletFee.regions,
            fees: response.data.walletFee.fees,
          });
        });
    });
  }

  updateFee() {
    this.inProgress = true;
    this.authService.token().subscribe(() => {
      this.feeService.update(
        this.route.snapshot.paramMap.get('feeId'),
        this.updateFeeForm.value
      ).subscribe((response) => {
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully updated fee ';
      });
    });

  }
  refresh() {
    this.ngOnInit()
  }

}
