import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { CashOutEntity } from '../cash-out-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class CashOutService {

  constructor(
    private apiService: ApiService
  ) {
  }


  cashOut(
    accountId: String,
    cashOutEntity: CashOutEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.paymentServiceBaseUrl + '/v1/mtn/payments/accounts/'+ accountId +'/cash-out',
      cashOutEntity
    )
  }
}
