import { Component } from '@angular/core';
import { take } from 'rxjs';

import { Task } from '../../models';
import { SupportCasesService } from '../../services';

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrl: './list-page.component.css',
})
export class ListPageComponent {
  loading = false;
  tasks: Task[] = [];

  constructor(private supportCasesService: SupportCasesService) {}

  ngOnInit(): void {
    this.loading = true;
    this.supportCasesService
      .fetchList()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.tasks = res.tasks.tasks;
          }
        },
        complete: () => (this.loading = false),
      });
  }
}
