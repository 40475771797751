<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Bank Payloads</h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                    </li>
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Bank Payloads
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title"></h3>
                    <div class="block-options">
                        <div class="form-group">
                            <ng-select [items]="messages$ | async"
                                       bindLabel="name"
                                       placeholder="Description"
                                       [hideSelected]="true"
                                       [minTermLength]="2"
                                       [loading]="messagesLoading"
                                       typeToSearchText="Please enter 2 or more characters"
                                       [typeahead]="messageInput$"
                                       [(ngModel)]="description"
                                       (change)="onSelect()"
                                       style="width: 256px;">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="block-content">
                    <p class="font-size-sm text-muted"></p>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Path</th>
                                    <th>Timestamp</th>
                                    <th style="width: 15%">Request Payload</th>
                                    <th style="width: 15%">Response Payload</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let bank_event of bank_events">
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ bank_event.context.action }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ bank_event.Path }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{
                                            bank_event.Timestamp * 1000
                                            | date : "MMM dd, yyyy 'at' hh:mm a"
                                            }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <a data-toggle="modal"
                                           data-target="#view-object"
                                           style="cursor: pointer;"
                                           (click)="showData('Request Payload', bank_event.payload)">
                                            ...
                                        </a>
                                    </td>
                                    <td class="font-size-sm">
                                        <a data-toggle="modal"
                                           data-target="#view-object"
                                           style="cursor: pointer;"
                                           (click)="showData('Response Payload', bank_event.ResponsePayload)">
                                            ...
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal"
     id="view-object"
     tabindex="-2"
     role="dialog"
     aria-labelledby="view-object"
     aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
                <div class="block-header block-header-default">
                    <h3 class="block-title">{{ selectedData?.title }}</h3>
                    <div class="block-options">
                        <button type="button"
                                class="btn-block-option"
                                data-dismiss="modal"
                                aria-label="Close">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="fs-sm p-1"
                     style="display: flex; justify-content: center; align-items: center;">
                    <pre><code>{{ selectedData?.data | json }}</code></pre>

                </div>
            </div>
        </div>
    </div>
</div>