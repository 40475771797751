<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Community Service Request</h1>
        </div>
    </div>
</div>

<div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button"
                class="btn btn-block btn-light d-flex justify-content-between align-items-center"
                data-toggle="class-toggle"
                data-target="#horizontal-navigation-hover-centered"
                data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->

    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/community-and-service']">Community Service Request List</a>
                        </li>
                        <li class="breadcrumb-item active"
                            aria-current="page">
                            Request
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content"
     *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <div class="js-wizard-simple block">
                <!-- add loading here -->

                <!-- Form -->
                <form [formGroup]="form">
                    <div class="block">
                        <div class="block-header">
                            <h3 class="block-title"></h3>
                            <div class="block-options">
                                <button class="btn btn-sm btn-success mx-1"
                                        type="button"
                                        (click)="onAccept()">
                                    <i class="nav-main-link-icon fa fa-check"></i>
                                    <span class="ml-1">Accept</span>
                                </button>

                                <button class="btn btn-sm btn-primary mx-1"
                                        type="button"
                                        (click)="onBlock()">
                                    <i class="nav-main-link-icon fa fa-check"></i>
                                    <span class="ml-1">Block</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-md-5 border-bottom"></div>

                    <!-- Steps Content -->
                    <div class="block-content block-content-full tab-content px-md-5"
                         style="min-height: 300px">
                        <!-- Step 1 -->
                        <div class="tab-pane active"
                             id="wizard-simple-step1"
                             role="tabpanel">
                            <div class="row">
                                <div class="col-md-6">
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Owner
                                    </h2>
                                    <ng-container formGroupName="owner">
                                        <div class="form-group">
                                            <label for="firstName">Firstname</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="firstName"
                                                   name="firstName"
                                                   formControlName="first_name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="lastName">Lastname</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="lastName"
                                                   name="lastName"
                                                   formControlName="last_name" />
                                        </div>
                                    </ng-container>
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Contacts
                                    </h2>
                                    <div class="form-group">
                                        <label for="mobileNumber">Mobile Number</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="mobileNumber"
                                               name="mobileNumber"
                                               formControlName="mobileNumber" />
                                    </div>
                                    <div class="form-group">
                                        <label for="phoneNumber">Phone Number</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="phoneNumber"
                                               name="phoneNumber"
                                               formControlName="phoneNumber" />
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="email"
                                               name="email"
                                               formControlName="email" />
                                    </div>
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Address
                                    </h2>
                                    <div formGroupName="address">
                                        <div class="form-group">
                                            <label for="streetName">Street Name</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="streetName"
                                                   name="streetName"
                                                   formControlName="street_name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="streetNumber">Street Number</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="streetNumber"
                                                   name="streetNumber"
                                                   formControlName="street_number" />
                                        </div>
                                        <div class="form-group">
                                            <label for="postCode">Post Code</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="postCode"
                                                   name="postCode"
                                                   formControlName="post_code" />
                                        </div>
                                        <div class="form-group">
                                            <label for="state">State</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="state"
                                                   name="state"
                                                   formControlName="state" />
                                        </div>
                                        <div class="form-group">
                                            <label for="state">Country</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="country"
                                                   name="country"
                                                   formControlName="country" />
                                        </div>
                                        <div class="form-group">
                                            <label for="state">Country Code</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="countryCode"
                                                   name="countryCode"
                                                   formControlName="countryCode" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Translate Type
                                    </h2>
                                    <ng-container formGroupName="translate_type">
                                        <div class="form-group">
                                            <label for="FR">FR</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="FR"
                                                   name="FR"
                                                   formControlName="FR" />
                                        </div>

                                        <div class="form-group">
                                            <label for="EN">EN</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="EN"
                                                   name="EN"
                                                   formControlName="EN" />
                                        </div>
                                    </ng-container>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Translate Type of Membership
                                    </h2>
                                    <ng-container formGroupName="translate_type_of_membership">
                                        <div class="form-group">
                                            <label for="FR">FR</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="FR"
                                                   name="FR"
                                                   formControlName="FR" />
                                        </div>

                                        <div class="form-group">
                                            <label for="EN">EN</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="EN"
                                                   name="EN"
                                                   formControlName="EN" />
                                        </div>
                                    </ng-container>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        General Information
                                    </h2>
                                    <div class="form-group">
                                        <label for="community_name">Community Name</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="community_name"
                                               name="community_name"
                                               formControlName="community_name" />
                                    </div>

                                    <div class="form-group">
                                        <label for="type">Type</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="type"
                                               name="type"
                                               formControlName="type" />
                                    </div>

                                    <div class="form-group">
                                        <label for="description">Description</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="description"
                                               name="description"
                                               formControlName="description" />
                                    </div>

                                    <div class="form-group">
                                        <label for="status">Status</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="status"
                                               name="status"
                                               formControlName="status" />
                                    </div>

                                    <div class="form-group">
                                        <label for="unique_id">Unique ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="unique_id"
                                               name="unique_id"
                                               formControlName="unique_id" />
                                    </div>


                                    <div class="form-group">
                                        <label for="type_of_membership">Type of Membership</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="type_of_membership"
                                               name="type_of_membership"
                                               formControlName="type_of_membership" />
                                    </div>

                                    <div class="form-group">
                                        <label for="created_at">Created At</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="created_at"
                                               name="created_at"
                                               formControlName="created_at" />
                                    </div>

                                    <div class="form-group">
                                        <label for="updated_at">Updated At</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="updated_at"
                                               name="updated_at"
                                               formControlName="updated_at" />
                                    </div>

                                    <div class="form-group">
                                        <label for="community_id">Community ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="community_id"
                                               name="community_id"
                                               formControlName="community_id" />
                                    </div>

                                    <div class="form-group">
                                        <label for="organization_id">Organization ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="organization_id"
                                               name="organization_id"
                                               formControlName="organization_id" />
                                    </div>

                                    <div class="form-group">
                                        <label for="members_count">Members Count</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="members_count"
                                               name="members_count"
                                               formControlName="members_count" />
                                    </div>

                                    <div class="form-group">
                                        <label for="join_status">Join Status</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="join_status"
                                               name="join_status"
                                               formControlName="join_status" />
                                    </div>

                                    <div class="form-group">
                                        <label for="profile_picture">Profile Picture</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="profile_picture"
                                               name="profile_picture"
                                               formControlName="profile_picture" />
                                    </div>

                                    <div class="form-group">
                                        <label for="approval_code">Approval Code</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="approval_code"
                                               name="approval_code"
                                               formControlName="approval_code" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!-- END Step 1 -->
                    </div>
                    <!-- END Steps Content -->
                </form>
                <!-- END Form -->
            </div>
        </div>
    </div>
</div>