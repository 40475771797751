<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Dashboard</h1>
        </div>
    </div>
</div>

<div class="content">
    <app-users />

    <app-transactions />

    <div class="row items-push px-4">
        <h3 class="flex-sm-fill h3 my-2">Pending</h3>
    </div>

    <div class="row items-push">

        <div class="col-sm-6 col-md-3 col-xxl-3">
            <app-card title="Pending Subscriptions"
                      [count]="subscriptionsCount()" />
        </div>

        <div class="col-sm-6 col-md-3 col-xxl-3">
            <app-card title="Pending Signatures"
                      [count]="signaturesCount()" />
        </div>

        <div class="col-sm-6 col-md-3 col-xxl-3">
            <app-card title="Pending KYC"
                      [count]="kycCount()" />
        </div>

    </div>
</div>