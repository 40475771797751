import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../../app/api.service';
import { environment } from '../../../environments/environment';

type Count = {
  status: string;
  data: {
    count: number;
  };
};

type TransactionVolume = {
  status: string;
  data: {
    transaction_volume: number;
  };
};

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  fetchPendingSubscriptionsCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/onboarding/subscriptions/pending/count`
    );
  }

  fetchPendingSignaturesCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/onboarding/signature/pending/count`
    );
  }

  fetchPendingKYCCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/onboarding/kyc/pending/count`
    );
  }

  fetchTransactionVolumeCount(): Observable<TransactionVolume> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/transactions/volume`
    );
  }

  fetchNewUsersCount(): Observable<Count> {
    return this.apiService.get(
      `${environment.wallet.analyticsServiceBaseUrl}/v1/users/count`
    );
  }
}
