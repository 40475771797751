import { NumberFormatStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { KycRegionCollection } from 'src/app/compliance-api/kyc/regions/kyc-region-collection';
import { KycRegionEntity } from 'src/app/compliance-api/kyc/regions/kyc-region-entity';
import { KycRegionsService } from 'src/app/compliance-api/kyc/regions/kyc-regions.service';
import { FeeService } from 'src/app/wallet-api/fees/fee.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-fee-create',
  templateUrl: './fee-create.component.html',
  styleUrls: ['./fee-create.component.css']
})
export class FeeCreateComponent implements OnInit {

  addNewFeeForm;
  alert: AlertEntity;
  inProgress: boolean;
  loading: boolean;
  loadingRegions: boolean;
  regions: Array<KycRegionEntity>

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private feeService: FeeService,
    private kycRegionService: KycRegionsService
  ) {


    this.addNewFeeForm = this.formBuilder.group({
      paymentMean: [null, Validators.required],
      regions: [[], Validators.required],
      fees: this.formBuilder.group({
        rate: [null, Validators.required],
        flatRate: [null, Validators.required]
      })
    });

    this.alert = {
      display: false,
      message: ''
    };
  }

  ngOnInit(): void {
    this.loadingRegions = true;
    this.authService.token().subscribe(() => {
      this.kycRegionService.fetchAll().subscribe((response: WalletApiResponse<KycRegionCollection>) => {
        this.regions = response.data.Regions;
        this.loadingRegions = false;
      });
    });
  }

  addNewFee() {
    this.inProgress = true;
    this.authService.token().subscribe(() => {
      this.feeService.create(
        this.addNewFeeForm.value
      ).subscribe((response) => {
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully added fee ';
      });
    });

  }

  refresh() {
    this.ngOnInit()
  }

}
