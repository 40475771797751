import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { DocumentTypeEntityService } from 'src/app/compliance-api/documents/types/document-type-entity.service';

@Component({
  selector: 'app-document-type-create',
  templateUrl: './document-type-create.component.html',
  styleUrls: ['./document-type-create.component.css']
})
export class DocumentTypeCreateComponent implements OnInit {

  addDocumentTypeForm;
  alert: AlertEntity;
  inProgress: boolean;
  loading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private documentTypeService: DocumentTypeEntityService
  ) { 


    this.addDocumentTypeForm = this.formBuilder.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
      createdAt: [null, Validators.required]
    });

    this.alert = {
      display: false,
      message: ''
    };
  }

  ngOnInit(): void {
  }

  addNewType(){
    this.inProgress = true;
    this.authService.token().subscribe(() => {
      this.documentTypeService.create(
        this.addDocumentTypeForm.value
      ).subscribe((response) => {
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully added document type';
      });
    });
  }
}
