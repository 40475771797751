import { Component, Input, OnInit } from '@angular/core';
import { finalize, map } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';

import { Daum } from '../models';
import { UserService } from '../user.service';

@Component({
  selector: 'app-pending-validation-list',
  templateUrl: './pending-validation-list.component.html',
  styleUrl: './pending-validation-list.component.css',
})
export class PendingValidationListComponent implements OnInit {
  users: Array<Daum> = [];
  loading: boolean;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  searchByEmail: string = '';
  searchByMobile: string;
  searchByFirstName: string;
  searchByLastName: string;
  searchByAlias: string;
  totalPages: any[] = [];

  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loading = true;
    if (window.One != undefined) {
      window.One.loader('show');
    }
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.getKYCPendingList();
      if (window.One != undefined) {
        window.One.loader('hide');
      }
    });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getKYCPendingList();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  search() {
    this.loading = true;
    this.users = [];
    let parameters = { page: this.currentPage, limit: this.pageSize };

    if (this.searchByMobile) {
      parameters['mobileNumber'] = this.searchByMobile;
    }

    if (this.searchByAlias) {
      parameters['alias'] = this.searchByAlias;
    }

    if (this.searchByEmail) {
      parameters['email'] = this.searchByEmail;
    }

    if (this.searchByFirstName) {
      parameters['firstName'] = this.searchByFirstName;
    }

    if (this.searchByLastName) {
      parameters['lastName'] = this.searchByLastName;
    }

    // let queryParams = new HttpParams({ fromObject: parameters });
    // this.userService
    //   .fetchAll(queryParams)
    //   .subscribe((response: WalletApiResponse<any>) => {
    //     if (response.status !== 'success') {
    //       this.loading = false;
    //       return;
    //     }
    //     this.users = response.data.walletAccountUsers.data;
    //     this.currentPage = response.data.walletAccountUsers.page;
    //     this.totalItems = response.data.walletAccountUsers.total;
    //     this.pageSize = response.data.walletAccountUsers.limit;
    //     this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
    //     this.loading = false;
    //   });
  }

  private getKYCPendingList(): void {
    this.loading = true;
    this.users = [];

    this.userService
      .fetchUsersWithPendingKYC()
      .pipe(
        map((res) => res.data),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (res) => {
          if (res.walletAccountUsers && res.walletAccountUsers.data) {
            this.users = [...res.walletAccountUsers.data];
            this.currentPage = res.walletAccountUsers.page;
            this.totalItems = res.walletAccountUsers.total;
            this.pageSize = res.walletAccountUsers.limit;
            this.totalPages = new Array(
              Math.ceil(this.totalItems / this.pageSize)
            );
          }
        },
        error: (e) => console.error(e),
      });
  }
}
