import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Subscription, switchMap } from 'rxjs';

import { AuthService } from './auth/auth.service';
import { WalletEvent, WalletEventApiResponse } from './models';
import { WalletEventsService } from './wallet-events/services';

declare global {
  interface Window {
    One: any;
  }
}
type User = {
  username: string;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'wallet-control-panel';
  subscription: Subscription;
  isLoggedIn: boolean;
  user: Partial<User> = {
    username: '........',
  };

  walletEvents: WalletEventApiResponse;
  selectedWalletEvent: WalletEvent = null;
  count = 0;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletEventsService
  ) {
    this.isLoggedIn = true;
  }

  ngOnInit(): void {
    this.subscription = this.authService
      .isAuthenticated()
      .subscribe((result) => {
        this.isLoggedIn = result;
      });

    this.authService.user().subscribe((info) => {
      this.user = info;
    });

    this.search();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickLogout() {
    this.authService.signOut().subscribe(() => {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('user');
      window.location.href = '/login';
    });
  }

  search(): void {
    const then = subtractDaysFromToday();

    this.walletService.setFromTimestamp(then.toString());

    this.walletService
      .fetchWalletEventsDescription('error')
      .pipe(
        map((res) => {
          if (res.status !== 'success') {
            return [];
          }
          const data = res.data.message.map((m) => m.description);

          this.walletService.setActions([...data]);

          return data;
        }),
        switchMap((data) =>
          this.walletService.fetchWalletEvents({
            fromTimestamp: then.toString(),
            actions: [...data],
          })
        )
      )
      .subscribe({
        next: (res) => {
          if (res.status !== 'success') return;

          this.walletEvents = res;

          this.count = res.data.total;
        },
      });
  }

  open(item: WalletEvent): void {
    this.selectedWalletEvent = item;
  }

  seeAll(): void {
    this.walletService.setWalletErrorEvents(this.walletEvents);

    this.router.navigate(['./wallet-events'], { relativeTo: this.route });
  }
}

function subtractDaysFromToday(days: number = 3): number {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Get current Unix timestamp
  const secondsInADay = 86400; // 24 * 60 * 60
  return currentTimestamp - days * secondsInADay;
}
