<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Comments
            </h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                    </li>
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Comments
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
    <div class="container p-0">
        <div class="card">
            <div class="row g-0">

                <div class="col-12">
                    <div class="flex-grow-0 py-3 px-4 border-top">
                        <div class="input-group">
                            <textarea class="form-control"
                                      rows="3"
                                      placeholder="Type your message here..."
                                      [formControl]="comment"></textarea>
                            <button class="btn btn-primary ml-2"
                                    (click)="postComment($event)">
                                <i class="fa fa-paper-plane"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="position-relative">
                        <div class="chat-messages p-4">
                            <div *ngIf="loading">
                                <div class="spinner-border"
                                     style="width: 3rem; height: 3rem;"
                                     role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow"
                                     style="width: 3rem; height: 3rem;"
                                     role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="comments.length === 0 && !loading"> No comments available</div>
                            <div *ngFor="let c of comments">
                                <div class="chat-message-left pb-4">
                                    <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
                                        <div class="font-weight-bold">
                                            Author: {{ c.author.firstName }} {{ c.author.lastName }}
                                        </div>
                                        <em style="font-size: 12px;" class="mb-2">
                                            {{ c.created_at * 1000 | date: 'yyyy-MM-dd HH:mm:ss' }}
                                        </em>
                                        <div class="mt-1">
                                            {{ c.content }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>