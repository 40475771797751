import { Component, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, noop, switchMap, take, tap } from 'rxjs';
import { NotifierService } from 'src/app/notifier';

import { CommunityRequest } from '../../models';
import { CommunityService } from '../../services';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrl: './view-page.component.css',
})
export class ViewPageComponent {
  private readonly _communityService = inject(CommunityService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _fb = inject(FormBuilder);
  private readonly _notifier = inject(NotifierService);

  loading = false;
  selectedCommunityRequest: CommunityRequest | null = null;

  form = this._fb.group({
    email: '',
    mobileNumber: '',
    phoneNumber: '',
    community_name: '',
    type: '',
    translate_type: this._fb.group({
      FR: '',
      EN: '',
    }),
    description: '',
    address: this._fb.group({
      street_name: '',
      street_number: '',
      post_code: '',
      state: '',
      country: '',
      countryCode: '',
    }),
    owner: this._fb.group({
      user_id: '',
      last_name: '',
      first_name: '',
    }),
    status: '',
    unique_id: '',
    type_of_membership: '',
    translate_type_of_membership: this._fb.group({
      FR: '',
      EN: '',
    }),
    created_at: '',
    updated_at: '',
    community_id: '',
    organization_id: '',
    members_count: '',
    join_status: '',
    profile_picture: '',
    approval_code: '',
    // meta_data: {},
  });

  ngOnInit(): void {
    this.getCommunityDetails();
  }

  onAccept(): void {
    this._communityService
      .updateCommunityStatus(this.selectedCommunityRequest.community_id, {
        status: 'VALIDATED',
      })
      .pipe(
        tap((res) => {
          if (res.status === 'success') {
            this._notifier.success(res.status);
          }
        }),
        take(1)
      )
      .subscribe(noop);
  }

  onBlock(): void {
    this._communityService
      .updateCommunityStatus(this.selectedCommunityRequest.community_id, {
        status: 'BLOCKED',
      })
      .pipe(
        tap((res) => {
          if (res.status === 'success') {
            this._notifier.success(res.status);
          }
        }),
        take(1)
      )
      .subscribe(noop);
  }

  private getCommunityDetails(): void {
    this._route.params
      .pipe(
        map((params) => params['id']),
        switchMap((id) => this._communityService.fetchCommunityDetails(id)),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.form.patchValue(res.data);
            this.selectedCommunityRequest = { ...res.data };
          }
        },
      });
  }
}
