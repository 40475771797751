<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                KYC Rules
            </h1>
        </div>
    </div>
</div>

<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/compliance','kyc', 'rules', 'transactions', 'amount', 'list']">Transaction
                            Amount</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Create New Rule
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
        <div class="col-md-6">
            <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                <p class="mb-0"> {{ alert.message }} </p>
            </div>
            <div class="js-wizard-simple block">
                <!-- Form -->
                <form [formGroup]="ruleForm" (submit)="createRule()">
                    <!-- Steps Content -->
                    <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
                        <!-- Step 1 -->

                        <h2 class="content-heading border-bottom mb-4 pb-2">General Settings</h2>
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input class="form-control" type="text" id="name" name="name" formControlName="name">
                        </div>

                        <div class="form-group">
                            <label for="action">Status</label>
                            <select class="form-control" formControlName="status" id="status"
                                name="status">
                                <option></option>
                                <!-- Required for data-placeholder attribute to work with Select2 plugin -->
                                <option value="active">Active</option>
                                <option value="disabled">Disabled</option>
                            </select>
                        </div>

                        <h2 class="content-heading border-bottom mb-4 pb-2">Location</h2>
                        <div formGroupName="location">
                            <div class="form-group">
                                <label for="kycRegions">
                                    Regions
                                    <a target="_blank" [routerLink]="['/compliance','kyc', 'regions', 'add', 'new']">
                                        [ <i class="nav-main-link-icon fas fa-plus"></i> Create a new region]</a>
                                    [ <a (click)="refresh()" href="javascript:;"><i class="nav-main-link-icon fas fa-sync-alt"></i>Refresh</a>]
                                    <i *ngIf="loadingRegions" class="nav-main-link-icon fa fa-sun fa-spin">
                                    </i>
                                </label>
                                
                                <select class="form-control" formControlName="regions" id="kycRegions"
                                    name="kycRegions" multiple>
                                    <option></option>
                                    <option *ngFor= "let region of regions" value="{{region.regionId}}">{{region.name}}</option>
                                </select>
                            </div>
                        </div>
                        <h2 class="content-heading border-bottom mb-4 pb-2">Transaction</h2>

                        <div formGroupName="transaction">
                            <div>
                                <div class="form-group">
                                    <label for="transactionType">Type</label>
                                    <select class="form-control" formControlName="transactionTypes" id="transactionType"
                                        name="transactionType"
                                        multiple>
                                        <option></option>
                                        <!-- Required for data-placeholder attribute to work with Select2 plugin -->
                                        <option value="TopUp">Top Up</option>
                                        <option value="CashOut">Cash Out</option>
                                        <option value="Transfer">Transfer</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="minThreshold">Min Threshold (Cents)</label>
                                    <input class="form-control" type="text" formControlName="minThreshold"
                                        id="minThreshold" name="minThreshold">
                                </div>
                                <div class="form-group">
                                    <label for="maxThreshold">Max Threshold (Cents)</label>
                                    <input class="form-control" type="text" formControlName="maxThreshold"
                                        id="maxThreshold" name="maxThreshold">
                                </div>
                                <div class="form-group">
                                    <label for="transactionPeriod">Period (Seconds)</label>
                                    <input class="form-control" type="text" formControlName="period"
                                        id="transactionPeriod" name="transactionPeriod">
                                </div>
                            </div>
                        </div>
                        <h2 class="content-heading border-bottom mb-4 pb-2">Actions</h2>
                        <div>
                            <div class="form-group">
                                <label for="action">Actions</label>
                                <select class="form-control" formControlName="actions" id="action"
                                    name="action"
                                    multiple>
                                    <option></option>
                                    <!-- Required for data-placeholder attribute to work with Select2 plugin -->
                                    <option value="BLOCK_TRANSACTION">Block transaction</option>
                                    <option value="REPORT_TRANSACTION">Report transaction</option>
                                </select>
                            </div>
                        </div>

                        <h2 class="content-heading border-bottom mb-4 pb-2">KYC</h2>
                        <div>
                            <div class="form-group">
                                <label for="kycDocuments">
                                    Documents
                                    [ <a target="_blank" [routerLink]="['/compliance','documents', 'types', 'add', 'new']">
                                        <i class="nav-main-link-icon fas fa-plus"></i> Create new document type</a>]
                                        [ <a (click)="refresh()" href="javascript:;"><i class="nav-main-link-icon fas fa-sync-alt"></i>Refresh</a>]
                                    <i *ngIf="loadingDocumentTypes" class="nav-main-link-icon fa fa-sun fa-spin">
                                    </i>
                                </label>
                                <select class="form-control" formControlName="documents" id="kycDocuments"
                                    name="kycDocuments" multiple>
                                    <option></option>
                                    <option *ngFor= "let type of documentTypes" value="{{type.typeId}}">{{type.name}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- END Step 1 -->
                    </div>
                    <!-- END Steps Content -->

                    <!-- Steps Navigation -->
                    <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                        <div class="row">
                            <div class="col-6 text-right">
                                <button type="submit" class="btn btn-primary" data-wizard="finish">
                                    <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- END Steps Navigation -->
                </form>
                <!-- END Form -->
            </div>
        </div>
        <div class="col-md-6">
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">
                        KYC rules in NBK Wallet Platform
                    </h3>
                    <div class="block-options">
                        <button type="button" class="btn-block-option">
                            <i class="si si-settings"></i>
                        </button>
                    </div>
                </div>
                <div class="block-content">
                    <p>Each transaction is ...</p>
                </div>
            </div>
        </div>
    </div>
</div>