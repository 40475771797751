import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { DirectivesModule } from '../directives/directives.module';
import { CommunityAndServiceRoutingModule } from './community-and-service-routing.module';
import { ListPageComponent, MainPageComponent, ViewPageComponent } from './pages';

@NgModule({
  declarations: [MainPageComponent, ListPageComponent, ViewPageComponent],
  imports: [
    CommonModule,
    CommunityAndServiceRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
  ],
})
export class CommunityAndServiceModule {}
