<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Add New Document Type
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">

    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/compliance','documents', 'types', 'list']">Document Types</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add New Document Type
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-md-6">
                <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p class="mb-0"> {{ alert.message }} </p>
                </div>
                <div class="js-wizard-simple block">
                    <!-- Form -->
                    <form [formGroup]="addDocumentTypeForm" novalidate (ngSubmit)="addNewType()">
                        <div class="block-content block-content-full tab-content px-md-5">
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input class="form-control" type="text" id="name" name="name" formControlName="name">
                            </div>

                            <div class="form-group">
                                <label for="name">Description</label>
                                <textarea class="form-control" id="description" name="description" formControlName="description">
                                </textarea>
                            </div>
                        </div>

                        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                            <div class="row">
                                <div class="col-6 text-right">
                                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                                        <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- END Steps Navigation -->
                    </form>
                    <!-- END Form -->
                </div>
            </div>
        </div>
    </div>