import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ListEventsComponent } from './list-events/list-events.component';
import { EventsRoutingModule } from './events-routing.module';



@NgModule({
  declarations: [
    ListEventsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    EventsRoutingModule
  ]
})
export class EventsModule { }
