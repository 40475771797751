import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

import { CommunityRequestApiResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CommunityService {
  private readonly BASE_URL =
    environment.wallet.fundRaisingGatewayServiceBaseUrl;

  constructor(private apiService: ApiService) {}

  fetchAllCommunities(
    params: HttpParams
  ): Observable<CommunityRequestApiResponse> {
    return this.apiService.get(`${this.BASE_URL}/v1/api/communities`, params);
  }

  fetchCommunityDetails(communityId: string): Observable<any> {
    return this.apiService.get(
      `${this.BASE_URL}/v1/api/communities/${communityId}`
    );
  }

  updateCommunityStatus(
    communityId: string,
    data: { status: 'VALIDATED' | 'BLOCKED' }
  ): Observable<any> {
    return this.apiService.patch(
      `${this.BASE_URL}/v1/api/communities/${communityId}`,
      data
    );
  }
}
