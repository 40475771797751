import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs';
import { NotifierService } from 'src/app/notifier';
import { Task } from 'src/app/support-cases/models';
import { SupportCasesService } from 'src/app/support-cases/services';

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrl: './view-page.component.css',
})
export class ViewPageComponent {
  task: Task | null = null;
  userId = '';
  taskId = '';

  form = new FormGroup({
    id: new FormControl(''),
    status: new FormGroup({
      id: new FormControl(''),
      orderIndex: new FormControl(''),
      status: new FormControl(''), //
    }),
    priority: new FormGroup({
      id: new FormControl(''),
      orderIndex: new FormControl(''),
      priority: new FormControl(''),
    }),
    points: new FormControl(''), //
    description: new FormControl(''), //
    creator: new FormGroup({
      id: new FormControl(''),
      email: new FormControl(''),
      username: new FormControl(''), //
    }),
    text_content: new FormControl(''), //
    folder: new FormGroup({
      name: new FormControl(''), //
      id: new FormControl(''),
    }),
  });

  updateForm = new FormGroup({
    archived: new FormControl(false),
    name: new FormControl(''),
    description: new FormControl(''),
    status: new FormControl(''),
    priority: new FormControl([]),
    assignees: new FormControl(null),
  });

  commentCtrl = new FormControl('', [Validators.required]);
  mediaForm = new FormGroup({
    attachment: new FormControl(null, [Validators.required]),
  });

  constructor(
    private supportCasesService: SupportCasesService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private notifier: NotifierService
  ) {
    this.taskId = this.route.snapshot.params['taskId'];
    this.userId = this.route.snapshot.params['userId'];
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        map((params) => params?.taskId),
        switchMap((id) => this.supportCasesService.fetchTask(id)),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.task = res.task;
            this.setFormValue(this.task);
            console.log(this.task);
          }
        },
      });
  }

  onSaveComment(): void {
    if (this.commentCtrl.invalid) {
      throw 'Comment is required!';
    }

    this.supportCasesService
      .addComment(this.taskId, {
        comment_text: this.commentCtrl.value,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
            return;
          }
        },
      });
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsBinaryString(file);

      reader.onload = () => {
        this.mediaForm.patchValue({
          attachment: file,
        });

        this.cd.markForCheck();
      };
    }
  }

  uploadAttachment(): void {
    if (this.mediaForm.invalid) {
      throw 'Attachment is required!';
    }

    const formData = new FormData();

    formData.append('attachment', this.mediaForm.get('attachment').value);

    this.supportCasesService
      .addAttachment(this.taskId, formData)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
            return;
          }
        },
      });
  }

  onUpdate(): void {
    const rawValue = this.updateForm.getRawValue();

    const value = {
      ...rawValue,
      priority: [rawValue.priority],
    };

    this.supportCasesService
      .updateTicket(this.taskId, value)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.notifier.success(res.status);
            return;
          }
        },
      });
  }

  private setFormValue(value: any): void {
    this.form.patchValue(value);

    this.updateForm.patchValue({
      archived: value.archived,
      assignees: value.assignees,
      description: value.description,
      name: value.name,
      priority: value.priority?.priority,
      status: value.status?.status,
    });
  }
}
