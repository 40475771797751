import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserTontineService {
  constructor(
    private apiService: ApiService
  ) {
  }


  fetch(projectId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/projects/' + projectId + '/projects');
  }

  fetchAll(
    userId: string,
    filter: HttpParams): Observable<any> {
    return this.apiService.get(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/projects/users/' + userId + '/projects');
  }

  fetchAllTontines(): Observable<any> {
    return this.apiService.get(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/projects');
  }

  startTontine(projectId: string): Observable<any> {
    const data = {
      "status": "ACTIVATED"
    }
    return this.apiService.put(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/projects/' + projectId + '/projects', 
      data
    );
  }

  startCollection(projectId: string): Observable<any> {
    return this.apiService.post(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/fund-management/projects/' + projectId + '/collect-funds',
      {});
  }

  startDistribution(projectId: string): Observable<any> {
    return this.apiService.post(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/fund-management/projects/' + projectId + '/transfer-funds',
      {});
  }

  putTontineInRemediation(projectId: string): Observable<any> {
    const data = {
      "isInRemediation": true
    }
    return this.apiService.post(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/fund-management/projects/' + projectId + '/collect-funds',
      data);
  }

  sendNotifications(projectId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/projects/' + projectId + '/non-payers/notifications');
  }

  cancelTontine(projectId: string): Observable<any> {
    const data = {
      "status": "CANCELLED"
    }
    return this.apiService.put(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/project/' + projectId + '/projects',
      data);
  }

  fetchBy(queryParams: HttpParams): Observable<any> {
    return this.apiService.get(
      environment.wallet.fundRaisingServiceBaseUrl + '/v1/fund-raising/projects', queryParams);
  }
}
