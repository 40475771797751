import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import {FormBuilder, Validators} from '@angular/forms';
import { UserEntity } from '../user-entity';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-wallet-user',
  templateUrl: './create-wallet-user.component.html',
  styleUrls: ['./create-wallet-user.component.css']
})
export class CreateWalletUserComponent implements OnInit {

  walletUserForm;
  alert: AlertEntity;
  inProgress: boolean;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
  ) { 
    this.walletUserForm = this.formBuilder.group({
      lastName : '',
      firstName: '',
      phoneNumber: '',
      mobileNumber: '',
      address : this.formBuilder.group({
        city: '',
        country: '',
        postCode: '',
        state: '',
        streetName: '',
        streetNumber: ''
      }),
      language: '',
      email: '',
      status: ''
    });

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
  }

   ngOnInit(): void {

  }

  createUser(){
    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.userService.create(
        this.walletUserForm.value
      ).subscribe((response)=>{
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully created user ';
      });
    });
  }

}
