import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { BankPayloadsPipePipe } from '../bank-payloads-pipe.pipe';
import { DirectivesModule } from '../directives/directives.module';
import { AccountModule } from './account/account.module';
import { BankPayloadsComponent } from './bank-payloads/bank-payloads.component';
import {
    CreateNewUserBeneficiaryComponent,
} from './beneficiaries/create-new-user-beneficiary/create-new-user-beneficiary.component';
import { DeleteUserBeneficiaryComponent } from './beneficiaries/delete-user-beneficiary/delete-user-beneficiary.component';
import { DetailUserBeneficiaryComponent } from './beneficiaries/detail-user-beneficiary/detail-user-beneficiary.component';
import { ListUserBeneficiariesComponent } from './beneficiaries/list-user-beneficiaries/list-user-beneficiaries.component';
import { CommentsComponent } from './comments/comments.component';
import { CreateUserPaymentMeanComponent } from './create-user-payment-mean/create-user-payment-mean.component';
import { CreateWalletUserComponent } from './create-wallet-user/create-wallet-user.component';
import { DetailComponent } from './detail/detail.component';
import { AddUserDocumentsComponent } from './documents/add-user-documents/add-user-documents.component';
import { EditUserDocumentComponent } from './documents/edit-user-document/edit-user-document.component';
import { ListUserDocumentsComponent } from './documents/list-user-documents/list-user-documents.component';
import { EditUserPaymentMeanComponent } from './edit-user-payment-mean/edit-user-payment-mean.component';
import { KycErrorDetailsComponent } from './kyc-error-details/kyc-error-details.component';
import { ListComponent } from './list/list.component';
import { MoneyTransEKYCErrorsComponent } from './money-trans-ekyc-errors/money-trans-ekyc-errors.component';
import { PendingValidationListComponent } from './pending-validation-list/pending-validation-list.component';
import { SupportCasesComponent } from './support-cases/support-cases/support-cases.component';
import { ViewPageComponent } from './support-cases/view-page/view-page.component';
import { ListUserTontinesComponent } from './tontine/list-user-tontines/list-user-tontines.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UserPaymentMeansComponent } from './user-payment-means/user-payment-means.component';
import { UsersRoutingModule } from './users-routing.module';
import { WalletEventsComponent } from './wallet-events/wallet-events.component';

@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    CreateWalletUserComponent,
    ListUserBeneficiariesComponent,
    CreateNewUserBeneficiaryComponent,
    DetailUserBeneficiaryComponent,
    DeleteUserBeneficiaryComponent,
    UserPaymentMeansComponent,
    CreateUserPaymentMeanComponent,
    EditUserPaymentMeanComponent,
    ListUserDocumentsComponent,
    AddUserDocumentsComponent,
    EditUserDocumentComponent,
    ListUserTontinesComponent,
    BankPayloadsComponent,
    PendingValidationListComponent,
    KycErrorDetailsComponent,
    TransactionsComponent,
    WalletEventsComponent,
    SupportCasesComponent,
    CommentsComponent,
    MoneyTransEKYCErrorsComponent,
    ViewPageComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    AccountModule,
    ReactiveFormsModule,
    FormsModule,
    BankPayloadsPipePipe,
    DirectivesModule,
    PdfViewerModule,
    NgSelectModule
  ],
})
export class UserModule {}
