<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Service Statuses</h1>
        </div>
    </div>
</div>

<div class="bg-body-extra-light">
    <div class="content content-full">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-4 py-4">

                <ul class="list-group push">
                    <li class="list-group-item d-flex justify-content-between align-items-center fw-medium">
                        Backend
                        <span
                              class="fs-sm fw-semibold d-inline-block py-1 px-3 rounded-pill bg-success-light text-success">Operational</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center fw-medium">
                        Frontend
                        <span
                              class="fs-sm fw-semibold d-inline-block py-1 px-3 rounded-pill bg-danger-light text-danger">Down</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center fw-medium">
                        API
                        <span
                              class="fs-sm fw-semibold d-inline-block py-1 px-3 rounded-pill bg-success-light text-success">Operational</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center fw-medium">
                        Payments
                        <span
                              class="fs-sm fw-semibold d-inline-block py-1 px-3 rounded-pill bg-warning-light text-warning">Maintenance</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center fw-medium">
                        Helpdesk
                        <span
                              class="fs-sm fw-semibold d-inline-block py-1 px-3 rounded-pill bg-success-light text-success">Operational</span>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</div>