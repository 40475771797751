import { Component, OnInit } from '@angular/core';
import { AccountEntity } from 'src/app/wallet-api/account-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/wallet-api/accounts.service';
import {FormBuilder, Validators} from '@angular/forms';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AccountApiResponse } from './account-api-response';
import { ProgressBarEntity } from 'src/app/progress-bar/progress-bar-entity';

@Component({
  selector: 'app-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.css']
})
export class EditAccountComponent implements OnInit {

  walletAccountForm;
  account: AccountEntity;
  progressBar: ProgressBarEntity;

  constructor(
    private accountService: AccountsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { 

    this.progressBar = {
      valueNow: 0
    };
    
    this.walletAccountForm = this.formBuilder.group({
      name: '',
      accountType : '',
      balance: '',
      status: ''
    });

    this.account = {
      name: '',
      userId: '',
      accountId: '',
      accountType: '',
      balance: 0,
      walletPlanId: '',
      status: ''
    }
  }

  ngOnInit(): void {
    this.progressBar.valueNow = 30;
    this.authService.token().subscribe(() => {
      this.progressBar.valueNow = 50;
      this.accountService.fetch(
        this.route.snapshot.paramMap.get('userId'),
        this.route.snapshot.paramMap.get('accountId')
        ).subscribe((response: WalletApiResponse<AccountApiResponse>) => {
          this.progressBar.valueNow = 100;
        this.account = response.data.walletAccount;
        this.walletAccountForm.patchValue({
          accountType : response.data.walletAccount.accountType,
          balance : response.data.walletAccount.balance,
          status: response.data.walletAccount.status,
          name: response.data.walletAccount.name
        });
      });
    });
  }

}
