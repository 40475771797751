import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { FeeCreateComponent } from './fee-create/fee-create.component';
import { FeeEditComponent } from './fee-edit/fee-edit.component';
import { FeeListComponent } from './fee-list/fee-list.component';

const routes: Routes = [
  {
    path: 'fees/list',
    component: FeeListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fees/add/new',
    component: FeeCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fees/edit/:feeId',
    component: FeeEditComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeesRoutingModule { }
