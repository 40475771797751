<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                New Wallet Plan
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center"
            data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->

    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/plans']">Plan List</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            New Plan
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-md-6">
                <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p class="mb-0"> {{ alert.message }} </p>
                </div>
                <div class="js-wizard-simple block">
                    <!-- Form -->
                    <form [formGroup]="createWalletPlanForm" (ngSubmit)="createWalletPlan()">
                        <!-- Steps Content -->
                        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
                            <!-- Step 1 -->
                            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input class="form-control" type="text" id="name" name="name"
                                        formControlName="name">
                                </div>
                                <div class="form-group">
                                    <label for="currency">Currency</label>
                                    <select class="form-control" id="currency" name="currency"
                                        formControlName="currency">
                                        <option value="0">Please select</option>
                                        <option value="EUR">EUR</option>
                                        <option value="GBP">GBP</option>
                                        <option value="GBP">USD</option>
                                        <option value="GBP">XOF</option>
                                        <option value="GBP">XAF</option>
                                        <option value="GBP">CAD</option>
                                        <option value="GBP">CNY</option>

                                    </select>
                                </div>

                                <div formGroupName="compliance">
                                    <div class="form-group">
                                        <label for="complianceRuleId">
                                            KYC Rules
                                            <a target="_blank" [routerLink]="['/compliance','kyc', 'rules', 'transactions', 'amount', 'list']">
                                                [ <i class="nav-main-link-icon fas fa-plus"></i> Create a new KYC rule]</a>
                                        </label>
                                        <select class="form-control" id="complianceRuleId" formControlName="kycRules"
                                            name="complianceRuleId" multiple>
                                            <option value="0">Please select</option>
                                            <option *ngFor="let rule of kycRules" value="{{ rule.ruleId }}">
                                                {{ rule.name }}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="form-group">
                                    <label>Status</label>
                                    <div class="custom-control custom-switch mb-1">
                                        <input type="checkbox" class="custom-control-input" id="status" name="status"
                                            checked="" formControlName="status">
                                        <label class="custom-control-label" for="status">Enabled</label>
                                    </div>
                                </div>
                            </div>
                            <!-- END Step 1 -->
                        </div>
                        <!-- END Steps Content -->

                        <!-- Steps Navigation -->
                        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                            <div class="row">
                                <div class="col-6 text-right">
                                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                                        <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- END Steps Navigation -->
                    </form>
                    <!-- END Form -->
                </div>
            </div>
        </div>
    </div>