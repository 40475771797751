import { Component, OnInit } from '@angular/core';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-bank-account-payout',
  templateUrl: './create-bank-account-payout.component.html',
  styleUrls: ['./create-bank-account-payout.component.css']
})
export class CreateBankAccountPayoutComponent implements OnInit {

  accountId: string;
  bankCashOutForm;
  alert: AlertEntity;
  inProgress: boolean

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { 
      this.accountId = this.route.snapshot.paramMap.get('accountId')

      this.bankCashOutForm = this.formBuilder.group({
        amount : '',
        paymentMeanId: ''
      });

      this.alert = {
        display: false,
        message: ''
      };
  
      this.inProgress = false;
  }

  ngOnInit(): void {

  }

  createCashOut(){
    this.inProgress = false;
    this.alert.display = true;
    this.alert.message = 'Successfully created payout request ';
  }

}
