import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ListPageComponent, MainPageComponent, ViewPageComponent } from './pages';
import { SupportCasesRoutingModule } from './support-cases-routing.module';


@NgModule({
  declarations: [MainPageComponent, ViewPageComponent, ListPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SupportCasesRoutingModule
  ]
})
export class SupportCasesModule { }
