import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { DirectivesModule } from '../directives/directives.module';
import { MainPageComponent } from './pages';
import { WalletEventsRoutingModule } from './wallet-events-routing.module';


@NgModule({
  declarations: [MainPageComponent],
  imports: [
    CommonModule,
    WalletEventsRoutingModule,
    NgSelectModule,
    FormsModule,
    DirectivesModule
  ]
})
export class WalletEventsModule { }
