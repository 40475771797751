<div class="bg-body-light">
  <div class="content content-full">
    <div
      class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
    >
      <h1 class="flex-sm-fill h3 my-2">User beneficiaries</h1>
    </div>
  </div>
</div>

<div class="col-xl-6">
  <div class="block">
    <div class="block-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb push">
          <li class="breadcrumb-item">
            <a [routerLink]="['/users']">User List</a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/users', userId]">User</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Beneficiaries
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="js-wizard-simple block">
  <div
    class="text-center"
    *ngIf="loading"
    style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="content" *ngIf="!loading">
  <div class="row">
    <div class="col-md-12">
      <div class="block">
        <div class="block-header">
          <h3 class="block-title"></h3>
          <div class="block-options">
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-primary dropdown-toggle"
                id="dropdown-default-primary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Actions
              </button>
              <div
                class="dropdown-menu font-size-sm"
                aria-labelledby="dropdown-default-primary"
                style=""
              >
                <a
                  [routerLink]="[
                    '/users',
                    userId,
                    'beneficiaries',
                    'add',
                    'new'
                  ]"
                  class="dropdown-item"
                  >Add new beneficiary</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="block-content">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-vcenter">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th style="width: 30%">Status</th>
                  <th style="width: 15%">Created At</th>
                  <th class="text-center" style="width: 100px">Actions</th>
                </tr>
              </thead>

              <div *ngIf="loading">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <div
                  class="spinner-grow"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <tbody>
                <tr *ngFor="let beneficiary of beneficiaries">
                  <td class="font-w600 font-size-sm">
                    <a href="#">{{ beneficiary.beneficiaryDetails.name }}</a>
                  </td>
                  <td class="font-w600 font-size-sm">
                    <a href="#">{{ beneficiary.beneficiaryType }}</a>
                  </td>
                  <td>
                    <span
                      [ngClass]="{
                        badge: true,
                        'badge-success': beneficiary.status == 'active',
                        'badge-danger': beneficiary.status == 'deactivated'
                      }"
                    >
                      {{
                        beneficiary.status == "active" ? "Active " : "Disabled"
                      }}
                    </span>
                  </td>
                  <td class="font-size-sm">
                    <em class="text-muted">{{
                      beneficiary.createdAt * 1000
                        | date : "yyyy/MM/dd h:mm:ss a"
                    }}</em>
                  </td>

                  <td class="text-center">
                    <div class="btn-group">
                      <button
                        [routerLink]="[
                          '/users',
                          userId,
                          'beneficiaries',
                          beneficiary.beneficiaryId
                        ]"
                        type="button"
                        class="btn btn-sm btn-primary"
                        data-toggle="tooltip"
                        title="Edit"
                      >
                        <i class="fa fa-fw fa-pencil-alt"></i>
                      </button>
                      <button
                        [routerLink]="[
                          '/users',
                          userId,
                          'beneficiaries',
                          beneficiary.beneficiaryId,
                          'delete'
                        ]"
                        type="button"
                        class="btn btn-sm btn-primary"
                        data-toggle="tooltip"
                        title="TopUp"
                      >
                        <i class="fa fa-fw fa-times"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
