import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    fromEvent,
    map,
    of,
    startWith,
    Subject,
    switchMap,
    takeUntil,
} from 'rxjs';

import { PeriodDirective } from '../../directives/period.directive';
import { DashboardService } from '../../services';
import { convertData } from '../../services/convert-data';
import { CardComponent } from '../card/card.component';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [CardComponent, ChartComponent, PeriodDirective, AsyncPipe, NgIf],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
})
export class UsersComponent {
  private readonly dashboardService = inject(DashboardService);

  @ViewChild('col') colEl!: ElementRef<HTMLDivElement>;

  @ViewChild('aup') aup!: ElementRef<HTMLInputElement>;
  @ViewChild('auf') aufEl!: ElementRef<HTMLSelectElement>;

  @ViewChild('activeUP') activeUP!: ElementRef<HTMLInputElement>;
  @ViewChild('activeUF') activeUFEl!: ElementRef<HTMLSelectElement>;

  @ViewChild('iup') iup!: ElementRef<HTMLInputElement>;
  @ViewChild('iuf') iufEl!: ElementRef<HTMLSelectElement>;

  @ViewChild('dup') dup!: ElementRef<HTMLInputElement>;
  @ViewChild('duf') dufEl!: ElementRef<HTMLSelectElement>;

  // events
  aup$;
  auf$;

  activeUP$;
  activeUF$;

  iup$;
  iuf$;

  dup$;
  duf$;

  allUsersCount$ = this.dashboardService.fetchNewUsersCount().pipe(
    map((res) => res.data.count || 0),
    catchError(() => of(0))
  );

  allUsersPeriods: { date: Date; value: number }[] = [];

  activeUsersCount$ = this.dashboardService.fetchNewUsersCount('active').pipe(
    map((res) => res.data.count || 0),
    catchError(() => of(0))
  );

  activeUsersPeriods: { date: Date; value: number }[] = [];

  inactiveUsersCount$ = this.dashboardService
    .fetchNewUsersCount('inactive')
    .pipe(
      map((res) => res.data.count || 0),
      catchError(() => of(0))
    );

  inactiveUsersPeriods: { date: Date; value: number }[] = [];

  deletedUsersCount$ = this.dashboardService.fetchNewUsersCount('deleted').pipe(
    map((res) => res.data.count || 0),
    catchError(() => of(0))
  );

  deletedUsersPeriods: { date: Date; value: number }[] = [];

  private destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.aupSearch();

    this.activeUPSearch();

    this.iupSearch();

    this.dupSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private aupSearch(): void {
    this.aup$ = fromEvent(this.aup.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.auf$ = fromEvent(this.aufEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.aup$, this.auf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getUserCountPerPeriod(data);
        }),
        map(convertData),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.allUsersPeriods = res;
        },
      });
  }

  private activeUPSearch(): void {
    this.activeUP$ = fromEvent(this.activeUP.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.activeUF$ = fromEvent(this.activeUFEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.activeUP$, this.activeUF$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getUserCountPerPeriod({
            user_status: 'active',
            ...data,
          });
        }),
        map(convertData),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.activeUsersPeriods = res;
        },
      });
  }

  private iupSearch(): void {
    this.iup$ = fromEvent(this.iup.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.iuf$ = fromEvent(this.iufEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.iup$, this.iuf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getUserCountPerPeriod({
            user_status: 'inactive',
            ...data,
          });
        }),
        map(convertData),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.inactiveUsersPeriods = res;
        },
      });
  }

  private dupSearch(): void {
    this.dup$ = fromEvent(this.dup.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.duf$ = fromEvent(this.dufEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.dup$, this.duf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getUserCountPerPeriod({
            user_status: 'deleted',
            ...data,
          });
        }),
        map(convertData),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.deletedUsersPeriods = res;
        },
      });
  }
}
