import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { UserPaymentMeansService } from 'src/app/wallet-api/payment-means/user-payment-means.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-user-payment-mean',
  templateUrl: './create-user-payment-mean.component.html',
  styleUrls: ['./create-user-payment-mean.component.css']
})
export class CreateUserPaymentMeanComponent implements OnInit {

  paymentMeanForm; 
  userId: string;
  alert: AlertEntity;
  inProgress: boolean
  
  constructor(
    private userPaymentMeansService: UserPaymentMeansService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { 


    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;

    this.userId = this.route.snapshot.paramMap.get('userId');
    this.paymentMeanForm = this.formBuilder.group({
      name : '',
      type: '',
      bankAccountDetails: this.formBuilder.group({
        iban: '',
        bankName: '',
        bic: ''
      }),
      debitCardDetails: this.formBuilder.group({
        cardName: '',
        cardNumber: ''
      })
    });
  }

  ngOnInit(): void {

  }

  createPaymentMean(): void {
    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.userPaymentMeansService.create(
        this.route.snapshot.paramMap.get('userId'),
        this.paymentMeanForm.value
      ).subscribe((response) => {
        this.alert.display = true;
        this.alert.message = 'Successfully created payment mean'
        this.inProgress = false;
      })
    })
  }

}
