import { Injectable } from '@angular/core';
import { ApiService } from  'src/app/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { BeneficiaryEntity } from './beneficiary-entity';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(
    userId: string
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/beneficiaries');
  }

  fetch(
    userId: string,
    beneficiaryId: string
    ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/beneficiaries/' + beneficiaryId);
  }

  create(
    userId: string,
    beneficiaryEntity: BeneficiaryEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/users/' + userId + '/beneficiaries',
      beneficiaryEntity
    )
  }
}
