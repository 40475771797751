import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-user-beneficiary',
  templateUrl: './detail-user-beneficiary.component.html',
  styleUrls: ['./detail-user-beneficiary.component.css']
})
export class DetailUserBeneficiaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
