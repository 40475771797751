import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { UserDocumentCollection } from 'src/app/compliance-api/documents/user-document-collection';
import { UserDocumentEntity } from 'src/app/compliance-api/documents/user-document-entity';
import { UserDocumentsService } from 'src/app/compliance-api/documents/user-documents.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { UserService } from '../../user.service';

type ErrorList = {
  timestamp: number;
  errors: any[];
};

@Component({
  selector: 'app-list-user-documents',
  templateUrl: './list-user-documents.component.html',
  styleUrls: ['./list-user-documents.component.css'],
})
export class ListUserDocumentsComponent implements OnInit {
  documents: Array<UserDocumentEntity>;
  errorList: ErrorList[] = [];
  loading: boolean;
  userId: string;

  constructor(
    private userDocumentService: UserDocumentsService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.userDocumentService
        .fetchAll(this.userId, new HttpParams().set('latest', 'true'))
        .subscribe((response: WalletApiResponse<UserDocumentCollection>) => {
          this.documents = response.data.Documents;
          this.loading = false;
        });
    });

    this.getErrorList();
  }

  getErrorList(): void {
    this.userService
      .fetchUserErrorDetails(this.userId)
      .pipe(
        map((res) => res.data.events),
        take(1)
      )
      .subscribe({
        next: (res) => {
          let i = res.walletAccountTransactions.map((t) => {
            return {
              timestamp: t.timestamp,
              errors: Object.keys(t.data.data).map((key) => {
                if (t.data.data['errorCode'] && t.data.data['errorMsg']) {
                  return {
                    errorCode: t.data.data['errorCode'],
                    errorMsg: Array.isArray(t.data.data['errorMsg'])
                      ? t.data.data['errorMsg'].join()
                      : t.data.data['errorMsg'],
                  };
                }

                return t.data.data[key];
              }),
            };
          });

          this.errorList = [...i];
        },
      });
  }
}
