<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Support Cases
            </h1>
        </div>
    </div>
</div>
<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item active"
                        aria-current="page">
                        Support Cases
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div>
    <div class="col-md-12">
        <div class="block">
            <div class="block-content">
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th style="width: 20%">Description</th>
                                <th style="width: 20%">Created By</th>
                                <th style="width: 10%">Status</th>
                                <th style="width: 20%">Date Created</th>
                                <th style="width: 20%">Last Updated</th>
                                <th style="width: 10%">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!loading">
                            <tr *ngFor="let task of tasks">
                                <td class="font-w600 font-size-sm">
                                    {{ task.description }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.creator.username }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.status.status }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.date_created | date : 'dd-MM-yyyy h:mm:ss a' }}
                                </td>
                                <td class="font-w600 font-size-sm">
                                    {{ task.date_updated | date : 'dd-MM-yyyy h:mm:ss a' }}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button [routerLink]="['../details', task.id]"
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                data-toggle="tooltip"
                                                title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--  pagination -->
            </div>
        </div>
    </div>
</div>