import { Component, OnInit } from '@angular/core';
import {PlanService} from '../plan.service';
import {AuthService} from '../../auth/auth.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { PlanCollection } from '../plan-collection';
import { PlanEntity } from '../plan-entity';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})

export class ListComponent implements OnInit {

  plans: Array<PlanEntity> = [];
  loading: boolean;

  constructor(
    private planService: PlanService,
    private authService: AuthService
  ) { 
    this.loading = true;
  }

  
  ngOnInit(){
    this.authService.token().subscribe(() => {
      this.planService.fetchAll().subscribe((response: WalletApiResponse<PlanCollection>) => {
        this.plans = response.data.walletPlans;
        this.loading = false;
      });
    });

  }

}
