import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { UserPaymentMeanEntity } from 'src/app/wallet-api/payment-means/user-payment-mean-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { UserPaymentMeansService } from 'src/app/wallet-api/payment-means/user-payment-means.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { UserPaymentMeanApiResponse } from 'src/app/wallet-api/payment-means/user-payment-mean-api-response';

@Component({
  selector: 'app-edit-user-payment-mean',
  templateUrl: './edit-user-payment-mean.component.html',
  styleUrls: ['./edit-user-payment-mean.component.css']
})
export class EditUserPaymentMeanComponent implements OnInit {

  paymentMean: UserPaymentMeanEntity;

  constructor(
    private userPaymentMeansService: UserPaymentMeansService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) { 
    this.paymentMean = {
      paymenMeanId: '',
      userId: '',
      type: '',
      status: '',
      bankAccountDetails: {
        iban: '',
        bankName: '',
        bic: ''
      },
      debitCardDetails: {
        cardName: '',
        cardNumber: ''
      }
    }
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.userPaymentMeansService.fetch(
        this.route.snapshot.paramMap.get('userId'),
        this.route.snapshot.paramMap.get('paymentMeanId'),
      ).subscribe((response: WalletApiResponse<UserPaymentMeanApiResponse>) => {
        this.paymentMean = response.data.userPaymentMean
      })
    })
  }

}
