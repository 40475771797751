import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DocumentTypeCollection } from 'src/app/compliance-api/documents/types/document-type-collection';
import { DocumentTypeEntity } from 'src/app/compliance-api/documents/types/document-type-entity';
import { DocumentTypeEntityService } from 'src/app/compliance-api/documents/types/document-type-entity.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.css']
})
export class DocumentTypeListComponent implements OnInit {

  documentTypes: Array<DocumentTypeEntity>
  loading: boolean;
  constructor(
    private authService: AuthService,
    private documentTypeService: DocumentTypeEntityService
  ) { 
    this.loading = true;
  }

  ngOnInit(): void {

    this.authService.token().subscribe(() => {
      this.documentTypeService.fetchAll().subscribe((response: WalletApiResponse<DocumentTypeCollection>) => {
        this.documentTypes = response.data.DocumentTypes;
        this.loading = false
      });
    });
  }
}
