<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">KYC Errors</h1>
        </div>
    </div>
</div>
<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content"
     *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <div class="block-content">
                    <h2 class="flex-sm-fill h4 my-4"></h2>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>Account ID</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th style="width: 15%">Timestamp</th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let error of errorList">
                                    <tr *ngFor="let e of error.errors">
                                        <td class="font-size-sm">
                                            <a [routerLink]="['/users', error.entityId, 'documents']">
                                                {{"..." + error.entityId.slice(-4).toUpperCase()}}
                                            </a>
                                        </td>
                                        <td class="font-size-sm">
                                            <em class="text-muted">{{ e.errorCode }}</em>
                                        </td>
                                        <td class="font-size-sm">
                                            <em class="text-muted">{{ e.errorMsg }}</em>
                                        </td>
                                        <td class="font-w600 font-size-sm">
                                            <a href="#">
                                                {{ error.timestamp * 1000 | date : 'dd-MM-yyyy h:mm:ss a'}}
                                            </a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>

                    <!--  pagination -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- max results per page -->
                        <select class="custom-select"
                                style="width: auto"
                                [(ngModel)]="pageSize">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                        </select>

                        <!-- total results -->
                        <span class="text-muted">Showing
                            <span class="badge badge-primary">{{ pageSize }}</span> of
                            <span class="badge badge-primary">{{ totalItems }}</span>
                            results</span>

                        <!-- pagination -->
                        <!-- pagination -->
                        <ul class="pagination">
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(1)">
                                    ««
                                </button>
                            </li>
                            <!-- previous button -->
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="previous()">«</button>
                            </li>
                            <ng-container *ngFor="let p of totalPages; index as i">
                                <li *ngIf="
                        i + 1 >= currentPage - pageSize &&
                        i + 1 <= currentPage + pageSize
                      "
                                    [class]="
                        currentPage === i + 1 ? 'page-item active' : 'page-item'
                      ">
                                    <button class="page-link"
                                            (click)="selectPageNumber(i + 1)">
                                        {{ i + 1 }}
                                    </button>
                                </li>
                            </ng-container>
                            <!-- next button -->
                            <li [class]="
                      currentPage === totalPages.length
                        ? 'page-item disabled'
                        : 'page-item'
                    "
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="next()">»</button>
                            </li>
                            <!-- last button -->
                            <li [class]="
                      currentPage === totalPages.length
                        ? 'page-item disabled'
                        : 'page-item'
                    "
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(totalPages.length)">
                                    »»
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>