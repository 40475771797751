<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Chats
            </h1>
        </div>
    </div>
</div>

<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<div class="content"
     *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <div class="alert alert-danger"
                 role="alert"
                 *ngIf="message != ''">
                {{message}}
            </div>
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title"></h3>
                    <div class="block-options">
                        <button class="btn btn-primary mr-2"
                                (click)="btnCreateClicked()">Create Channel</button>

                        <div class="dropdown">
                            <button type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    id="dropdown-default-primary"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                Actions
                            </button>
                            <div class="dropdown-menu font-size-sm"
                                 aria-labelledby="dropdown-default-primary">
                                <a class="dropdown-item"
                                   href="javascript:void(0)">Create new event</a>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="mt-4 mb-4">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text"
                                       class="form-control"
                                       placeholder="Enter User Id"
                                       [(ngModel)]="userId">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text"
                                       class="form-control"
                                       placeholder="Enter Channel Subject"
                                       [(ngModel)]="channelSubject">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-content">
                    <p class="font-size-sm text-muted">

                    </p>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>User Email</th>
                                    <th style="width: 30%;">Channel ID</th>
                                    <th style="width: 15%;">Subject</th>
                                    <th style="width: 15%;">Is Read</th>
                                    <th style="width: 15%;">Status</th>
                                    <th style="width: 15%;">Created</th>
                                    <!-- <th class="text-center" style="width: 100px;">Actions</th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="!loading">
                                <tr *ngFor="let chan of channels">
                                    <td class="font-w600 font-size-sm">
                                        <a [routerLink]="['/channel', chan.userId]">{{chan.email}}</a>
                                    </td>
                                    <td class="font-size-sm">
                                        <a [routerLink]="['/chats/window', chan.channelId, chan.userId]">{{
                                            chan.channelId }}</a>
                                    </td>
                                    <td class="font-size-sm"><em class="text-muted">{{chan.subject}}</em></td>
                                    <td>
                                        <span
                                              [ngClass]="{'badge': true, 'badge-success': chan.isRead == 'true', 'badge-danger': chan.isRead == 'false'}">
                                            {{ chan.isRead == 'true' ? 'Read': 'Unread' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                              [ngClass]="{'badge': true, 'badge-success': chan.status == 'OPENED', 'badge-danger': chan.status !== 'OPENED'}">
                                            {{ chan.status == 'OPENED' ? 'Active ': 'Disabled' }}
                                        </span>
                                    </td>
                                    <td class="font-size-sm"><em
                                            class="text-muted">{{convertToTime(chan.createdAt)}}</em></td>
                                    <!--
                                    <td class="text-center">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip"
                                                title="Edit">
                                                <i class="fa fa-fw fa-pencil-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip"
                                                title="Delete">
                                                <i class="fa fa-fw fa-times"></i>
                                            </button>
                                        </div>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--  pagination -->
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- max results per page -->
                        <select class="custom-select"
                                style="width: auto;"
                                [(ngModel)]="pageSize">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                        </select>

                        <!-- total results -->
                        <span class="text-muted">Showing <span class="badge badge-primary">{{ pageSize }}</span> of
                            <span class="badge badge-primary">{{ totalItems }}</span> results</span>

                        <!-- pagination -->
                        <!-- pagination -->
                        <ul class="pagination">
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(1)">««</button>
                            </li>
                            <!-- previous button -->
                            <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="previous()">«</button>
                            </li>
                            <ng-container *ngFor="let p of totalPages; index as i">
                                <li *ngIf="i + 1 >= (currentPage - pageSize) && i + 1 <= (currentPage + pageSize)"
                                    [class]="currentPage === (i  + 1) ? 'page-item active' : 'page-item'">
                                    <button class="page-link"
                                            (click)="selectPageNumber(i + 1)">{{i + 1}}</button>
                                </li>
                            </ng-container>
                            <!-- next button -->
                            <li [class]="currentPage === totalPages.length ? 'page-item disabled' : 'page-item'"
                                *ngIf="nextPreviousButtons">
                                <button class="page-link"
                                        (click)="next()">»</button>
                            </li>
                            <!-- last button -->
                            <li [class]="currentPage === totalPages.length ? 'page-item disabled' : 'page-item'"
                                *ngIf="firstLastButtons">
                                <button class="page-link"
                                        (click)="selectPageNumber(totalPages.length)">»»</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>