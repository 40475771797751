import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

import { Collection } from '../project/collection.model';
import { CollectionsApiResponse } from '../project/collections-api-response.model';
import { CollectionsService } from '../project/collections.service';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
  styleUrl: './collection-list.component.css',
})
export class CollectionListComponent implements OnInit {
  loading = false;
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages: any[] = [];
  firstLastButtons = false;
  nextPreviousButtons = true;
  start_date = '';
  end_date = '';
  tontineId = '';
  status = '';

  collections: Collection[] = [];
  collection: any;
  collectionId = '';

  constructor(
    private authService: AuthService,
    private collectionsService: CollectionsService,
    private route: ActivatedRoute
  ) {
    this.loading = true;
    if (window.One != undefined) {
      window.One.loader('show');
    }
  }

  ngOnInit(): void {
    this.loading = true;

    if (window.One != undefined) {
      window.One.loader('show');
    }

    this.authService.token().subscribe(() => {
      this.onSearch();
      if (window.One != undefined) {
        window.One.loader('hide');
      }
    });
  }

  onSearch(event?: Event, key?: string) {
    this.loading = true;
    // let parameters = { page: this.currentPage, limit: this.pageSize };
    let parameters = {};

    if (this.tontineId) {
      parameters['project_id'] = this.tontineId;
    }

    if (this.status) {
      parameters['statuses'] = [this.status].join();
    }

    if (this.start_date) {
      const dateTime = this.start_date.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      parameters['start_date'] = (date.getTime() / 1000).toString();
    }

    if (this.end_date) {
      const dateTime = this.end_date.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      parameters['end_date'] = (date.getTime() / 1000).toString();
    }

    this.collections = [];
    let queryParams = new HttpParams({ fromObject: parameters });

    this.collectionsService
      .fetch(queryParams)
      .subscribe((response: CollectionsApiResponse) => {
        if (response.status !== 'success') {
          this.loading = false;
          return;
        }
        this.collections = [...response.collections];
        this.currentPage = response.page;
        this.totalItems = response.total;
        this.pageSize = response.limit;
        this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
        this.loading = false;
      });
  }

  getCollection(id: string): void {
    this.collectionsService
      .fetchBy(id)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.collection = res.collection;
        },
      });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.onSearch();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }
}
