import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MTNCashInService } from 'src/app/payment-api/mtn/cash-in.service';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { PaypalCashInService } from 'src/app/payment-api/paypal/paypal-cash-in.service';

@Component({
  selector: 'app-paypal-cash-in',
  templateUrl: './paypal-cash-in.component.html',
  styleUrls: ['./paypal-cash-in.component.css']
})
export class PaypalCashInComponent implements OnInit {

  paypalTopUpForm;
  userId: string;
  accountId: string;
  alert: AlertEntity;
  inProgress: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private paypalCashInService: PaypalCashInService
  ) { 

    this.accountId = this.route.snapshot.paramMap.get('accountId');
    this.userId =  this.route.snapshot.paramMap.get('userId');

    this.paypalTopUpForm = this.formBuilder.group({
      amount : 200,
      currency: 'EUR'
    });
  
    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
  }

  ngOnInit(): void {

  }

  topUp(): void{
    this.inProgress = true;
    this.paypalCashInService.cashIn(
      this.route.snapshot.paramMap.get('accountId'),
      {
        amount: this.paypalTopUpForm.value.amount,
        type: "MTN",
        currency: this.paypalTopUpForm.value.currency,
        originator: {
          originatorType: "User",
          originatorId: this.route.snapshot.paramMap.get('userId')
        },
        description: "Top Up",
        regions: ["5e99a07063389569485205f3"]
      }
    ).subscribe((response)=>{
      this.inProgress = false;
      this.alert.display = true;
      this.alert.message = 'Request to pay successfully sent ';
      window.open(response.data.CashIn.extras.approveUrl, "_blank") || window.location.replace(response.data.CashIn.extras.approveUrl);
    });

  }

}
