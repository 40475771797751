import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountEntity } from '../account-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationAccountService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/organizations/accounts');
  }

  fetch(
    accountId: string
    ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/organizations/accounts/' + accountId);
  }

  create(
    accountEntity: AccountEntity): Observable<any> {
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/organizations/accounts',
      accountEntity
    )
  }
}
