import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MarketingRoutingModule } from './marketing-routing.module';
import { ListPageComponent } from './pages';

@NgModule({
  declarations: [ListPageComponent],
  imports: [
    CommonModule,
    MarketingRoutingModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class MarketingModule {}
