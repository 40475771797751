import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bankPayloadsPipe',
  standalone: true
})
export class BankPayloadsPipePipe implements PipeTransform {

  transform(value, type: string = 'response'): string {
    if (type == 'request') {
      if (value) {
        return JSON.stringify(value);
      } else {
        return 'No Request Payload'
      }
    } else {
      return value
    }
  }

  showRequestPayload(value: object) {
    alert(value)
  }

}
