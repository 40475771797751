<div class="bg-body-light">
  <div class="content content-full">
    <div
      class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
    >
      <h1 class="flex-sm-fill h3 my-2">Wallet Tontines</h1>
    </div>
  </div>
</div>

<div class="js-wizard-simple block">
  <div
    class="text-center"
    *ngIf="loading"
    style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
  >
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="content" *ngIf="!loading">
  <div class="row">
    <div class="col-md-12">
      <div class="block">
        <!-- add user search field  -->
        <div class="block-header">
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="dropdown btn-block">
                <!-- Utiliser la classe "btn-block" pour que le bouton prenne toute la largeur -->
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  id="dropdown-default-primary"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Actions
                </button>
                <div
                  class="dropdown-menu font-size-sm"
                  aria-labelledby="dropdown-default-primary"
                >
                  <a
                    [routerLink]="['/users/add/new']"
                    class="dropdown-item"
                    href="javascript:void(0)"
                  >
                    Create new totine
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <form #searchByNameForm="ngForm">
                <div class="form-group">
                  <label for="searchByname">Name:</label>
                  <input
                    type="text"
                    id="searchByname"
                    name="searchByname"
                    [(ngModel)]="searchByname"
                    appSearch
                    (search)="onSearch($event, '')"
                    class="form-control"
                  />
                </div>
              </form>
            </div>
            <div class="col-md-6 mb-3">
              <form #searchByUserIdForm="ngForm">
                <div class="form-group">
                  <label for="searchByUserId">User Id:</label>
                  <input
                    type="text"
                    id="searchByUserId"
                    name="searchByUserId"
                    [(ngModel)]="searchByUserId"
                    appSearch
                    (search)="onSearch($event, '')"
                    class="form-control"
                  />
                </div>
              </form>
            </div>
            <div class="col-md-12 mb-3">
              <form #searchByIsInRemediationForm="ngForm">
                <div class="form-group">
                  <label for="searchByIsInRemediation">In Remediation:</label>
                  <select
                    name="searchByIsInRemediation"
                    id="searchByIsInRemediation"
                    [(ngModel)]="searchByIsInRemediation"
                    (ngModelChange)="search()"
                    class="form-control"
                  >
                    <option value=""></option>
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="block-content">
          <div class="table-responsive">
            <table class="table table-bordered table-striped table-vcenter">
              <thead>
                <tr>
                  <th>Tontine Name</th>
                  <th>Tontine Amount</th>
                  <th style="width: 15%">Frequency</th>
                  <th style="width: 15%">Start At</th>
                  <th style="width: 15%">End At</th>
                  <th style="width: 15%">Status</th>
                  <th style="width: 15%">Is In Remediation</th>
                  <th class="text-center" style="width: 100px">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="!loading">
                <tr *ngFor="let tontine of tontines">
                  <td class="font-size-sm">
                    <em class="text-muted">{{ tontine.name }}</em>
                  </td>
                  <td class="font-size-sm">
                    <em class="text-muted"
                      >{{ tontine.amount }} {{ tontine.currency }}</em
                    >
                  </td>
                  <td class="font-size-sm">
                    <em class="text-muted">{{ tontine.frequencyOfPayment }}</em>
                  </td>
                  <td class="font-w600 font-size-sm">
                    <a href="#">{{ tontine.startAt }}</a>
                  </td>
                  <td class="font-w600 font-size-sm">
                    <a href="#">{{ tontine.endAt }}</a>
                  </td>
                  <td>
                    <span
                      [ngClass]="{
                        badge: true,
                        'badge-success': tontine.status == 'ACTIVATED',
                        'badge-warning': tontine.status == 'IN PROGRESS',
                        'badge-danger': tontine.status == 'CANCELLED'
                      }"
                    >
                      {{ tontine.status }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      [ngClass]="{
                        badge: true,
                        'badge-success': tontine.isInRemediation == false,
                        'badge-danger': tontine.isInRemediation == true
                      }"
                    >
                      {{ tontine.isInRemediation }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="text-center">
                      <button
                        type="button"
                        [routerLink]="['/tontines', 'edit', tontine.projectId]"
                        class="btn btn-sm btn-primary"
                        data-toggle="tooltip"
                        title="Edit"
                      >
                        <i class="fa fa-fw fa-pencil-alt"></i>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--  pagination -->
          <div class="d-flex justify-content-between align-items-center">
            <!-- max results per page -->
            <select
              class="custom-select"
              style="width: auto"
              [(ngModel)]="pageSize"
            >
              <option [ngValue]="10">10</option>
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
            </select>

            <!-- total results -->
            <span class="text-muted"
              >Showing
              <span class="badge badge-primary">{{ pageSize }}</span> of
              <span class="badge badge-primary">{{ totalItems }}</span>
              results</span
            >

            <!-- pagination -->
            <ul class="pagination">
              <li
                [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                *ngIf="firstLastButtons"
              >
                <button class="page-link" (click)="selectPageNumber(1)">
                  ««
                </button>
              </li>
              <!-- previous button -->
              <li
                [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                *ngIf="nextPreviousButtons"
              >
                <button class="page-link" (click)="previous()">«</button>
              </li>
              <ng-container *ngFor="let p of totalPages; index as i">
                <li
                  *ngIf="
                    i + 1 >= currentPage - pageSize &&
                    i + 1 <= currentPage + pageSize
                  "
                  [class]="
                    currentPage === i + 1 ? 'page-item active' : 'page-item'
                  "
                >
                  <button class="page-link" (click)="selectPageNumber(i + 1)">
                    {{ i + 1 }}
                  </button>
                </li>
              </ng-container>
              <!-- next button -->
              <li
                [class]="
                  currentPage === totalPages.length
                    ? 'page-item disabled'
                    : 'page-item'
                "
                *ngIf="nextPreviousButtons"
              >
                <button class="page-link" (click)="next()">»</button>
              </li>
              <!-- last button -->
              <li
                [class]="
                  currentPage === totalPages.length
                    ? 'page-item disabled'
                    : 'page-item'
                "
                *ngIf="firstLastButtons"
              >
                <button
                  class="page-link"
                  (click)="selectPageNumber(totalPages.length)"
                >
                  »»
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
