<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          New Transfer
        </h1>
      </div>
    </div>
  </div>
  
  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        New Transfer
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div class="content">
    <div class="row">
      <div class="col-md-6">
        <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <p class="mb-0"> {{ alert.message }} </p>
        </div>
        <div class="js-wizard-simple block">
        <!-- Form -->
        <form [formGroup]="transferForm" (submit)="transfer()">
        <!-- Steps Content -->
        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
            <!-- Step 1 -->
            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel" formGroupName="sender">
                <div class="form-group">
                    <label for="accountId">Sender Account</label>
                    <select class="form-control" id="accountId" name="accountId" formControlName="accountId">
                        <option value="0">Please select</option>
                        <option *ngFor= "let account of accounts" value="{{ account.accountId }}">{{ account.userId }} - {{ account.name }}</option>
                    </select>
                </div>
            </div>
            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel" formGroupName="receiver">
                <div class="form-group">
                    <label for="beneficiaryId">Beneficiary</label>
                    <select class="form-control" id="beneficiaryId" name="beneficiaryId" formControlName="beneficiaryId">
                        <option value="0">Please select</option>
                        <option *ngFor= "let beneficiary of beneficiaries" value="{{ beneficiary.beneficiaryId }}">{{ beneficiary.beneficiaryDetails.name }}</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label for="accountType">Amount (Cents) </label>
                <input class="form-control" type="text" id="amount" name="amount" formControlName="amount">
            </div>

        </div>
        <!-- END Steps Content -->

        <!-- Steps Navigation -->
        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
                <div class="col-6 text-right">
                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                    </button>
                </div>
            </div>
        </div>
        <!-- END Steps Navigation -->
    </form>
    <!-- END Form -->
</div>
</div>
</div>
</div>
