import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    catchError,
    concat,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    of,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { BankPayloadsCollection } from '../../baas/bank-payloads/bank-payloads-collection';
import { BankPayloadsService } from '../../baas/bank-payloads/bank-payloads.service';

@Component({
  selector: 'app-bank-payloads',
  templateUrl: './bank-payloads.component.html',
  styleUrls: ['./bank-payloads.component.css'],
})
export class BankPayloadsComponent implements OnInit {
  bank_events;
  loading: boolean;
  userId: string;
  messagesLoading = false;
  description: { id: string; name: string };
  messageInput$ = new Subject<string>();
  messages$: Observable<{ id: string; name: string }[]>;
  selectedData: {
    title: string;
    data: any;
  };

  constructor(
    private bankPayloadsService: BankPayloadsService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.bankPayloadsService
        .fetchAll(this.userId)
        .subscribe((response: WalletApiResponse<BankPayloadsCollection>) => {
          this.bank_events = response.data.message;
          this.loading = false;
        });
    });

    this.loadMessages();
  }

  fetchPayload(value?): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      let httpParams = new HttpParams();

      if (this.description?.name) {
        httpParams = httpParams.set('action', this.description.name);
      }

      this.bankPayloadsService
        .fetchAll(this.userId, httpParams)
        .subscribe((response: WalletApiResponse<BankPayloadsCollection>) => {
          this.bank_events = response.data.message;
          this.loading = false;
        });
    });
  }

  onSelect() {
    this.fetchPayload();
  }

  private loadMessages() {
    this.messages$ = concat(
      of([]), // default items
      this.messageInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(Boolean),
        tap(() => (this.messagesLoading = true)),
        switchMap((term) =>
          this.bankPayloadsService.fetchBankPayloadActions(term).pipe(
            map((res) => {
              if (res.status === 'success') {
                return res.data.message.map((m) => ({
                  id: m.action,
                  name: m.action,
                }));
              } else {
                return [];
              }
            }),
            catchError(() => of([])), // empty list on error
            tap(() => (this.messagesLoading = false))
          )
        )
      )
    );
  }

  showData(title: string, value: any) {
    if (title === 'Request Payload') {
      if (typeof value === 'string') {
        this.selectedData = {
          data: JSON.parse(value),
          title,
        };
      }
      if (value) {
        this.selectedData = {
          data: value,
          title,
        };
      } else {
        this.selectedData = {
          data: { Message: 'No Request Payload' },
          title,
        };
      }

      return;
    }

    if (typeof value === 'string') {
      this.selectedData = {
        data: JSON.parse(value),
        title,
      };

      return;
    }

    this.selectedData = {
      data: JSON.parse(value),
      title,
    };
  }
}
