import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import {FormBuilder, Validators} from '@angular/forms';
import { AccountsService } from 'src/app/wallet-api/accounts.service';
import { PlanService } from 'src/app/plans/plan.service';
import { PlanEntity } from 'src/app/plans/plan-entity';
import { PlanCollection } from 'src/app/plans/plan-collection';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-user-account',
  templateUrl: './create-user-account.component.html',
  styleUrls: ['./create-user-account.component.css']
})
export class CreateUserAccountComponent implements OnInit {

  userAccountForm;
  walletPlans : Array<PlanEntity>;
  userId: string;
  alert: AlertEntity;
  inProgress: boolean

  constructor(
    private accountService: AccountsService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private planService: PlanService
  ) { 
    this.userAccountForm = this.formBuilder.group({
      name : '',
      accountType: 'personal',
      walletPlanId: ''
    });

    this.userId = this.route.snapshot.paramMap.get('userId');

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
  }

   ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.planService.fetchAll().subscribe((response: WalletApiResponse<PlanCollection>) => {
        this.walletPlans = response.data.walletPlans;
      });
    });
  }

  createAccount(){
    this.authService.token().subscribe(() => {
      this.inProgress = true;
      this.accountService.create(
        this.route.snapshot.paramMap.get('userId'),
        this.userAccountForm.value
      ).subscribe((response)=>{
        this.alert.display = true;
        this.alert.message = 'Successfully created user account'
        this.inProgress = false;
      });
    });
  }
}
