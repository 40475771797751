export const convertData = (data: any) => {
  const periods = Object.keys(data.data.count);

  const items = periods.map((period) => {
    const value = period.split('/')[0];

    const date = new Date(value);
    // date.setMonth(date.getMonth() - 1);

    return {
      date,
      value: data.data.count[period].reduce(
        (acc, curr) => acc + curr.count,
        0
      ) as number,
      period,
    };
  });

  return items;
};

export const convertTransactions = (data: any) => {
  const periods = Object.keys(data.data);

  const items = periods.map((period) => {
    const value = period.split('/')[0];

    const date = new Date(value);
    // date.setMonth(date.getMonth() - 1);

    return {
      date,
      value: data.data[period].reduce(
        (acc, curr) => acc + curr.count,
        0
      ) as number,
      period,
    };
  });

  return items;
};
