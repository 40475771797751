<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          New User Beneficiay
        </h1>
      </div>
    </div>
  </div>
  
  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users',userId]">User</a>
                    </li>
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users',userId, 'beneficiaries']">User Beneficiaries</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        New User Beneficiary
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
      <div class="col-md-6">
        <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <p class="mb-0"> {{ alert.message }} </p>
        </div>
    <div class="js-wizard-simple block">
    <!-- Form -->
    <form [formGroup]="beneficiaryForm" (submit)="createBeneficiary()">
        <!-- Steps Content -->
        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
            <!-- Step 1 -->
            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
                <h2 class="content-heading border-bottom mb-4 pb-2">General Settings</h2>
                <div class="form-group">
                    <label for="beneficiaryType">Beneficiary Type</label>
                    <select class="form-control" id="beneficiaryType" name="beneficiaryType" formControlName="beneficiaryType">
                        <option value="0">Please select</option>
                        <option value="INDIVIDUAL">INDIVIDUAL</option>
                        <option value="COMPANY">COMPANY</option>
                    </select>
                </div>
                <h2 class="content-heading border-bottom mb-4 pb-2">Beneficiary Details</h2>
                <div formGroupName="beneficiaryDetails">
                    <div class="form-group">
                        <label for="name">name</label>
                        <input class="form-control" type="text" id="name" name="name" formControlName="name">
                    </div>
                    <div class="form-group">
                        <label for="email">email</label>
                        <input class="form-control"  type="text" id="email" name="email" formControlName="email">
                    </div>
                    <div class="form-group">
                        <label for="name">Phone Number</label>
                        <input class="form-control"  type="text" id="name" name="name" formControlName="phoneNumber">
                    </div>

                    <h2 class="content-heading border-bottom mb-4 pb-2">Address</h2>
                    <div formGroupName="address">
                        <div class="form-group">
                            <label for="streetName">Street Name</label>
                            <input class="form-control"  type="text" id="streetName" name="streetName" formControlName="streetName">
                        </div>
                        <div class="form-group">
                            <label for="streetNumber">Street Number</label>
                            <input class="form-control"  type="text" id="streetNumber" name="streetNumber" formControlName="streetNumber">
                        </div>
                        <div class="form-group">
                            <label for="postCode">Post Code</label>
                            <input class="form-control"  type="text" id="postCode" name="postCode" formControlName="postCode">
                        </div>
                        <div class="form-group">
                            <label for="city">City</label>
                            <input class="form-control"  type="text" id="city" name="city" formControlName="city">
                        </div>
                        <div class="form-group">
                            <label for="state">State</label>
                            <input class="form-control"  type="text" id="state" name="state" formControlName="state">
                        </div>
                        <div class="form-group">
                            <label for="state">Country</label>
                            <input class="form-control"  type="text" id="country" name="country" formControlName="country">
                        </div>
                    </div>
                </div>


                <h2 class="content-heading border-bottom mb-4 pb-2">Beneficiary Account Details</h2>
                <div formGroupName="beneficiaryAccountDetails">
                    <div class="form-group">
                        <label for="accountType">Account Type</label>
                        <select class="form-control" id="accountType" name="accountType" formControlName="accountType">
                            <option value="0">Please select</option>
                            <option value="WALLET_ACCOUNT">Wallet Account</option>
                            <option value="BANK_ACCOUNT">Bank Account</option>
                            <option value="DEBIT_CARD">Debit Card</option>
                        </select>
                    </div>

                    <h2 class="content-heading border-bottom mb-4 pb-2">Account Identifiers</h2>
                    <div class="col-6">
                        <button type="button" class="btn btn-alt-success mr-1 mb-3" (click)="addAccountIdentifier()">
                            <i class="fa fa-fw fa-plus mr-1"></i> Add
                        </button>
                    </div>

                    <div *ngFor="let control of beneficiaryForm.controls.beneficiaryAccountDetails.controls.accountIdentifiers.controls; index as i">
                        <h2 class="content-heading border-bottom mb-4 pb-2"> ------ </h2>
                        <div class="form-group">
                            <label>Identifier Type</label>
                            <select class="form-control" [formControl]="beneficiaryForm.controls.beneficiaryAccountDetails.controls.accountIdentifiers.controls[i].controls.accountIdentifierName">
                                <option value="0">Please select</option>
                                <option value="WALLET_ACCOUNT_ID">Wallet Account identifier</option>
                                <option value="IBAN">IBAN</option>
                                <option value="SWIFT_CODE">SWIFT CODE</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label>Identifier Value</label>
                            <input class="form-control"  type="text" [formControl]="beneficiaryForm.controls.beneficiaryAccountDetails.controls.accountIdentifiers.controls[i].controls.accountIdentifierValue">
                        </div>
                    </div>
                </div>

                <h2 class="content-heading border-bottom mb-4 pb-2">Notifications</h2>
                <div formGroupName="transferNotification">

                    <div formGroupName="beneficiary">
                        <div class="form-group">
                            <label>Notify beneficiary</label>
                            <div class="custom-control custom-switch mb-1">
                                <input type="checkbox" class="custom-control-input" id="notify-beneficiary" name="notify-beneficiary" checked="" formControlName="notify">
                                <label class="custom-control-label" for="status">Enabled</label>
                            </div>
                        </div>
                    </div>

                    <div formGroupName="sender">
                        <div class="form-group">
                            <label>Notify Sender</label>
                            <div class="custom-control custom-switch mb-1">
                                <input type="checkbox" class="custom-control-input" id="notify-sender" name="notify-sender" checked="" formControlName="notify">
                                <label class="custom-control-label" for="status">Enabled</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- END Step 1 -->
        </div>
        <!-- END Steps Content -->

        <!-- Steps Navigation -->
        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
                <div class="col-6 text-right">
                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                        <!-- <i class="fa fa-check mr-1"></i> Submit -->
                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                    </button>
                </div>
            </div>
        </div>
        <!-- END Steps Navigation -->
    </form>
    <!-- END Form -->
</div>
</div>
</div>
</div>