import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/error-handler.service';

import { NotifierService } from '../../notifier';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  accessToken: string;
  message = null;
  loading = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private route: ActivatedRoute,
    private notifier: NotifierService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.message = null;
  }

  ngOnInit(): void {}

  login(loginData: { username: string; password: string }): void {
    this.loading = true;
    this.message = null;
    if (!this.loginForm.valid) {
      this.loading = false;
      this.message = 'Please fill in a valid username and password.';
      return;
    }
    // check if username is valid email
    if (
      !loginData.username.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.loading = false;
      this.message = 'Please fill in a valid username and password.';
      //   this.errorHandlerService.handleGeneralError(this.message);
      this.notifier.error(this.message);
      return;
    }
    this.authService
      .getAccessToken()
      .pipe(
        switchMap((result) => {
          this.accessToken = result.data.AccessToken;
          return this.authService.signIn(
            loginData.username,
            loginData.password,
            this.accessToken
          );
        }),
        catchError((error) => {
          console.log('Access token error:', error);
          this.message = error;
          this.loading = false;
          return of(null);
        })
      )
      .subscribe(
        (result) => {
          if (result.status === 'success') {
            // Successful sign-in, navigate to the desired route
            localStorage.setItem('jwtToken', result.data.AccessToken);
            const user = {
              email: result.data.email,
              username: result.data.firstName,
              lastName: result.data.lastName,
              mobileNumber: result.data.mobileNumber,
              userId: result.data.userId
            };
            localStorage.setItem('user', JSON.stringify(user));
            this.authService.loggedIn.next(true);

            let returnUrl = '';

            if (
              !this.route.snapshot.queryParams['returnUrl'] ||
              this.route.snapshot.queryParams['returnUrl'] === '/'
            ) {
              returnUrl = '/dashboard';
            } else {
              returnUrl = this.route.snapshot.queryParams['returnUrl'];
            }

            this.router.navigateByUrl(returnUrl);
          } else {
            // Handle sign-in failure, e.g., show an error message
            console.log('Sign-in failed.');
            this.message = 'Sign-in failed.';
          }

          this.loading = false;
        },
        (error) => {
          console.log('Sign-in error:', error);
          this.message = error;
          this.loading = false;
        }
      );
  }
}
