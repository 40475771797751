import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';

import { environment } from '../../environments/environment';
import { ErrorHandlerService } from '../error-handler.service';
import { NotifierService } from '../notifier';

const apiVersion = 'v1';
const clientId = environment.cognito.Auth.clientId;
const clientSecret = environment.cognito.Auth.clientSecret;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private errorHandlerService: ErrorHandlerService,
    private notifier: NotifierService
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  async signUp(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    mobileNumber: string
  ) {
    try {
      const url = `${environment.wallet.walletGwBaseUrl}/${apiVersion}/registration/users/admin`;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const options = {
        headers: headers,
      };
      const body = {
        firstName,
        lastName,
        email,
        password,
        mobileNumber,
      };
      const result = this.apiService.post(url, body);
      console.log('sign up successful');
    } catch (error) {
      //   this.errorHandlerService.handleHttpError(error);
      this.notifier.httpError(error);
      console.log('error signing up:', error);
    }
  }

  getAccessToken(): Observable<any> {
    const url = `${environment.wallet.walletGwBaseUrl}/${apiVersion}/authentication/oauth2/token/${clientId}/${clientSecret}`;
    return this.apiService.get(url);
  }

  signIn(
    username: string,
    password: string,
    accessToken?: string
  ): Observable<any> {
    try {
      const url = `${environment.wallet.walletGwBaseUrl}/${apiVersion}/authentication/users`;
      const headers = accessToken
        ? new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
          })
        : new HttpHeaders();

      const options = {
        headers: headers,
      };
      return this.http
        .post(url, { userName: username, userPassword: password }, options)
        .pipe(
          tap((result) => {
            if (result['status'] === 'success') {
              this.loggedIn.next(true);
              return result;
            }
          }),
          catchError((error) => {
            console.error('Error signing in:', error);
            //   this.errorHandlerService.handleHttpError(error);
            this.notifier.httpError(error);
            return throwError('Unable to sign in.');
          })
        );
    } catch (error) {
      //   this.errorHandlerService.handleHttpError(error);
      this.notifier.httpError(error);
      console.log('error signing in', error);
    }
  }

  user(): Observable<any> {
    return localStorage.getItem('user')
      ? of(JSON.parse(localStorage.getItem('user')))
      : of(null);
  }

  confirmSignUp(username: string, code: string) {
    try {
      const url = `${environment.wallet.walletGwBaseUrl}/${apiVersion}/registration/users/confirmation/${username}/${code}`;
      const result = this.apiService.get(url);

      console.log('confirm sign up successful', result);
    } catch (error) {
      console.log('error confirming sign up', error);
    }
  }

  /** get authenticat state */
  public isAuthenticated(): Observable<boolean> {
    const jwtHelper = new JwtHelperService();

    const token = localStorage.getItem('jwtToken');

    const isExpired = jwtHelper.isTokenExpired(token);

    this.loggedIn.next(!isExpired);

    return this.loggedIn.asObservable();
  }

  public signOut(): Observable<boolean> {
    const url = `${environment.wallet.walletGwBaseUrl}/${apiVersion}/authentication/users/token/signOut`;
    const body = {
      accessToken: localStorage.getItem('jwtToken'),
    };

    this.apiService.post(url, body);
    this.apiService.logOut();
    return of(true);
  }

  token(): Observable<string> {
    return of(localStorage.getItem('jwtToken'));
  }
}
