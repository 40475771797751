import { Component, ElementRef, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs';
import { NotifierService } from 'src/app/notifier';

import { UserService } from '../user.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.css',
})
export class TransactionsComponent {
  loading = false;

  file: File | null = null;

  @ViewChild('fileCtrl') fileCtrl: ElementRef<HTMLInputElement>;

  constructor(
    private userService: UserService,
    private notifier: NotifierService
  ) {}

  onUpload(): void {
    if (this.file) {
      this.loading = true;

      const formData = new FormData();
      formData.append('file', this.file, this.file.name);

      this.userService
        .uploadTransactions(formData)
        .pipe(
          finalize(() => {
            this.loading = false;
          }),
          take(1)
        )
        .subscribe({
          next: (res) => {
            this.file = null;
            this.fileCtrl.nativeElement.value = '';

            if (res.status === 'success') {
              this.notifier.success(res.status);
            } else {
              this.notifier.error(res.status);
            }
          },
          error: (error: any) => {
            console.error(error);
          },
        });
    }
  }

  // On file Select
  onChange(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      this.file = file;
    }
  }
}
