import {Component, OnInit } from '@angular/core';
import {AlertEntity} from 'src/app/alert/alert-entity';
import {AuthService} from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {WalletApiResponse} from 'src/app/wallet-api/wallet-api-response';
import {UserTontineService} from "../project/user-tontine.service";
import {UserTontineProjectApiResponse} from "../project/user-tontine-project-api-response";


@Component({
  selector: 'app-tontine-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {
  projectDetails;
  payers = [];
  beneficiaries = [];
  numberOfPayers;
  numberOfBeneficiaries;
  currentToReceive;
  nextToReceived;
  alert: AlertEntity;
  inProgress: boolean;
  loading: boolean;
  project: object;

  constructor(
    private authService: AuthService,
    private userTontineService: UserTontineService,
    private route: ActivatedRoute,
  ) {
    this.projectDetails = {
      name: "",
      amount: "",
      frequencyOfPayment: "",
      currency: "",
      startAt: "",
      endAt: "",
      status: "",
      asAPayer: "",
      retentionRate: "",
      createdAt: "",
      updatedAt: "",
      isInRemediation: false,
      type: "",
      projectId: ""
    };


    this.alert = {
      display: false,
      message: ''
    };


    this.inProgress = false;
  }

  ngOnInit(): void {
    this.loading = true;

    if(window.One != undefined){
      window.One.loader('show')
    }
    

    this.authService.token().subscribe(() => {
      this.userTontineService.fetch(
        this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<UserTontineProjectApiResponse>) => {
        this.projectDetails = response.data.project;
        this.payers = response.data.payers;
        this.beneficiaries = response.data.beneficiaries;
        this.numberOfPayers = response.data.numberOfPayers;
        this.numberOfBeneficiaries = response.data.numberOfPayers;
        this.currentToReceive = response.data.currentToReceive;
        this.nextToReceived = response.data.nextToReceived;
        this.loading = false;
        if(window.One != undefined){
          window.One.loader('hide')
        }
      });
    });
  }

  startTontine() {
    this.alert.display = false;
    this.loading = true;
    this.userTontineService.startTontine(
      this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<UserTontineProjectApiResponse>) => {
        this.projectDetails = response.data.project;
        this.loading = false;
        this.alert.display = true;
        this.alert.message = 'Tontine started successfully';
    });
  }

  startCollection() {
    this.alert.display = false;
    this.loading = true;
    this.userTontineService.startCollection(
      this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<any>) => {
        if (response.status !== "success") {
          this.loading = false;
          this.alert.display = true;
          this.alert.message = response.StatusDescription;
          return;
        }
        this.loading = false;
        this.alert.display = true;
        this.alert.message = 'Collection started successfully';
      });
  }

  startDistribution() {
    this.alert.display = false;
    this.loading = true;
    this.userTontineService.startDistribution(
      this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<any>) => {
        if (response.status !== "success") {
          this.loading = false;
          this.alert.display = true;
          this.alert.message = response.StatusDescription;
          return;
        }
        this.loading = false;
        this.alert.display = true;
        this.alert.message = "Distribution started successfully";
      });
  }

  putTontineInRemediation() {
    this.loading = true;
    this.loading = false;
     this.userTontineService.putTontineInRemediation(
       this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<any>) => {
         const data = response.data;
         this.loading = false;
         this.alert.display = true;
         this.alert.message = 'Tontine in remediation';
       });
  }

  sendNotifications() {
    this.loading = true;
    this.alert.display = false;
    this.userTontineService.sendNotifications(
      this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<any>) => {
        if (response.status !== "success") {
          this.loading = false;
          this.alert.display = true;
          this.alert.message = response.StatusDescription;
          return;
        }
        this.loading = false;
        this.alert.display = true;
        this.alert.message = "Notifications sent successfully";
    });
  }

  cancelTontine() {
    this.loading = true;
    this.userTontineService.cancelTontine(
      this.route.snapshot.paramMap.get('projectId')).subscribe((response: WalletApiResponse<UserTontineProjectApiResponse>) => {
        if (response.status !== "success") {
          this.loading = false;
          this.alert.display = true;
          this.alert.message = response.StatusDescription;
          return;
        }
        this.projectDetails = response.data.project;
        this.loading = false;
        this.alert.message = 'Tontine cancelled successfully';
        this.alert.display = true;
    });
  }

  refresh() {
    this.ngOnInit()
  }

}
