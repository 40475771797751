import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { NotifierService } from 'src/app/notifier';
import { Task } from 'src/app/support-cases/models';
import { SupportCasesService } from 'src/app/support-cases/services';

@Component({
  selector: 'app-support-cases',
  templateUrl: './support-cases.component.html',
  styleUrl: './support-cases.component.css',
})
export class SupportCasesComponent {
  userId = '';
  loading = false;
  tasks: Task[] = [];

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    tag_name: new FormControl('', [Validators.required]),
    wallet_customer_id: new FormControl(''),
  });

  constructor(
    private route: ActivatedRoute,
    private supportCasesService: SupportCasesService,
    private notifier: NotifierService
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
  }

  ngOnInit(): void {
    this.loading = true;
    this.supportCasesService
      .fetchList(this.userId)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.tasks = res.tasks.tasks;
          }
        },
        complete: () => (this.loading = false),
      });
  }

  onSave(): void {
    if (this.form.invalid) {
      throw 'Form is invalid!';
    }

    this.form.controls.wallet_customer_id.setValue(this.userId);

    this.supportCasesService
      .addTicket(this.form.value)
      .pipe(
        switchMap((res) =>
          res.status === 'success'
            ? this.supportCasesService.fetchList(this.userId)
            : of([])
        ),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (Array.isArray(res)) {
            return;
          }

          if (res.status === 'success') {
            this.tasks = res.tasks.tasks;
            this.notifier.success(res.status);
          }
        },
      });
  }
}
