import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListAccountComponent } from './list-account/list-account.component';
import { TopupAccountComponent } from './topup-account/topup-account.component';
import {RouterModule } from '@angular/router';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { TransactionsAccountComponent } from './transactions-account/transactions-account.component';
import { CreateUserAccountComponent } from './create-user-account/create-user-account.component';
import { CreateTransferComponent } from './create-transfer/create-transfer.component';
import { TransfersComponent } from './transfers/transfers.component';
import { BankCardCashOutComponent } from './cash-out/bank-card-cash-out/bank-card-cash-out.component';
import { BankAccountCashOutComponent } from './cash-out/bank-account-cash-out/bank-account-cash-out.component';
import { MtnComponent } from './topup-account/mtn/mtn.component';
import { MtnCashOutComponent } from './cash-out/mtn/mtn-cash-out/mtn-cash-out.component';
import { PaypalCashInComponent } from './topup-account/paypal/paypal-cash-in/paypal-cash-in.component';
import { TransactionFeesComponent } from './transaction-fees/transaction-fees.component';

@NgModule({
  declarations: [
    ListAccountComponent,
    TopupAccountComponent,
    EditAccountComponent,
    TransactionsAccountComponent,
    CreateUserAccountComponent,
    CreateTransferComponent,
    TransfersComponent,
    BankCardCashOutComponent,
    BankAccountCashOutComponent,
    MtnComponent,
    MtnCashOutComponent,
    PaypalCashInComponent,
    TransactionFeesComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class AccountModule { }
