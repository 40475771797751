import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-user-beneficiary',
  templateUrl: './delete-user-beneficiary.component.html',
  styleUrls: ['./delete-user-beneficiary.component.css']
})
export class DeleteUserBeneficiaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
