<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Add New Fee
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">

    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/fees','list']">Fee List</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add New Fee
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-md-6">
                <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p class="mb-0"> {{ alert.message }} </p>
                </div>
                <div class="js-wizard-simple block">
                    <!-- Form -->
                    <form [formGroup]="addNewFeeForm" novalidate (ngSubmit)="addNewFee()">
                        <div class="block-content block-content-full tab-content px-md-5">
                            <div class="form-group">
                                <label for="name">Payment Mean</label>
                                <select class="form-control" formControlName="paymentMean" id="paymentMean"
                                    name="paymentMean">
                                    <option></option>
                                    <!-- Required for data-placeholder attribute to work with Select2 plugin -->
                                    <option value="Stripe">Stripe</option>
                                    <option value="Mtn">MTN</option>
                                    <option value="OrangeMoney">OrangeMoney</option>
                                    <option value="Nbk">NBK</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="kycRegions">
                                    Regions
                                    <a target="_blank" [routerLink]="['/compliance','kyc', 'regions', 'add', 'new']">
                                        [ <i class="nav-main-link-icon fas fa-plus"></i> Create a new region]</a>
                                    [ <a (click)="refresh()" href="javascript:;"><i
                                            class="nav-main-link-icon fas fa-sync-alt"></i>Refresh</a>]
                                    <i *ngIf="loadingRegions" class="nav-main-link-icon fa fa-sun fa-spin">
                                    </i>
                                </label>

                                <select class="form-control" formControlName="regions" id="regions" name="regions" multiple>
                                    <option></option>
                                    <option *ngFor="let region of regions" value="{{region.regionId}}">{{region.name}}
                                    </option>
                                </select>
                            </div>

                            <div formGroupName="fees">
                                <div class="form-group">
                                    <label for="name">Flat Rate</label>
                                    <input class="form-control" type="text" id="flatRate" name="flatRate" formControlName="flatRate">
                                </div>
    
                                <div class="form-group">
                                    <label for="name">Rate</label>
                                    <input class="form-control" type="text" id="rate" name="rate" formControlName="rate">
                                </div>
                            </div>
                        </div>



                        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                            <div class="row">
                                <div class="col-6 text-right">
                                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                                        <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- END Steps Navigation -->
                    </form>
                    <!-- END Form -->
                </div>
            </div>
        </div>
    </div>