import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ApiService} from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class SigningService {
  constructor(
    private apiService: ApiService
  ) {
  }

  createAndPublish(userId: string): Observable<any> {
    return this.apiService.post(
      environment.wallet.signingServiceBaseUrl + '/api/ignisign/admin/signature-requests/' + userId,
      {}
    );
  }
}
