export const environment = {
   production: 'eu-west-3',
   cognito: {
    Auth: {
      clientId: '25c3l164v0n0cl5ijh1tsg51gv',
      clientSecret: '1sfi8jkspvv5u2fbrdng8j221idjsjupmdl8fod5ahbetqfev953',
    },
    region: 'eu-west-3',
    userPoolId: 'eu-west-3_2U8S6Y6Ez',
    userPoolWebClientId: '25c3l164v0n0cl5ijh1tsg51gv',
    walletPlanBaseUrl: 'https://wallet-plans.dev.nbk-cg.com'
   },
   wallet: {
    walletGwBaseUrl: 'https://wallet-gw.dev.nbk-cg.com',
    mobileServiceBaseUrl: 'https://mobile-payment-gw.dev.nbk-cg.com',
    planBaseUrl: 'https://wallet-plans.dev.nbk-cg.com',
    paymentServiceBaseUrl: 'https://card-payment-gw.dev.nbk-cg.com',
    complianceServiceBaseUrl: 'https://wallet-compliance.dev.nbk-cg.com',
    fundRaisingServiceBaseUrl: 'https://fund-raising-gw.dev.nbk-cg.com',
    fundRaisingGatewayServiceBaseUrl: 'https://fund-raising-gateway.dev.nbk-cg.com',
    bassServiceBaseUrl: 'https://bass-gw.dev.nbk-cg.com',
    signingServiceBaseUrl: 'https://wallet-signing.dev.nbk-cg.com',
    analyticsServiceBaseUrl: 'https://wallet-analytics-ng.dev.nbk-cg.com',
    walletEventsBaseUrl: 'https://wallet-events.dev.nbk-cg.com',
    tontineRemittanceServiceBaseUrl: 'https://tontine-remediation.dev.nbk-cg.com',
    nbkCrmBaseUrl: 'https://nbk-crm.dev.nbk-cg.com',
  }
};
