import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    catchError,
    concat,
    debounceTime,
    distinctUntilChanged,
    filter,
    interval,
    map,
    Observable,
    of,
    startWith,
    Subject,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

import { Message, WalletEvent, WalletEventParams } from '../../models';
import { UserService } from '../user.service';

@Component({
  selector: 'app-wallet-events',
  templateUrl: './wallet-events.component.html',
  styleUrl: './wallet-events.component.css',
})
export class WalletEventsComponent implements OnInit, OnDestroy {
  userId = '';
  loading = false;
  pageSize = 10;
  currentPage = 1;
  totalItems = 0;
  totalPages: any[] = [];

  description: { id: string; name: string };
  fromTimestamp = '';
  toTimestamp = '';

  walletEvents: WalletEvent[] = [];
  selectedData: any;
  descriptions: Message[] = [];

  isLiveTrailActive = false;

  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  messagesLoading = false;

  private subject$ = new Subject<void>();
  messageInput$ = new Subject<string>();
  messages$: Observable<{ id: string; name: string }[]>;

  constructor(private route: ActivatedRoute, private userService: UserService) {
    this.userId = this.route.snapshot.paramMap.get('userId');
  }

  ngOnInit(): void {
    this.getWalletEvents();

    this.loadMessages();
  }

  ngOnDestroy(): void {
    this.subject$.next();
    this.subject$.complete();
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.getWalletEvents();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  getWalletEvents(): void {
    const data: Partial<WalletEventParams> = {
      entityId: this.userId,
      page: this.currentPage,
      limit: this.pageSize,
    };

    if (this.description?.name) {
      data.description = this.description.name;
    }

    if (this.fromTimestamp) {
      const dateTime = this.fromTimestamp.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);
      data.fromTimestamp = (date.getTime() / 1000).toString();
    }

    if (this.toTimestamp) {
      const dateTime = this.toTimestamp.split(' ');
      const finalValue = `${dateTime[0]}T${dateTime[1]}:00`;
      const date = new Date(finalValue);

      data.toTimestamp = (date.getTime() / 1000).toString();
    }

    this.userService
      .fetchWalletEvents(data)
      .pipe(
        map((res) => {
          if (res.status !== 'success') {
            throw new Error();
          }

          this.currentPage = res.data.page;
          this.totalItems = res.data.total;
          this.pageSize = res.data.limit;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );

          return res.data.WalletEvents;
        }),
        takeUntil(this.subject$),
        catchError(() => of([] as WalletEvent[]))
      )
      .subscribe({
        next: (res) => {
          this.walletEvents = res;

          this.loading = false;
        },
        error: (e) => console.error(e),
      });
  }

  toggleLiveWalletEvents(): void {
    this.isLiveTrailActive = !this.isLiveTrailActive;

    if (!this.isLiveTrailActive) {
      this.subject$.next();
      this.subject$.complete();

      return;
    }

    const data: Partial<WalletEventParams> = {
      entityId: this.userId,
      page: 1,
      limit: this.pageSize,
    };

    if (this.description?.name) {
      data.description = this.description.name;
    }

    interval(2_000)
      .pipe(
        startWith(0),
        switchMap(() => this.userService.fetchWalletEvents(data)),
        map((res) => {
          if (res.status !== 'success') {
            throw new Error();
          }

          this.currentPage = res.data.page;
          this.totalItems = res.data.total;
          this.pageSize = res.data.limit;
          this.totalPages = new Array(
            Math.ceil(this.totalItems / this.pageSize)
          );

          return res.data.WalletEvents;
        }),
        takeUntil(this.subject$),
        catchError(() => of([] as WalletEvent[]))
      )
      .subscribe({
        next: (res) => {
          this.walletEvents = res;

          this.loading = false;
        },
        error: (e) => console.error(e),
      });
  }

  private loadMessages() {
    this.messages$ = concat(
      of([]), // default items
      this.messageInput$.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(Boolean),
        tap(() => (this.messagesLoading = true)),
        switchMap((term) =>
          this.userService.fetchWalletEventsDescription(term).pipe(
            map((res) => {
              if (res.status === 'success') {
                return res.data.message.map((m) => ({
                  id: m.description,
                  name: m.description,
                }));
              } else {
                return [];
              }
            }),
            catchError(() => of([])), // empty list on error
            tap(() => (this.messagesLoading = false))
          )
        )
      )
    );
  }

  onSelect(): void {
    this.getWalletEvents();
  }
}
