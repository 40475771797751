<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
        User Payment Means
        </h1>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center" data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
        <!-- END Toggle Navigation -->
        <div class="col-xl-6">
            <div class="block">
                <div class="block-content">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb push">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/users']">User List</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/users',paymentMean.userId]">User</a>
                            </li>
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/users',paymentMean.userId, 'payment-means']">Payout Means</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Edit Payout Mean
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    <!-- Navigation -->
    <div id="horizontal-navigation-hover-normal" class="d-none d-lg-block mt-2 mt-lg-0">
    <ul class="nav-main nav-main-horizontal nav-main-hover">
    </ul>
    </div>
    <!-- END Navigation -->