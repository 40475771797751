import {Component, OnInit} from '@angular/core';
import {PlanService} from '../plan.service';
import {WalletApiResponse} from '../../wallet-api/wallet-api-response';
import {PlanApiResponse} from './plan-api-response';
import {PlanEntity} from '../plan-entity';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { TransactionAmountKycRuleService } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule.service';
import { TransactionAmountKycRuleEntity } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-entity';
import { TransactionAmountKycRuleCollection } from 'src/app/compliance-api/kyc/rules/transactions/amount/transaction-amount-kyc-rule-collection';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

  plan: PlanEntity;
  kycRules: Array<TransactionAmountKycRuleEntity>

  constructor(
    private planService: PlanService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private transactionAmountKycRuleService: TransactionAmountKycRuleService
  ) {
    this.plan = {
      planId: '',
      status: '',
      name: '',
      currency: '',
      compliance: {}
    };
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.planService.fetch(this.route.snapshot.paramMap.get('planId')).subscribe((response: WalletApiResponse<PlanApiResponse>) => {
        this.plan = response.data.walletPlan;
      });
    });

    this.authService.token().subscribe(() => {
      this.transactionAmountKycRuleService.fetchAll().subscribe((response: WalletApiResponse<TransactionAmountKycRuleCollection>) => {
        this.kycRules = response.data.Rules;
      });
    });

  }

}
