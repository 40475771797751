import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

import { CommentApiResponse, SupportCaseApiResponse, Task } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SupportCasesService {
  constructor(private apiService: ApiService) {}

  fetchList(customerId?: string): Observable<SupportCaseApiResponse> {
    let params = new HttpParams();

    if (customerId) {
      params = params.set('wallet_customer_id', customerId);
    }

    return this.apiService.get(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/default/lists/tasks`,
      params
    );
  }

  fetchTask(taskId: string): Observable<{ status: string; task: Task }> {
    return this.apiService.get(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/tasks/${taskId}`
    );
  }

  addComment(
    taskId: string,
    data: { comment_text: string }
  ): Observable<CommentApiResponse> {
    return this.apiService.post(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/task/${taskId}/comments`,
      data
    );
  }

  updateTicket(
    taskId: string,
    data: any
  ): Observable<CommentApiResponse> {
    return this.apiService.put(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/tasks/${taskId}`,
      data
    );
  }

  addTicket(data: any): Observable<any> {
    return this.apiService.post(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/tasks/default/lists`,
      data
    );
  }

  addAttachment(taskId: string, data: FormData): Observable<any> {
    return this.apiService.post(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/task/${taskId}/attachment`,
      data
    );
  }
}
