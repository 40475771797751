import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import {ListComponent} from './list/list.component';
import {AuthGuard} from '../auth/auth.guard';
import {DetailComponent} from './detail/detail.component';
import { CreateComponent } from './create/create.component';

const routes: Routes = [
  {
    path: 'plans',
    component: ListComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'plans/:planId',
    component: DetailComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'plans/add/new',
    component: CreateComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PlansRoutingModule { }
