import { Directive, ElementRef, inject, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';

type EventArg = { target: { value: string } };

@Directive({
  selector: 'input[appSearch]',
})
export class SearchDirective {
  private readonly elementRef = inject(ElementRef);

  @Input()
  debounceTime = 1000;

  @Input()
  minLength = 2;

  @Output()
  search = fromEvent<EventArg>(this.elementRef.nativeElement, 'input').pipe(
    debounceTime(this.debounceTime),
    map((eventArg: EventArg) => eventArg.target.value),
    distinctUntilChanged()
  );
}
