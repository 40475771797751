import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { CashInEntity } from 'src/app/payment-api/cash-in-entity';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import { TransactionAmountKycRuleDocumentCashInEntity } from './transaction-amount-kyc-rule-document-cash-in-entity';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionAmountKycRuleDocumentService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getDocuments(
    cashInEntity: TransactionAmountKycRuleDocumentCashInEntity
    ): Observable<any> {
    return this.apiService.post(
      environment.wallet.complianceServiceBaseUrl + '/v1/compliance/kyc/rules/transactions/amount/cash-in/documents',
      cashInEntity
    )
  }
}
