<main class="content">
    <div class="container p-0">

        <h1 class="h3 mb-3">Messages</h1>
        <!-- back button  -->

        <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group mx-2">
                <button class="btn btn-sm btn-outline-secondary"
                        (click)="btnBackClicked()">Back to Channels</button>
            </div>
            <div class="btn-group mx-2">
                <button class="btn btn-sm btn-outline-secondary"
                        (click)="closeChannel()">Close Channel</button>
            </div>
            <div class="btn-group mx-2">
                <button class="btn btn-sm btn-outline-secondary"
                        (click)="updateStatus()">Open Channel</button>
            </div>
            <div class="btn-group mx-2">
                <button class="btn btn-sm btn-outline-secondary"
                        (click)="updateChannelRead()">Read Channel</button>
            </div>
            <div class="btn-group me-2">
                <button class="btn btn-sm btn-outline-secondary"
                        (click)="fetchAll()">Refresh</button>
            </div>
            <div class="btn-group mx-2">
                <button class="btn btn-sm btn-outline-secondary"
                        (click)="toggleLiveChat()">
                    {{ isLiveChatActive ? 'Stop' : 'Start' }} Live Chat
                </button>
            </div>
        </div>
        <!-- error message  -->

        <div class="alert alert-danger"
             role="alert"
             *ngIf="message != ''">
            {{message}}
        </div>
        <div class="card">
            <div class="row g-0">
                <div class="col-12 col-lg-7 col-xl-9">
                    <div class="py-2 px-4 border-bottom d-none d-lg-block">
                        <div class="d-flex align-items-center py-1">
                            <div class="flex-grow-1 pl-3">
                                <span>Channel ID: <span class="name">{{channelId}}</span></span>
                                <br>
                                <span *ngIf="channelEmail">Email: <span class="name">{{channelEmail}}</span></span>
                                <br>
                                <span *ngIf="channelSubject">Subject: <span class="name">{{channelSubject}}</span></span>
                            </div>
                            <div>
                                <button class="btn btn-light border btn-lg px-3"><svg xmlns="http://www.w3.org/2000/svg"
                                         width="24"
                                         height="24"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         stroke="currentColor"
                                         stroke-width="2"
                                         stroke-linecap="round"
                                         stroke-linejoin="round"
                                         class="feather feather-more-horizontal feather-lg">
                                        <circle cx="12"
                                                cy="12"
                                                r="1"></circle>
                                        <circle cx="19"
                                                cy="12"
                                                r="1"></circle>
                                        <circle cx="5"
                                                cy="12"
                                                r="1"></circle>
                                    </svg></button>
                            </div>
                        </div>
                    </div>

                    <div class="position-relative">
                        <div class="chat-messages p-4">
                            <div *ngIf="loading">
                                <div class="spinner-border"
                                     style="width: 3rem; height: 3rem;"
                                     role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow"
                                     style="width: 3rem; height: 3rem;"
                                     role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!Messages && !loading"> No messages available</div>
                            <div *ngFor="let data of Messages">
                                <div class="chat-message-left pb-4"
                                     *ngIf="data.author !== user.username">

                                    <div *ngIf="data.author !== user.username">
                                        <div class="text-muted small text-nowrap mt-2">{{ convertToTime(data.createdAt)
                                            }}</div>
                                    </div>
                                    <div class="flex-shrink-1 bg-light rounded py-2 px-3 ml-3"
                                         *ngIf="data.author !== user.username">
                                        <div class="font-weight-bold mb-1">
                                            Author: {{ data.author === channelUserId ? channelEmail : data.author }}
                                        </div>
                                        {{ data.body }}
                                    </div>
                                </div>

                                <div class="chat-message-right pb-4"
                                     *ngIf="data.author === user.username">
                                    <div>
                                        <img src="../assets/nbk_logo.jpg"
                                             class="rounded-circle mr-1"
                                             alt="Chris Wood"
                                             width="40"
                                             height="40">
                                        <div class="text-muted small text-nowrap mt-2">{{ convertToTime(data.createdAt)
                                            }}</div>
                                    </div>
                                    <div class="flex-shrink-1 bg-light rounded py-2 px-3 mr-3"
                                         *ngIf="data.author === user.username">
                                        <div class="font-weight-bold mb-1">Author: {{ data.author }}</div>
                                        {{ data.body }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex-grow-0 py-3 px-4 border-top">
                        <div class="input-group">
                            <textarea [(ngModel)]="inputMessage"
                                      class="form-control"
                                      rows="3"
                                      placeholder="Type your message here..."></textarea>
                            <button class="btn btn-primary"
                                    (click)="sendMessage()">
                                <i class="fa fa-paper-plane"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>