<div class="row items-push px-4">
    <h3 class="flex-sm-fill h3 my-2">Transactions</h3>
</div>

<div class="row items-push">
    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="Volume of Transactions"
                  [count]="transactionsVolume$ | async" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="Number of Transactions"
                  [count]="transactions$ | async" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="CashIn Transactions"
                  [count]="cashInTransactions$ | async" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <app-card title="CashOut Transactions"
                  [count]="cashOutTransactions$ | async" />
    </div>

</div>

<div class="row items-push">
    <div class="col-sm-6 col-md-3 col-xxl-3"
         #col>
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #tvp />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #tvf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="transactionsVolumePeriods.length"
                   [data]="transactionsVolumePeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="tvfEl.nativeElement.value" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #tp />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #tf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="transactionsPeriods.length"
                   [data]="transactionsPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="tfEl.nativeElement.value" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #citp />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #citf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>

        <app-chart *ngIf="cashInTransactionsPeriods.length"
                   [data]="cashInTransactionsPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="citfEl.nativeElement.value" />
    </div>

    <div class="col-sm-6 col-md-3 col-xxl-3">
        <div class="row px-3">
            <div class="form-group mr-2"
                 style="width: 80px;">
                <input type="number"
                       appPeriod
                       class="form-control form-control-xs"
                       value="12"
                       placeholder="Period"
                       #cotp />
            </div>

            <select class="custom-select"
                    style="width: auto;"
                    #cotf>
                <option value="M">M</option>
                <option value="W">W</option>
                <option value="D">D</option>
            </select>
        </div>
        <app-chart *ngIf="cashOutTransactionsPeriods.length"
                   [data]="cashOutTransactionsPeriods"
                   [cWidth]="colEl.nativeElement.clientWidth"
                   [frequency]="cotfEl.nativeElement.value" />
    </div>

</div>