import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize, take } from 'rxjs';

import { Comment } from '../models';
import { UserService } from '../user.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrl: './comments.component.css',
})
export class CommentsComponent implements OnInit {
  userId = '';
  firstName = '';
  lastName = '';
  loading = false;
  comments: Comment[] = [];

  comment = new FormControl('', [Validators.required]);

  constructor(private route: ActivatedRoute, private userService: UserService) {
    this.userId = this.route.snapshot.paramMap.get('userId');
  }

  ngOnInit(): void {
    this.loading = true;

    this.getUserComments();
  }

  convertToTime(createdAt: string): string {
    const date = new Date(+createdAt * 1000);
    return date.toLocaleString();
  }

  getUserComments(): void {
    this.userService
      .fetchComments(this.userId)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (res) => {
          if (res.status !== 'success') {
            return;
          }

          this.comments = res.comments;
        },
      });
  }

  postComment(event: Event): void {
    event.stopPropagation();

    if (this.comment.invalid) {
      return;
    }

    const { userId, username, lastName } = JSON.parse(
      localStorage.getItem('user')
    );

    const comment: Comment = {
      userId: this.userId,
      content: this.comment.value,
      author: {
        firstName: username,
        lastName: lastName,
        userId: userId,
      },
    };

    this.userService
      .postComment(comment)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            // show success message
            this.comments.push(res.comment);
            this.comment.reset();
          }
        },
      });
  }
}
