import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconComponent } from './icon.component';
import { LoaderComponent } from './loader.component';
import { AsComponentPipe } from './pipes/as-component.pipe';
import { IsStringPipe } from './pipes/is-string.pipe';
import { ToastFilterPipe } from './pipes/toast-filter.pipe';
import { ToastComponent } from './toast.component';
import { NgxSonnerToaster } from './toaster.component';

const ARR = [
  AsComponentPipe,
  IsStringPipe,
  ToastFilterPipe,
  IconComponent,
  LoaderComponent,
  ToastComponent,
  NgxSonnerToaster,
];

@NgModule({
  declarations: [...ARR],
  imports: [CommonModule],
  exports: [...ARR],
})
export class NotifierModule {}
