<div class="bg-body-light">
  <div class="content content-full">
    <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
      <h1 class="flex-sm-fill h3 my-2">
        Wallet Plan
      </h1>
    </div>
  </div>
</div>


<div class="col-xl-6">
  <div class="block">
    <div class="block-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb push">
          <li class="breadcrumb-item">
            <a [routerLink]="['/plans']">Plan List</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Plan
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>

<div class="bg-white p-3 push">
  <!-- Toggle Navigation -->
  <div class="d-lg-none">
    <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
    <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center"
      data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
      Menu - Hover Centered
      <i class="fa fa-bars"></i>
    </button>
  </div>
  <!-- END Toggle Navigation -->

</div>
<div class="content">
  <div class="row">
    <div class="col-md-6">
      <form action="" method="POST">
        <div class="block">
          <div class="block-header block-header-default">
            <h3 class="block-title">{{ plan.name }}</h3>
            <div class="block-options">
              <button type="submit" class="btn btn-sm btn-primary">
                Submit
              </button>
            </div>
          </div>
          <div class="block-content">
            <h2 class="content-heading border-bottom mb-4 pb-2">General Settings</h2>
            <div class="row justify-content-center py-sm-3 py-md-5">
              <div class="col-sm-10 col-md-8">
                <div class="form-group">
                  <label for="block-form1-name">Name</label>
                  <input type="text" class="form-control form-control-alt" id="block-form1-name" name="block-form1-name"
                    placeholder="{{ plan.name }}">
                </div>
                <div class="form-group">
                  <label for="block-form1-currency">Currency</label>
                  <input type="text" class="form-control form-control-alt" id="block-form1-currency"
                    name="block-form1-currency" placeholder="{{ plan.currency }}">
                </div>
                <div>
                  <div class="form-group">
                    <label for="complianceRuleId">KYC Rules</label>
                    <select class="form-control" id="complianceRuleId"
                      name="complianceRuleId" multiple>
                      <option value="0">Please select</option>
                      <option *ngFor="let rule of kycRules" value="{{ rule.ruleId }}">
                        {{ rule.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>