import { Component, inject, OnInit, signal } from '@angular/core';

import { CardComponent } from '../../components/card/card.component';
import { TransactionsComponent } from '../../components/transactions/transactions.component';
import { UsersComponent } from '../../components/users/users.component';
import { DashboardService } from '../../services';

@Component({
  selector: 'app-main-page',
  standalone: true,
  imports: [CardComponent, UsersComponent, TransactionsComponent],
  templateUrl: './main-page.component.html',
  styleUrl: './main-page.component.css',
})
export class MainPageComponent implements OnInit {
  readonly #dashboardService = inject(DashboardService);

  kycCount = signal<number>(0);
  subscriptionsCount = signal<number>(0);
  signaturesCount = signal<number>(0);
  transactionsCount = signal<number>(0);

  ngOnInit(): void {
    this.#dashboardService.fetchPendingKYCCount().subscribe({
      next: (res) => {
        this.kycCount.set(res.data.count);
      },
      error: console.error,
    });

    this.#dashboardService.fetchPendingSignaturesCount().subscribe({
      next: (res) => {
        this.signaturesCount.set(res.data.count);
      },
      error: console.error,
    });

    this.#dashboardService.fetchPendingSubscriptionsCount().subscribe({
      next: (res) => {
        this.subscriptionsCount.set(res.data.count);
      },
      error: console.error,
    });

    this.#dashboardService.fetchTransactionVolumeCount().subscribe({
      next: (res) => {
        if (res.data.count) {
          this.transactionsCount.set(res.data.count);
        }
      },
      error: console.error,
    });
  }
}
