import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/api.service';


@Injectable({
  providedIn: 'root'
})
export class OrganizationAccountTransactionService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetchAll(
    accountId: string
    ): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/organizations/accounts/' + accountId + '/transactions');
  }
}
