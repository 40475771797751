import { AsyncPipe, NgIf } from '@angular/common';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    fromEvent,
    map,
    of,
    startWith,
    Subject,
    switchMap,
    takeUntil,
} from 'rxjs';

import { PeriodDirective } from '../../directives/period.directive';
import { DashboardService } from '../../services';
import { convertTransactions } from '../../services/convert-data';
import { CardComponent } from '../card/card.component';
import { ChartComponent } from '../chart/chart.component';

@Component({
  selector: 'app-transactions',
  standalone: true,
  imports: [CardComponent, ChartComponent, PeriodDirective, AsyncPipe, NgIf],
  templateUrl: './transactions.component.html',
  styleUrl: './transactions.component.css',
})
export class TransactionsComponent {
  private readonly dashboardService = inject(DashboardService);

  @ViewChild('col') colEl!: ElementRef<HTMLDivElement>;

  @ViewChild('tvp') tvpEl!: ElementRef<HTMLInputElement>;
  @ViewChild('tvf') tvfEl!: ElementRef<HTMLSelectElement>;

  @ViewChild('tp') tpEl!: ElementRef<HTMLInputElement>;
  @ViewChild('tf') tfEl!: ElementRef<HTMLSelectElement>;

  @ViewChild('citp') citpEl!: ElementRef<HTMLInputElement>;
  @ViewChild('citf') citfEl!: ElementRef<HTMLSelectElement>;

  @ViewChild('cotp') cotpEl!: ElementRef<HTMLInputElement>;
  @ViewChild('cotf') cotfEl!: ElementRef<HTMLSelectElement>;

  // events
  tvp$;
  tvf$;

  tp$;
  tf$;

  citp$;
  citf$;

  cotp$;
  cotf$;

  transactionsVolume$ = this.dashboardService
    .fetchTransactionVolumeCount()
    .pipe(
      map((res) => res.data.count || 0),
      catchError(() => of(0))
    );

  transactionsVolumePeriods: { date: Date; value: number }[] = [];

  transactions$ = this.dashboardService.getTransactionCount().pipe(
    map((res) => res.data.count || 0),
    catchError(() => of(0))
  );

  transactionsPeriods: { date: Date; value: number }[] = [];

  cashInTransactions$ = this.dashboardService
    .fetchTransactionVolumeCount('IN')
    .pipe(
      map((res) => res.data.count || 0),
      catchError(() => of(0))
    );

  cashInTransactionsPeriods: { date: Date; value: number }[] = [];

  cashOutTransactions$ = this.dashboardService
    .fetchTransactionVolumeCount('OUT')
    .pipe(
      map((res) => res.data.count || 0),
      catchError(() => of(0))
    );

  cashOutTransactionsPeriods: { date: Date; value: number }[] = [];

  private destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.tvpSearch();

    this.tpSearch();

    this.citpSearch();

    this.cotpSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private tvpSearch(): void {
    this.tvp$ = fromEvent(this.tvpEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.tvf$ = fromEvent(this.tvfEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.tvp$, this.tvf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getTransactionVolumePerPeriod({
            ...data,
            transaction_type: 'ANY',
          });
        }),
        map(convertTransactions),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.transactionsVolumePeriods = res;
        },
      });
  }

  private tpSearch(): void {
    this.tp$ = fromEvent(this.tpEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.tf$ = fromEvent(this.tfEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.tp$, this.tf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getTransactionCountPerPeriod({
            ...data,
            transaction_type: 'ANY',
          });
        }),
        map(convertTransactions),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.transactionsPeriods = res;
        },
      });
  }

  private citpSearch(): void {
    this.citp$ = fromEvent(this.citpEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.citf$ = fromEvent(this.citfEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.citp$, this.citf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getTransactionVolumePerPeriod({
            ...data,
            transaction_type: 'IN',
          });
        }),
        map(convertTransactions),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.cashInTransactionsPeriods = res;
        },
      });
  }

  private cotpSearch(): void {
    this.cotp$ = fromEvent(this.cotpEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      distinctUntilChanged(),
      debounceTime(250),
      startWith(12)
    );

    this.cotf$ = fromEvent(this.cotfEl.nativeElement, 'input').pipe(
      map((e) => (e.target as any).value),
      startWith('M')
    );

    combineLatest(this.cotp$, this.cotf$)
      .pipe(
        map(([periods, frequency]) => ({ frequency, periods })),
        switchMap((data) => {
          return this.dashboardService.getTransactionVolumePerPeriod({
            ...data,
            transaction_type: 'OUT',
          });
        }),
        map(convertTransactions),
        catchError(() => of([{ date: new Date(), value: 0 }])),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.cashOutTransactionsPeriods = res;
        },
      });
  }
}
