import {Injectable} from '@angular/core';
import { ApiService } from 'src/app/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {PlanEntity} from './plan-entity';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetch(planId: string): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/plans/' + planId);
  }

  fetchAll(): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/plans');
  }

  create(walletPlan: PlanEntity): Observable<any> {
    walletPlan.status = walletPlan.status ? 'active' : 'deactivated'
    return this.apiService.post(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/plans',
      walletPlan
    )
  }

}
