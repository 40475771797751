<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Organization Accounts
        </h1>
      </div>
    </div>
  </div>

  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item active" aria-current="page">
                        Account List
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
            <div class="block-header">
                <h3 class="block-title"></h3>
                <div class="block-options">
                    <div class="dropdown">
                        <button type="button" class="btn btn-primary dropdown-toggle" id="dropdown-default-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div class="dropdown-menu font-size-sm" aria-labelledby="dropdown-default-primary" style="">
                            <a [routerLink]="['/organizations', 'accounts', 'add', 'new']" class="dropdown-item">Create New Organization Account</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-content">
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th>Account Name</th>
                                <th>Account Type</th>
                                <th style="width: 30%;">Balance</th>
                                <th style="width: 15%;">Status</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>

                        <div *ngIf="loading">
                            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                              <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                        </div>

                        <tbody *ngIf="!loading">
                            <tr *ngFor= "let account of accounts"> 
                                <td class="font-w600 font-size-sm">
                                    <a href="#">{{ account.name }}</a>
                                </td>
                                <td class="font-w600 font-size-sm">
                                    <a href="#">{{ account.accountType }}</a>
                                </td>
                                <td class="font-size-sm">
                                    <em class="text-muted">{{ account.balance / 100 | currency: 'EUR' }}</em>
                                </td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': account.status == 'active', 'badge-danger': account.status == 'deactivated'}">
                                        {{ account.status == 'active' ? 'Active ': 'Disabled' }}
                                    </span>
                                </td>
                             
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button  [routerLink]="['/organizations','accounts',account.accountId]" type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button   type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="TopUp">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>