import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListWalletPlansComponent } from './list-wallet-plans/list-wallet-plans.component';
import { WalletPlanRoutingModule } from './wallet-plan-routing.module';
import { CreateWalletPlanComponent } from './create-wallet-plan/create-wallet-plan.component';



@NgModule({
  declarations: [ListWalletPlansComponent, CreateWalletPlanComponent],
  imports: [
    CommonModule,
    WalletPlanRoutingModule
  ]
})
export class WalletPlanModule { }
