<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Sponsorships</h1>
        </div>
    </div>
</div>
<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content"
     *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <div class="block-header float-right">
                    <div class="block-options">
                        <button class="btn btn-sm btn-primary"
                                type="button"
                                data-toggle="modal"
                                data-target="#addSponsor">
                            <span class="ml-1">Add new code</span>
                        </button>
                    </div>
                </div>


                <div class="block-content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th style="width: 15%">Sponsor Code</th>
                                    <th style="width: 20%">FirstName</th>
                                    <th style="width: 20%">LastName</th>
                                    <th style="width: 30%">Description</th>
                                    <th style="width: 15%">Created At</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!loading">
                                <tr *ngFor="let sponsor of sponsorships">
                                    <td class="font-w600 font-size-sm">
                                        <em class="text-muted">{{ sponsor.sponsor_code }}</em>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <em class="text-muted">{{ sponsor.author.firstName }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ sponsor.author.lastName }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">{{ sponsor.description }}</em>
                                    </td>
                                    <td class="font-size-sm">
                                        <em class="text-muted">
                                            {{ sponsor.createdAt * 1000 | date: 'yyyy/MM/dd h:mm:ss a' }}
                                        </em>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="addSponsor"
     tabindex="-1"
     role="dialog"
     aria-labelledby="sponsor"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="validateUser">Add New Code</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="form-group">
                        <label for="description">Description</label>
                        <input class="form-control"
                               type="text"
                               id="description"
                               name="description"
                               formControlName="description" />
                    </div>
                    <div class="form-group">
                        <label for="prefix">Prefix</label>
                        <input class="form-control"
                               type="text"
                               id="prefix"
                               name="prefix"
                               formControlName="prefix" />
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="onSave()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm"
                        [disabled]="form.invalid">Save</button>
            </div>
        </div>
    </div>
</div>