import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { ChatService } from '../chats.service';

export interface Message {
  messageId?: string;
  author: string;
  channelId: string;
  body: string;
  walletOrganizations?: object;
  createdAt?: string;
}

type User = {
  username: string;
};

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrl: './chat-window.component.css',
})
export class ChatWindowComponent {
  activetab = 2;
  Messages: Message[];
  channelId: string;
  loading: boolean;
  allChannels: Array<any> = [];
  user: Partial<User> = {
    username: '........',
  };
  inputMessage: string;
  message: string = '';
  isLiveChatActive = false;

  private subject$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private chatService: ChatService,
    private router: Router
  ) {
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.loading = true;
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.user().subscribe((info) => {
      this.user = info;
    });
    this.fetchAll();
    this.updateChannelRead();
    this.loading = false;
  }
  convertToTime(createdAt: string): string {
    const date = new Date(+createdAt * 1000);
    return date.toLocaleString();
  }

  btnBackClicked() {
    this.router.navigate(['chats/list']);
  }

  sendMessage() {
    this.authService.token().subscribe(() => {
      const newMessage = {
        channelId: this.channelId,
        body: this.inputMessage,
        author: this.user.username,
      };

      this.chatService
        .createMessage(newMessage)
        .subscribe((response: WalletApiResponse<Message>) => {
          this.Messages.push(response.data);
          this.inputMessage = '';
        });
    });
  }

  closeChannel() {
    this.message = '';
    this.authService.token().subscribe(() => {
      const data = {
        status: 'CLOSED',
      };
      this.chatService
        .updateChannelStatus(this.channelId, data)
        .subscribe((response: any) => {
          if (response.status !== 'success') {
            this.message = response.statusDescription;
            return;
          }
          this.message = '';
        });
    });
  }

  updateStatus() {
    this.authService.token().subscribe(() => {
      const data = {
        status: 'OPENED',
      };
      this.chatService
        .updateChannelStatus(this.channelId, data)
        .subscribe((response: WalletApiResponse<Message>) => {
          this.Messages.push(response.data);
        });
    });
  }
  updateChannelRead() {
    this.authService.token().subscribe(() => {
      const data = {
        isRead: true,
      };
      this.chatService
        .updateChannelRead(this.channelId, data)
        .subscribe((response: WalletApiResponse<Message>) => {
          this.Messages.push(response.data);
        });
    });
  }
  fetchAll() {
    this.authService.token().subscribe(() => {
      this.chatService
        .getMessagesByChannel(this.channelId)
        .subscribe((response: WalletApiResponse<Message[]>) => {
          this.Messages = response.data;
        });
    });
  }

  toggleLiveChat(): void {
    this.isLiveChatActive = !this.isLiveChatActive;

    if (!this.isLiveChatActive) {
      this.subject$.next();
      this.subject$.complete();

      return;
    }

    interval(2_000)
      .pipe(
        startWith(0),
        switchMap(() => this.authService.token()),
        switchMap(() => this.chatService.getMessagesByChannel(this.channelId)),
        takeUntil(this.subject$)
      )
      .subscribe((response: WalletApiResponse<Message[]>) => {
        this.Messages = response.data;
      });
  }
}
