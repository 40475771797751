import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { toast } from './state';

const duration = 5000;

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  httpError(error: HttpErrorResponse): void {
    toast.error(error.message, { duration });
  }

  error(message: string): void {
    toast.error(message, { duration });
  }

  success(message: string): void {
    toast.success(message, { duration });
  }

  info(message: string): void {
    toast.info(message, { duration });
  }

  warning(message: string): void {
    toast.warning(message, { duration });
  }
}
