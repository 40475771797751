import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetch(): Observable<any> {
    return this.apiService.get(
      environment.wallet.walletGwBaseUrl + '/v1/wallets/organizations');
  }
}
