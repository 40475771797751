import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

import { CollectionsApiResponse } from './collections-api-response.model';

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {
  constructor(private apiService: ApiService) {}

  fetch(queryParams?: HttpParams): Observable<CollectionsApiResponse> {
    return this.apiService.get(
      `${environment.wallet.tontineRemittanceServiceBaseUrl}/api/tontines/remediation/collections/search`,
      queryParams
    );
  }

  fetchBy(collectionId: string): Observable<any> {
    return this.apiService.get(
      `${environment.wallet.tontineRemittanceServiceBaseUrl}/api/tontines/remediation/collections/${collectionId}`
    );
  }
}
