import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DocumentTypeEntityService } from 'src/app/compliance-api/documents/types/document-type-entity.service';

@Pipe({
  name: 'typeName'
})
export class TypeNamePipe implements PipeTransform {

  constructor(
    private documentTypeService: DocumentTypeEntityService,
    private authService: AuthService
  ) { 

  }
  transform(typeId: string): string {
    this.authService.token().subscribe(() => {
      this.documentTypeService.fetch(
        typeId
      ).subscribe((response) => {
        return response.data.DocumentType.name
      });
    });
    return null;
  }

}
