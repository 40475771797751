import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from './auth/auth.service';

declare global {
  interface Window {
    One: any;
  }
}
type User = {
  username: string;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'wallet-control-panel';
  subscription: Subscription;
  isLoggedIn: boolean;
  user: Partial<User> = {
    username: '........',
  };

  constructor(private authService: AuthService, private router: Router) {
    this.isLoggedIn = true;
  }

  ngOnInit(): void {
    this.subscription = this.authService
      .isAuthenticated()
      .subscribe((result) => {
        this.isLoggedIn = result;
      });

    this.authService.user().subscribe((info) => {
      this.user = info;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickLogout() {
    this.authService.signOut().subscribe(() => {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('user');
      window.location.href = '/login';
    });
  }
}
