<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Fees Management
            </h1>
        </div>
    </div>
</div>


<div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item active" aria-current="page">
                        Fee list
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title"></h3>
                    <div class="block-options">
                        <div class="dropdown">
                            <button type="button" class="btn btn-primary dropdown-toggle" id="dropdown-default-primary"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Actions
                            </button>
                            <div class="dropdown-menu font-size-sm" [routerLink]="['/fees','add', 'new']"
                                aria-labelledby="dropdown-default-primary" style="">
                                <a class="dropdown-item" href="javascript:void(0)">Create New Fee</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-content">
                    <p class="font-size-sm text-muted">

                    </p>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-vcenter">
                            <thead>
                                <tr>
                                    <th>Payment mean</th>
                                    <th>Flat Rate</th>
                                    <th>Rate</th>
                                    <th style="width: 15%;">DateTime</th>
                                    <th class="text-center" style="width: 100px;">Actions</th>
                                </tr>
                            </thead>

                            <div *ngIf="loading">
                                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>

                            <tbody *ngIf="!loading">
                                <tr *ngFor="let fee of fees">
                                    <td class="font-w600 font-size-sm">
                                        <a href="#">{{ fee.paymentMean }}</a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a href="#">{{ fee.fees.flatRate }}</a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <a href="#">{{ fee.fees.rate }}</a>
                                    </td>
                                    <td class="font-w600 font-size-sm">
                                        <!-- <a href="#">{{ region.createdAt*1000 | date: 'yyyy/MM/dd h:mm:ss a'}}</a> -->
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-group">
                                            <button type="button" [routerLink]="['/fees', fee.feeId]"
                                                class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                                <i class="fa fa-fw fa-pencil-alt"></i>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip"
                                                title="Delete">
                                                <i class="fa fa-fw fa-times"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>