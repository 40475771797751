import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators, FormArray} from '@angular/forms';
import { BeneficiaryService } from 'src/app/wallet-api/beneficiaries/beneficiary.service';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-create-new-user-beneficiary',
  templateUrl: './create-new-user-beneficiary.component.html',
  styleUrls: ['./create-new-user-beneficiary.component.css']
})
export class CreateNewUserBeneficiaryComponent implements OnInit {

  beneficiaryForm;
  userId: string;
  alert: AlertEntity;
  inProgress: boolean

  constructor(
    private beneficiaryService: BeneficiaryService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { 

    this.alert = {
      display: false,
      message: ''
    };

    this.inProgress = false;
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.beneficiaryForm = this.formBuilder.group({
      beneficiaryType: "INDIVIDUAL",
      beneficiaryDetails: this.formBuilder.group({
        name: "New Beneficiary Name",
        email: "",
        phoneNumber: "",
        address: this.formBuilder.group({
          streetName: "",
          streetNumber: 35,
          city: "",
          postCode: "",
          state: "",
          country: ""
        })
      }),
      beneficiaryAccountDetails: this.formBuilder.group({
        accountType: "WALLET_ACCOUNT",
        accountIdentifiers: new FormArray([])
      }),
      transferNotification : this.formBuilder.group({
        beneficiary : this.formBuilder.group({
          notify: true
        }),
        sender: this.formBuilder.group({
          notify: true
        })
      })
    });
  }

   ngOnInit(): void {

   }

  addAccountIdentifier(){
    this.beneficiaryForm.controls.beneficiaryAccountDetails.controls.accountIdentifiers.push(
      this.formBuilder.group({
       accountIdentifierName: "",
       accountIdentifierValue: ""
      })
    );
  }
  createBeneficiary(){
    this.inProgress = true;
    this.authService.token().subscribe(() => {
      this.beneficiaryService.create(
        this.route.snapshot.paramMap.get('userId'),
        this.beneficiaryForm.value
      ).subscribe((response)=>{
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully created new beneficiary'
      });
    });
  }

}
