import { Component, OnInit } from '@angular/core';
import { WalletPlanModule } from '../wallet-plan.module';
import { WalletPlanComplianceRule } from 'src/app/wallet-api/compliance/wallet-plan/wallet-plan-compliance-rule';

@Component({
  selector: 'app-list-wallet-plans',
  templateUrl: './list-wallet-plans.component.html',
  styleUrls: ['./list-wallet-plans.component.css']
})
export class ListWalletPlansComponent implements OnInit {

  rules: Array<WalletPlanComplianceRule>
  loading: boolean;
  constructor() { 
    this.loading = true;
  }

  ngOnInit(): void {
    this.loading = false;
  }

}
