import { Component, OnInit } from '@angular/core';
import { AccountEntity } from 'src/app/wallet-api/account-entity';
import { AccountsService } from 'src/app/wallet-api/accounts.service';
import { AuthService } from 'src/app/auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AccountCollection } from 'src/app/wallet-api/account-collection';

@Component({
  selector: 'app-list-account',
  templateUrl: './list-account.component.html',
  styleUrls: ['./list-account.component.css']
})
export class ListAccountComponent implements OnInit {

  
  accounts: Array<AccountEntity> = [];
  userId: string;
  loading: boolean;

  constructor(
    private accountService: AccountsService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { 
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
  }

  ngOnInit(): void {
    
    this.authService.token().subscribe(() => {
      this.accountService.fetchAll(this.userId).subscribe((response: WalletApiResponse<AccountCollection>) => {
        this.accounts = response.data.walletAccounts
        this.loading = false;
      })
    })
  }
}
