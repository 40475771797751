import { Component, OnInit } from '@angular/core';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { AuthService } from 'src/app/auth/auth.service';
import { BeneficiaryEntity } from 'src/app/wallet-api/beneficiaries/beneficiary-entity';
import { BeneficiaryService } from 'src/app/wallet-api/beneficiaries/beneficiary.service';
import {ActivatedRoute} from '@angular/router';
import { BeneficiaryCollection } from 'src/app/wallet-api/beneficiaries/beneficiary-collection';

@Component({
  selector: 'app-list-user-beneficiaries',
  templateUrl: './list-user-beneficiaries.component.html',
  styleUrls: ['./list-user-beneficiaries.component.css']
})
export class ListUserBeneficiariesComponent implements OnInit {

  beneficiaries: Array<BeneficiaryEntity> = [];
  userId: string;
  loading: boolean;

  constructor(
    private beneficiaryService: BeneficiaryService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
   }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.beneficiaryService.fetchAll(
        this.route.snapshot.paramMap.get('userId')
      ).subscribe((response: WalletApiResponse<BeneficiaryCollection>) => {
        this.beneficiaries = response.data.UserBeneficiaries
        this.loading = false;
      })
    })
  }

}
