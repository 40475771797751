<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Events
        </h1>
      </div>
    </div>
  </div>
  
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="block">
            <div class="block-header">
                <h3 class="block-title"></h3>
                <div class="block-options">
                    <div class="dropdown">
                        <button type="button" class="btn btn-primary dropdown-toggle" id="dropdown-default-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Actions
                        </button>
                        <div class="dropdown-menu font-size-sm" aria-labelledby="dropdown-default-primary" style="">
                            <a  class="dropdown-item" href="javascript:void(0)">Create new event</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-content">
                <p class="font-size-sm text-muted">
                    
                </p>
                <div class="table-responsive">
                    <table class="table table-bordered table-striped table-vcenter">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th style="width: 30%;">Account</th>
                                <th style="width: 15%;">Status</th>
                                <th class="text-center" style="width: 100px;">Actions</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Journée Impact Homme</a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">PARIS_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Journée Impact Femme</a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">PARIS_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">40 jours de prières</a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">PARIS_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">la Toussaint </a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">TOULOUSE_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Noël</a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">PARIS_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Nouvel An</a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">TOURS_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">IEBI </a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">LYON_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Module 201 </a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">LYON_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Module 301 </a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">CERGY_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr> 
                                <td class="font-w600 font-size-sm">
                                    <a href="be_pages_generic_profile.html">Module 401 </a>
                                </td>
                                <td class="font-size-sm"><em class="text-muted">CERGY_CHURCH</em></td>
                                <td>
                                    <span [ngClass]="{'badge': true, 'badge-success': true}">
                                        Active
                                    </span>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Edit">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Delete">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  