import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';

import { NotifierService } from './notifier';

// api.service.ts
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly #notifier = inject(NotifierService);

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    private authService: AuthService
  ) {}

  private getHeaders(): HttpHeaders {
    // Include the Authorization header with the JWT token from localStorage
    const jwtToken = localStorage.getItem('jwtToken');
    return new HttpHeaders({
      Authorization: `Bearer ${jwtToken}`,
    });
  }

  get(
    endpoint: string,
    httpParams: HttpParams = new HttpParams()
  ): Observable<any> {
    const url = `${endpoint}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers: headers, params: httpParams }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.logOut();
        }

        if (error.status === 404) {
          if (error.error?.data?.message) {
            // this.errorHandlerService.handleHttpError(error.error.data);
            this.#notifier.error(error.error.data.message);
          }
        } else {
          // this.errorHandlerService.handleHttpError(error);
          this.#notifier.httpError(error);
        }

        return EMPTY;
      })
    );
  }

  post(endpoint: string, data: any): Observable<any> {
    const url = `${endpoint}`;
    const headers = this.getHeaders();
    return this.http.post(url, data, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.authService.signOut();
          this.logOut();
        }
        // this.errorHandlerService.handleHttpError(error);
        this.#notifier.httpError(error);
        return EMPTY;
      })
    );
  }

  patch(endpoint: string, data: any): Observable<any> {
    const url = `${endpoint}`;
    const headers = this.getHeaders();
    return this.http.patch(url, data, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.authService.signOut();
          this.logOut();
        }
        // this.errorHandlerService.handleHttpError(error);
        this.#notifier.httpError(error);
        return EMPTY;
      })
    );
  }

  put(endpoint: string, data: any): Observable<any> {
    const url = `${endpoint}`;
    const headers = this.getHeaders();
    return this.http.put(url, data, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.authService.signOut();
          this.logOut();
        }
        // this.errorHandlerService.handleHttpError(error);
        this.#notifier.httpError(error);
        return EMPTY;
      })
    );
  }

  logOut() {
    // localStorage.removeItem('jwtToken');
    // localStorage.removeItem('user');
    //this.authService.loggedIn.next(false);
    // window.location.href = '/login';
  }
}
