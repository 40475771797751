import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { UserPaymentMeanEntity } from 'src/app/wallet-api/payment-means/user-payment-mean-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { UserPaymentMeansService } from 'src/app/wallet-api/payment-means/user-payment-means.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';
import { UserPaymentMeanCollection } from 'src/app/wallet-api/payment-means/user-payment-mean-collection';
import {FormBuilder, Validators} from '@angular/forms';
import { AlertEntity } from 'src/app/alert/alert-entity';

@Component({
  selector: 'app-bank-account-cash-out',
  templateUrl: './bank-account-cash-out.component.html',
  styleUrls: ['./bank-account-cash-out.component.css']
})
export class BankAccountCashOutComponent implements OnInit {

  paymentMeans: Array<UserPaymentMeanEntity>
  userId: string;
  accountId: string;
  bankCashOutForm;
  alert: AlertEntity;
  inProgress: boolean

  constructor(
    private userPaymentMeansService: UserPaymentMeansService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { 
      this.userId = this.route.snapshot.paramMap.get('userId');
      this.accountId = this.route.snapshot.paramMap.get('accountId')

      this.bankCashOutForm = this.formBuilder.group({
        amount : '',
        paymentMeanId: ''
      });

      this.alert = {
        display: false,
        message: ''
      };
  
      this.inProgress = false;
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.userPaymentMeansService.fetchAll(
        this.route.snapshot.paramMap.get('userId')
      ).subscribe((response: WalletApiResponse<UserPaymentMeanCollection>) => {
        this.paymentMeans = response.data.userPaymentMeans
      })
    })
  }

  createCashOut(){
    this.inProgress = false;
    this.alert.display = true;
    this.alert.message = 'Successfully created cash out request ';
  }

}
