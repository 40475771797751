import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const config = {
  closeButton: true,
};

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private toastr: ToastrService) {}

  handleHttpError(error: HttpErrorResponse): void {
    this.toastr.error(error.message, 'Error', config);
  }

  handleGeneralError(message: string): void {
    this.toastr.error(message, 'Error', config);
  }

  handleSuccessMessage(message: string): void {
    this.toastr.success(message, 'Success', config);
  }
}
