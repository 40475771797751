import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { FeeCollection } from 'src/app/wallet-api/fees/fee-collection';
import { FeeEntity } from 'src/app/wallet-api/fees/fee-entity';
import { FeeService } from 'src/app/wallet-api/fees/fee.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-fee-list',
  templateUrl: './fee-list.component.html',
  styleUrls: ['./fee-list.component.css']
})
export class FeeListComponent implements OnInit {

  fees: Array<FeeEntity> = [];
  loading: boolean;

  constructor(
    private feeService: FeeService,
    private authService: AuthService
  ) { 
    this.loading = true;
  }

  
  ngOnInit(){
    this.authService.token().subscribe(() => {
      this.feeService.fetchAll().subscribe((response: WalletApiResponse<FeeCollection>) => {
        this.fees = response.data.WalletFees;
        this.loading = false;
      });
    });

  }

}
