<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Wallet User Bank Account Details
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center"
            data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
</div>

<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="js-wizard-simple block">
                <!-- add loading here -->
                <div *ngIf="loading">
                    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                </div>
            </div>
            <div class="block">
                <div class="js-wizard-simple block">
                    <div class="block-header">
                        <h3 class="block-title">
                            Bank Details
                        </h3>
                    </div>
                    <div class="block-content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-vcenter">
                                <thead>
                                    <tr class="d-none d-sm-table-row">
                                        <th style="width: 30%;">Bank Name</th>
                                        <th style="width: 30%;">Bank Address</th>
                                        <th style="width: 30%;">Bank Swift Code</th>
                                        <th style="width: 15%;">IBAN</th>
                                        <th style="width: 15%;">Card Holder Name</th>
                                        <th style="width: 15%;">Card Number</th>
                                        <th style="width: 15%;">Card Expiry</th>
                                        <th style="width: 15%;">Blocking Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="d-none d-sm-table-row">
                                        <td class="font-w600 font-size-sm">
                                            <em class="text-muted">{{ bankDetails.bankName }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ bankDetails.bankAddress }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ bankDetails.bankSwiftCode }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ bankDetails.iban }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ cardDetails.cardHolder }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ cardDetails.cardNumber }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ cardDetails.expiryDate }}</em>
                                        </td>
                                        <td>
                                            <em class="text-muted">{{ cardDetails.blockingStatus }}</em>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>