import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { NotifierService } from '../../notifier';
import { UserService } from '../user.service';

interface BankDetails {
  bankAddress: string;
  bankName: string;
  bankSwiftCode: string;
  iban: string;
}
interface CardDetails {
  cardHolder: string;
  cardNumber: string;
  expiryDate: string;
  blockingStatus: string;
}
@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css'],
})
export class BankDetailsComponent implements OnInit {
  bankDetails: BankDetails;
  cardDetails: CardDetails;
  loading: boolean;
  userId: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorHandlerService: ErrorHandlerService,
    private notifier: NotifierService
  ) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    this.loading = true;
    this.bankDetails = {
      bankAddress: '',
      bankName: '',
      bankSwiftCode: '',
      iban: '',
    };
    this.cardDetails = {
      cardHolder: '',
      cardNumber: '',
      expiryDate: '',
      blockingStatus: '',
    };
  }

  ngOnInit(): void {
    this.loading = true;
    this.authService.token().subscribe(() => {
      this.userService
        .fetchBankDetails(this.userId)
        .subscribe((response: WalletApiResponse<any>) => {
          this.userService
            .fetchDebitCardDetails(this.userId)
            .subscribe((cardResponse: WalletApiResponse<any>) => {
              if (
                response.status !== 'success' ||
                cardResponse.status !== 'success'
              ) {
                const error =
                  response.status !== 'success' ? response : cardResponse;
                // this.errorHandlerService.handleGeneralError(
                //   error.StatusDescription
                // );
                this.notifier.error(error.StatusDescription);
              }
              if (response.data) {
                this.bankDetails = response.data;
              }
              if (cardResponse.data) {
                this.cardDetails = cardResponse.data.cardDetails;
              }
              this.loading = false;
            });
        });
    });
  }
}
