import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  fetchAll():Observable<any>  {
    return this.apiService.get(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/channels`)
  }


  createChannel(data: any):Observable<any>  {
    return this.apiService.post(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/channels`, data)
  }

  // GET
  // ​/v1​/notifications​/chats​/channels​/{channelId}
  // Get a Channel data
  getChannel(channelId: string):Observable<any>  {
    return this.apiService.get(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/channels/${channelId}`)
  }

  // PATCH
  // ​/v1​/notifications​/chats​/channels​/{channelId}
  // Update Channel
  updateChannelRead(channelId: string, data: any):Observable<any>  {
    return this.apiService.patch(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/channels/${channelId}`, data)
  }

  // PATCH
  // ​/v1​/notifications​/chats​/channels​/{channelId}​/status
  // change channel status
  updateChannelStatus(channelId: string, data: any):Observable<any>  {
    return this.apiService.patch(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/channels/${channelId}/status`, data)
  }

  // GET
  // ​/v1​/notifications​/chats​/channels​/{userId}​/users
  // Obtain a list of channels by user
  obtainChannelsByUser(userId: string):Observable<any>  {
    return this.apiService.get(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/channels/${userId}/users`)
  }

  // GET
  // ​/v1​/notifications​/chats​/messages
  // Obtain a list of messages by channel
  fetchMessages():Observable<any>  {
    return this.apiService.get(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/messages`)
  }

  // POST
  // ​/v1​/notifications​/chats​/messages
  // Create a Message

  createMessage(data: any):Observable<any>  {
    return this.apiService.post(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/messages`, data)
  }

  // GET
  // ​/v1​/notifications​/chats​/messages​/{messageId}
  // Get a Channel data

  getMessage(messageId: string):Observable<any>  {
    return this.apiService.get(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/messages/${messageId}`)
  }

  // GET
  // ​/v1​/notifications​/chats​/messages​/{channelId}​/channels
  // Obtain a list of messages by channel
  getMessagesByChannel(channelId: string):Observable<any>  {
    return this.apiService.get(`${environment.wallet.walletGwBaseUrl}/v1/notifications/chats/messages/${channelId}/channels`)
  }



}
