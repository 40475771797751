import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class CashInServiceService {

  constructor(
    private apiService: ApiService
  ) {
  }

  fetchFromEventTypeAndEventId(
    eventType: string,
    eventId: string
    ): Observable<any> {
    return this.apiService.get(
      environment.wallet.paymentServiceBaseUrl + '/v1/cash-ins/eventType/'+ eventType + '/eventId/' + eventId);
  }

  fetchTopUpFees(
    topUpTransactionId: string
  ): Observable<any> {
    return this.apiService.get(
      environment.wallet.paymentServiceBaseUrl + '/v1/cash-ins/events/topUp/'+ topUpTransactionId + '/fees');
  }
}
