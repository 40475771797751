import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { environment } from 'src/environments/environment';

import { Sponsorship } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SponsorshipService {
  constructor(private apiService: ApiService) {}

  fetchAll(): Observable<{ status: string; data: Sponsorship[] }> {
    return this.apiService.get(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/sponsor-codes`
    );
  }

  addSponsorship(data: any): Observable<any> {
    return this.apiService.post(
      `${environment.wallet.nbkCrmBaseUrl}/api/crm/sponsor-codes`,
      data
    );
  }
}
