import { Component, computed, effect, ElementRef, input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';

type DataType = {
  date: Date;
  value: number;
  period: string;
};

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.css',
})
export class ChartComponent implements OnInit {
  data = input.required<DataType[]>();
  cWidth = input.required<number>();
  frequency = input.required<'M' | 'W' | 'D'>();

  @ViewChild('chart', { static: true }) private chartContainer!: ElementRef;

  private margin = { top: 20, right: 60, bottom: 140, left: 50 };
  private width = computed(
    () => this.cWidth() - this.margin.left - this.margin.right
  );
  private height = 300 - this.margin.top - this.margin.bottom;

  private svg: any = null;
  private tooltip: any;
  private focusLine: any;
  private focusCircle: any;

  freq = 'M';

  constructor() {
    effect(() => {
      this.updateChart();
    });

    effect(() => {
      this.freq = this.frequency();
    });
  }

  ngOnInit(): void {
    this.createChart();
  }

  private createChart(): void {
    const element = this.chartContainer.nativeElement;
    d3.select(element).select('svg').remove();

    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', this.width() + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);

    this.svg = svg;

    this.tooltip = d3
      .select(element)
      .append('div')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('padding', '5px 10px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('opacity', 0);

    this.focusLine = svg
      .append('line')
      .attr('stroke', 'gray')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4 4')
      .style('opacity', 0);

    this.focusCircle = svg
      .append('circle')
      .attr('r', 5)
      .attr('fill', '#5C80D1')
      .attr('stroke', '#fff')
      .attr('stroke-width', '2')
      .style('opacity', 0);

    this.updateChart();
  }

  private updateChart(): void {
    if (!this.svg) return;

    const svg = this.svg;
    svg.selectAll('*').remove();

    const x = d3
      .scaleTime()
      .domain(d3.extent(this.data(), (d) => d.date) as [Date, Date])
      .range([0, this.width()]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(this.data(), (d) => d.value)!])
      .range([this.height, 0]);

    const area = d3
      .area<DataType>()
      .curve(d3.curveLinear)
      .x((d) => x(d.date))
      .y0(this.height)
      .y1((d) => y(d.value));

    svg
      .append('path')
      .datum(this.data())
      .attr('fill', '#5C80D1')
      .attr('fill-opacity', 0.4)
      .attr('d', area);

    const line = d3
      .line<DataType>()
      .curve(d3.curveLinear)
      .x((d) => x(d.date))
      .y((d) => y(d.value));

    svg
      .append('path')
      .datum(this.data())
      .attr('fill', 'none')
      .attr('stroke', '#5C80D1')
      .attr('stroke-width', 3)
      .attr('d', line);

    const xAxis = svg
      .append('g')
      .attr('transform', `translate(0, ${this.height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(
            this.freq === 'M'
              ? d3.timeMonth.every(1)
              : this.freq === 'W'
              ? d3.timeWeek.every(1)
              : d3.timeDay.every(1)
          )
          .tickValues(this.data().map((d) => d.date))
          .tickFormat((d, i) => {
            if (this.freq === 'M') {
              return d3.timeFormat('%b %Y')(d as Date); // Show month and year
            } else if (this.freq === 'W') {
              //   return d3.timeFormat('W%U %Y')(d as Date); // Show week number and year
              return this.data()[i].period;
            } else {
              return d3.timeFormat('%d %b %Y')(d as Date); // Show day and month
            }
          })
      );

    xAxis
      .selectAll('text')
      .attr('transform', 'rotate(-70)') // Slight angle for better spacing
      .style('text-anchor', 'end') // Align properly
      .style('font-size', '12px');

    svg.append('g').call(d3.axisLeft(y));

    svg
      .append('rect')
      .attr('width', this.width())
      .attr('height', this.height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseover', () => {
        this.focusLine.style('opacity', 1);
        this.focusCircle.style('opacity', 1);
        this.tooltip.style('opacity', 1);
      })
      .on('mousemove', (event: MouseEvent) => {
        const [mouseX] = d3.pointer(event);
        const x0 = x.invert(mouseX);
        const bisectDate = d3.bisector((d: DataType) => d.date).left;
        const index = bisectDate(this.data(), x0, 1);
        const d0 = this.data()[index - 1];
        const d1 = this.data()[index];
        const dClosest =
          x0.getTime() - d0.date.getTime() > d1.date.getTime() - x0.getTime()
            ? d1
            : d0;

        const cx = x(dClosest.date);
        const cy = y(dClosest.value);

        this.focusLine
          .attr('x1', cx)
          .attr('x2', cx)
          .attr('y1', 0)
          .attr('y2', this.height)
          .style('opacity', 1);
        this.focusCircle.attr('cx', cx).attr('cy', cy).style('opacity', 1);

        this.tooltip
          .html(
            `<strong>Date:</strong> ${dClosest.date.toLocaleDateString()}<br><strong>Value:</strong> ${
              dClosest.value
            }`
          )
          .style('left', `${cx + 10}px`)
          .style('top', `${cy + 28}px`)
          .style('opacity', 1);
      })
      .on('mouseout', () => {
        this.tooltip.style('opacity', 0);
        this.focusLine.style('opacity', 0);
        this.focusCircle.style('opacity', 0);
      });
  }
}
