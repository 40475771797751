import { Routes, RouterModule } from '@angular/router';
import {NgModule} from '@angular/core';
import { ListWalletPlansComponent } from './list-wallet-plans/list-wallet-plans.component';
import { AuthGuard } from 'src/app/auth/auth.guard';
import { CreateWalletPlanComponent } from './create-wallet-plan/create-wallet-plan.component';

const routes: Routes = [
  {
    path: 'compliance/wallet-plans',
    component: ListWalletPlansComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  },
  {
    path: 'compliance/wallet-plans/add/new',
    component: CreateWalletPlanComponent,
    canActivate: [AuthGuard],
    children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class WalletPlanRoutingModule { }
