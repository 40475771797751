import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';

import { environment } from '../../../environments/environment';
import { WalletEventApiResponse, WalletEventParams, WalletEventsDescriptionApiResponse } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class WalletEventsService {
  private readonly _walletErrorEvents =
    new BehaviorSubject<WalletEventApiResponse | null>(null);

  private readonly _actions = new BehaviorSubject<string[]>([]);

  private readonly _fromTimestamp = new BehaviorSubject<string>('');

  constructor(private apiService: ApiService) {}

  fetchWalletEvents(
    data: Partial<WalletEventParams>
  ): Observable<WalletEventApiResponse> {
    return this.apiService.post(
      `${environment.wallet.walletEventsBaseUrl}/v1/events/search`,
      data
    );
  }

  fetchWalletEventsDescription(
    desc: string
  ): Observable<WalletEventsDescriptionApiResponse> {
    return this.apiService.get(
      `${environment.wallet.bassServiceBaseUrl}/api/bank-events/descriptions/list?query=${desc}`
    );
  }

  setWalletErrorEvents(events: WalletEventApiResponse): void {
    this._walletErrorEvents.next(events);
  }

  getWalletErrorEvents(): Observable<WalletEventApiResponse> {
    return this._walletErrorEvents.asObservable();
  }

  setActions(actions: string[]): void {
    this._actions.next(actions);
  }

  getActions(): Observable<string[]> {
    return this._actions.asObservable();
  }

  setFromTimestamp(time: string): void {
    this._fromTimestamp.next(time);
  }

  getTimestamp(): Observable<string> {
    return this._fromTimestamp.asObservable();
  }
}
