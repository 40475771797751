import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';

import { environment } from '../../../environments/environment';
import { WalletEventApiResponse, WalletEventParams, WalletEventsDescriptionApiResponse } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class WalletEventsService {

  constructor(private apiService: ApiService) { }

  fetchWalletEvents(data: Partial<WalletEventParams>) : Observable<WalletEventApiResponse> {
    return this.apiService.post(`${environment.wallet.walletEventsBaseUrl}/v1/events/search`, data);
  }

  fetchWalletEventsDescription(desc: string) : Observable<WalletEventsDescriptionApiResponse> {
    return this.apiService.get(`${environment.wallet.bassServiceBaseUrl}/api/bank-events/descriptions/list?query=${desc}`);
  }
}
