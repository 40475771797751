<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          New User
        </h1>
      </div>
    </div>
  </div>
  
  <div class="col-xl-6">
    <div class="block">
        <div class="block-content">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb push">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        New User
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
      <div class="col-md-6">
        <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
            <p class="mb-0"> {{ alert.message }} </p>
        </div>
        <div class="js-wizard-simple block">
        <!-- Form -->
        <form [formGroup]="walletUserForm" (submit)="createUser()">
        <!-- Steps Content -->
        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
            <!-- Step 1 -->
            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
                <h2 class="content-heading border-bottom mb-4 pb-2">General Settings</h2>
                <div class="form-group">
                    <label for="name">Firstname</label>
                    <input class="form-control" type="text" id="name" name="name" formControlName="firstName">
                </div>
                <div class="form-group">
                    <label for="name">Lastname</label>
                    <input class="form-control"  type="text" id="name" name="name" formControlName="lastName">
                </div>
                <h2 class="content-heading border-bottom mb-4 pb-2">Contacts</h2>
                <div class="form-group">
                    <label for="name">Phone Number</label>
                    <input class="form-control"  type="text" id="name" name="name" formControlName="phoneNumber">
                </div>
                <div class="form-group">
                    <label for="name">Mobile Number</label>
                    <input class="form-control"  type="text" id="name" name="name" formControlName="mobileNumber">
                </div>
                <div class="form-group">
                    <label for="name">Email</label>
                    <input class="form-control"  type="text" id="email" name="email" formControlName="email">
                </div>
                <h2 class="content-heading border-bottom mb-4 pb-2">Address</h2>
                <div formGroupName="address">
                    <div class="form-group">
                        <label for="streetName">Street Name</label>
                        <input class="form-control"  type="text" id="streetName" name="streetName" formControlName="streetName">
                    </div>
                    <div class="form-group">
                        <label for="streetNumber">Street Number</label>
                        <input class="form-control"  type="text" id="streetNumber" name="streetNumber" formControlName="streetNumber">
                    </div>
                    <div class="form-group">
                        <label for="postCode">Post Code</label>
                        <input class="form-control"  type="text" id="postCode" name="postCode" formControlName="postCode">
                    </div>
                    <div class="form-group">
                        <label for="city">City</label>
                        <input class="form-control"  type="text" id="city" name="city" formControlName="city">
                    </div>
                    <div class="form-group">
                        <label for="state">State</label>
                        <input class="form-control"  type="text" id="state" name="state" formControlName="state">
                    </div>
                    <div class="form-group">
                        <label for="state">Country</label>
                        <input class="form-control"  type="text" id="country" name="country" formControlName="country">
                    </div>
                </div>
            </div>
            <!-- END Step 1 -->
        </div>
        <!-- END Steps Content -->

        <!-- Steps Navigation -->
        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
                <div class="col-6 text-right">
                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                    </button>
                </div>
            </div>
        </div>
        <!-- END Steps Navigation -->
    </form>
    <!-- END Form -->
</div>
</div>
</div>
</div>