import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { CashInDetailsApiResponse } from 'src/app/payment-api/cash-in/cash-in-details-api-response';
import { CashInDetailsEntity } from 'src/app/payment-api/cash-in/cash-in-details-entity';
import { CashInServiceService } from 'src/app/payment-api/cash-in/cash-in-service.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-transaction-fees',
  templateUrl: './transaction-fees.component.html',
  styleUrls: ['./transaction-fees.component.css']
})
export class TransactionFeesComponent implements OnInit {

  fees;
  accountId: string;
  transactionId: string;
  userId: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private cashInService: CashInServiceService
  ) {
      this.transactionId = this.route.snapshot.paramMap.get('transactionId')
      this.accountId = this.route.snapshot.paramMap.get('accountId')
      this.userId = this.route.snapshot.paramMap.get('userId')
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.cashInService.fetchTopUpFees(
        this.transactionId
        ).subscribe((response) => {
        this.fees = response.data.Fees;
      });
    });

  }
}
