<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Transactions</h1>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="block">
                <div class="block-content">

                    <div class="col-lg-8 col-xl-5 overflow-hidden">
                        <div class="mt-4 mb-4">
                            <div class="custom-control mb-1">
                                <input #fileCtrl
                                       type="file"
                                       (change)="onChange($event)" />
                            </div>
                        </div>

                        <button class="btn btn-sm btn-primary ml-4 mb-4"
                                [disabled]="loading"
                                (click)="onUpload()">
                            <i class="fa fa-check mr-1"></i>
                            {{ loading ? 'Uploading...' : 'Upload' }}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>